
import {observable, computed, action} from 'mobx';
import moment from 'moment';
import uuid from 'uuid';


function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

class LoginDataModel{
    //--- observable 
    @observable isWorking=0;

    @observable email="";
    @observable pass="";
    @observable passCopy="";
    @observable resetCode="";
    @observable fbToken="";
    @observable appUserId='';
    @observable appProviderId="";
    @observable lastLoginFailed="";
    @observable token="";

  @observable joinPersonName = "";
  @observable joinEmail = "";
  @observable joinPhone = "";
  @observable joinPass = "";
  @observable joinPass2 = "";
  @observable joinCompanyName = "";
  @observable joinCompanyCode = "";

    @observable provider="";             // provider data
    @observable fbEmail="";              // only used to link an e-mail login account to an FB login

    //--- computable
    @computed get routerCheckIsLoginOK() { return (!(this.appUserId==="")) }

    @computed get isLoginDisabled() { 
      if (validateEmail(this.email) && (!(""===this.pass))) { return(""); }
        return "grey";
    }

    @computed get isRegisterDisabled() { 
      if (validateEmail(this.email) && this.pass===this.passCopy ) { return(""); }
        return "disabled";
    }
    @computed get registerError()
    {
      if(''===this.email){
        return '';
      }
      if (!validateEmail(this.email)){
          return "Invalid E-Mail address";
      }
      if(""===this.pass){
          return "Password must be 6 characters long.";
      }
      if(!(this.passCopy===this.pass)){
          return "Password and Copy must match";
      }
      return " ";
    }


    //--- volatile
    @observable oldPass="";
    @observable newPass1="";
    @observable newPass2="";

    @computed get isPasswordChangeReady()
    {
        if(""===this.oldPass) { return(" disabled "); }
        if(""===this.newPass1) { return(" disabled "); }
        if(""===this.newPass2) { return(" disabled "); }
        if(!(this.newPass1===this.newPass2)) { return(" disabled "); }
        return "";
    }

    // if already have a token goto this page.
    @action checkIsLoggedIn(goalPage, router)
    {
        var saneThis = this;
        if(saneThis.appUserId){
            // already logged in.  stay put
            return;
        }

        var curToken = window.localStorage.getItem('curToken');
        
        if(curToken){
            // we have a token. Notsure if it's valid, but let's find out.
            // if it is valid we'll go to the goal page
            saneThis.token=curToken;            
            saneThis.getProvider(goalPage, router);
        }
        // if we don't have a token, let's head to the login page.
        router.push('login.start');      
    }

    isLocalStorageForReal()
    {
      var testKey = 'test', storage = window.localStorage;
      try {
        storage.setItem(testKey, '1');
        storage.removeItem(testKey);
        console.log('Local Storage OK');
        return true;
      } catch (error) {
        console.log('Local Storage Not Available, please consider upgrading your browser.');
        return false;
      }
    }

  // called automatically when a new appUserId is set
  @action getProvider(goalPage, router)
  {
    var saneThis = this;
    saneThis.isWorking++;
    saneThis.router=router;
    saneThis.goalPage=goalPage;
    
    fetch("/mqc/provider/",
    {   
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+saneThis.token
        },
        method: "POST",
        body: JSON.stringify({email: saneThis.email})
    })
    .then(function(res){ 
        saneThis.isWorking--;
        if(res.ok){
          return res.json() 
        }
        else{
          return ({invalidProvider:true});
        } 
    })
    .then(function(res){
      if(res.invalidProvider){
        saneThis.lastLoginFailed = 'Account needs attention from help@modaquote.com';
      }
      else{
        if(!res.linkCode){ res.linkCode=''; }
        saneThis.provider = res; 
        saneThis.appUserId = res.appUserId;
        if(!saneThis.email && res.loggedInEmail){
          saneThis.email = res.loggedInEmail;
        }
        if(saneThis.router){
          if(saneThis.secretGoal){
              saneThis.router.push(saneThis.secretGoal);      
              saneThis.secretGoal = '';
          }
          else{
            saneThis.router.push(saneThis.goalPage);      
          }
        }
      }
    } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ 
      console.log(res);
      window.Materialize.toast(res.ExceptionMessage); 
    });    
  }

  @action doLogin(loginResultMethod)
  {
    this.isWorking++;
    var saneThis = this;
        
    fetch("/token",
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: "POST",
        body: "grant_type=password&username="+saneThis.email+"&password="+saneThis.pass
    })
    .then(function(res){
      saneThis.isWorking--;
      return res.json() 
    })
    .then(function(res){ 
      console.log(res)
      if(res.access_token){
        saneThis.token = res.access_token;
        if (saneThis.isLocalStorageForReal()){
          window.localStorage.setItem('curToken',res.access_token);
        }
        if(loginResultMethod){
          loginResultMethod(true);
        }
      }
      else{
        if(res.error_description){
          saneThis.lastLoginFailed = res.error_description;
        }
        loginResultMethod(false); 
      }
    }) // don't forget to bind or JavaScript with drop it's brain on the floor.    
    .catch(function(res){ 
      loginResultMethod(false); 
    })    
  }
    
  getExternalLoginList()
  {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/api/Account/ExternalLogins?returnUrl=%2F&generateState=true",
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: "GET",
    })
    .then(function(res){
      saneThis.isWorking--;
      if (!res.ok) { 
        window.Materialize.toast('Unable to log in.', 3000, 'rounded red'); 
      }
      return res;
    })
    .then(function(res){ 
      return res.json() 
    })      
    .then(function(res){
        //console.log(JSON.stringify(res));
        saneThis.extURL = res[0].Url;
        //saneThis.tryExtLogin(res[0].Url);
    })      
  }


  checkExternalLogin(fbToken,email,id,path,router,providerEmail)
  {
    var saneThis = this;
    saneThis.isWorking++;
    saneThis.router = router;
    saneThis.path = path;
    fetch("/api/Account/ObtainLocalAccessToken",
    {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify({
            email:email,
            id:id,
            providerEmail:providerEmail,
            externalAccessToken:fbToken
        })
    })
    .then(function(res){
      saneThis.isWorking--;
      if (!res.ok) { console.log( '>> Unable to load External Access List >>'+JSON.stringify(res));    throw res; }
      return res;
    })
    .then(function(res){ 
      return res.json() 
    })      
    .then(function(res){
        saneThis.token = res.access_token;
        if (saneThis.isLocalStorageForReal()) {
          window.localStorage.setItem('curToken',res.access_token);
        }
        saneThis.getProvider(saneThis.path,saneThis.router);
    })      
  }

  doRegisterTemp(router) {
    console.log('do register temp started');
    var shortCode = uuid.v4();
    shortCode = shortCode.substring(shortCode.lastIndexOf('-'));
    this.email = 'Free-Sample-' + shortCode+'@test-account.com';
    this.pass = uuid.v4();
    this.passCopy = this.pass;
    this.doRegisterEmail(router);
  }

  doRegisterEmail(router)
  {
    console.log('do register email started');
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/api/Account/register",
    {
        headers: {'Accept': 'application/json','Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          Email: this.email, 
          Password: this.pass,
          ConfirmPassword: this.passCopy
        })
    })
    .then(function(res){ 
      saneThis.isWorking--;
      if(res.ok){
        console.log('new user create provider started');
        saneThis.doRegisterToken(router);
        return null;
      }
      else{ 
        return res.json();
      }
    })
    .then(function(res){
      if(res){
        var err = (res.ModelState[""]|| '')+(res.ModelState["model.Email"]|| '')+(res.ModelState["model.Password"]||'')+(res.ModelState["model.ConfirmPassword"]||'');  
        saneThis.lastLoginFailed = err;
      }
    })
 // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ 
      console.log(res);
      window.Materialize.toast(res.ExceptionMessage,3000,'rounded red');
    })    
  }
  
  doRegisterToken(router)
  {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/token",
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: "POST",
        body: "grant_type=password&username="+this.email+"&password="+this.pass
    })
    .then(function (res) {
      saneThis.isWorking--;
      if(res.ok){
        return res.json();
      }
      else{
        console.log(res);
        window.Materialize.toast(res.ExceptionMessage,3000,'rounded red');
      }
    })
    .then(function (res) { 
        console.log('new user login ok ');
        saneThis.token = res.access_token;
        saneThis.saveNewProvider(router);
    } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ console.log(res);window.Materialize.toast(res.ExceptionMessage);  })    
  }


  /***
  doRegisterJoin(router) {
    console.log('do register joinstarted');
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/api/Account/register-join",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          joinPersonName:this.joinPersonName,
          joinEmail:this.joinEmail,
          joinPhone:this.joinPhone,
          joinPass:this.joinPass,
          joinPass2:this.joinPass2,
          joinCompanyName:this.joinCompanyName,
          joinCompanyCode:this.joinCompanyCode
        })
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.ok) {
          console.log('new user create provider started');
          saneThis.doRegisterToken(router);
          return null;
        }
        else {
          return res.json();
        }
      })
      .then(function (res) {
        if (res) {
          var err = (res.ModelState[""] || '') + (res.ModelState["model.Email"] || '') + (res.ModelState["model.Password"] || '') + (res.ModelState["model.ConfirmPassword"] || '');
          saneThis.lastLoginFailed = err;
        }
      })
      // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) {
        console.log(res);
        window.Materialize.toast(res.ExceptionMessage, 3000, 'rounded red');
      })
  }
  ***/


  // called automatically when a new appUserId is set
  saveNewProvider(router)
  {
    console.log('saving client info...');
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/mqc/provider/create",
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+this.token
        },
        method: "POST",
        body: JSON.stringify({
          email: this.email,
          joinPersonName: this.joinPersonName,
          joinEmail: this.joinEmail,
          joinPhone: this.joinPhone,
          joinPass: this.joinPass,
          joinPass2: this.joinPass2,
          joinCompanyName: this.joinCompanyName,
          joinCompanyCode: this.joinCompanyCode

        })
    })
    .then(function(res){ 
      saneThis.isWorking--;
      if(!res.ok){
        window.Materialize.toast('Unable to create a new account.  Please send an e-mail to help@modaquote.com and try again later.',3000,'rounded red');
        return null;
      }
      return res.json() 
    })
    .then(function(res){ 
      if(res){
        saneThis.provider = res;
        saneThis.appUserId = res.appUserId;
        if(router && router.push){
           router.push("desk.home");
        }
        else if (router){
          router(); // not really a router any more.
        }
        console.log('new client successfully saved');
      }      
    } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ console.log(res);window.Materialize.toast(res.ExceptionMessage,3000,'rounded red'); });    
  }

  @action switchUser(goalUser)
  {
    console.log('switching user to '+goalUser);
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/api/Account/LoginAs",
    {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer "+this.token
        },
        method: "POST",
        body: JSON.stringify({goalUser: goalUser})
    })
    .then(function(res){
      saneThis.isWorking--;
      if (!res.ok) { console.log( '>> Unable to load External Access List >>'+JSON.stringify(res));    throw res; }
      return res;
    })
    .then(function(res){ 
      return res.json() 
    })      
    .then(function(res){
        saneThis.token = res.access_token;
        if (saneThis.isLocalStorageForReal()) {
          window.localStorage.setItem('curToken',res.access_token);
        }
        saneThis.getProvider(saneThis.path,saneThis.router);
    })      
  }
  


    @action async saveImageBanner(data, justReturnTheURL)
    {
      var formData  = new FormData();
      var resURL = '';

      // eslint-disable-next-line
      for(var name in data) {
        formData.append(name, data[name]);
      }
      var saneThis = this;
      saneThis.isWorking++;
      await fetch('mqc/quote/image/file/save/', {
        method: 'POST',
        body: formData
      })
      .then(function(res){ return res.text() })
      .then(function(res){ 
        saneThis.isWorking--;
        window.Materialize.toast('Image File Saved', 3000, 'rounded'); 
        if (justReturnTheURL){
          resURL = saneThis.provider.propBannerURL = 'https://espt.blob.core.windows.net/quoteboat/' + data[0].name;
        }
        else{          
          saneThis.provider.propBannerURL = 'https://espt.blob.core.windows.net/quoteboat/'+data[0].name; // res.replace(/"/g, ""); // don't use the thumbnail for the banner image
        }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to save image banner.', 3000, 'rounded'); })    

      return resURL;
    }
  

    @computed get daysLeft() { 
      var today=moment();
      var expireDay = moment(this.provider.accountExpiresOn);

      var dayDiff = 0;
      if(expireDay){
        dayDiff=expireDay.diff(today, 'days');      
      }
      return dayDiff; 
    }

    @computed get accountStatus() { 
      if(!this.provider){
        return 'LOADING';
      }
      if(this.provider.quoteCount<3 && this.daysLeft<=0){
        return 'FREE3WORKING';
      }
      else if(this.provider.quoteCount===3 && this.daysLeft<=0){
        return 'FREE3FULL';
        //disabled=true;
      } 
      else if(this.provider.quoteCount>3 && this.daysLeft<=0){
        return 'EXPIRED';
        //disabled=true;
      }
      else if(this.daysLeft<5){
        return 'GOOD-SHORT';
      }
      return 'GOOD';
    }


  @action resetPasswordStart()
  {
    var saneThis = this;
    saneThis.isWorking++;
    
    fetch("/api/Account/ResetPasswordStart",
    {   
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: "POST",
        body: JSON.stringify({email: saneThis.email})
    })
    .then(function(res){ 
        saneThis.isWorking--;
        if(!res.ok){
            saneThis.logOut(saneThis.router);
            window.Materialize.toast('Please log in again.',3000,'rounded');
            return null;
        }
        // no error.  Must have worked.  Load it and goto home
        window.Materialize.toast('A reset code has been sent to your e-mail address.',3000,'rounded green');
    })
    .catch(function(res){ console.log(res);window.Materialize.toast(res.ExceptionMessage); });    
  }


  @action resetPassword(router)
  {
    var saneThis = this;
    saneThis.isWorking++;
    
    fetch("/api/Account/ResetPasswordFinish",
    {   
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        method: "POST",
        body: JSON.stringify({
          email: saneThis.email,
          password: saneThis.pass,
          resetCode: saneThis.resetCode,
        })
    })
    .then(function(res){ 
        saneThis.isWorking--;
        if(!res.ok){
            window.Materialize.toast('Please log in again.',3000,'rounded');
            return null;
        }
        else{
          // no error.  Must have worked.  Load it and goto home
          window.Materialize.toast('Your password has been reset.',3000,'rounded green');
          router.push('login.email');
        }
    })
    .catch(function(res){ console.log(res);window.Materialize.toast(res.ExceptionMessage); });    
  }

  @action setFBLink(token)
  {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/provider/fbLink",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({ fbEmail: this.fbEmail})
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) { window.Materialize.toast('Settings Saved', 3000, 'rounded'); }
        else { console.log(res);window.Materialize.toast(res, 3000, 'rounded'); }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save provider changes.', 3000, 'rounded'); }
    );
  }


  @action logOut(router){
    console.log('clearing login');
    if (this.isLocalStorageForReal()) {
      window.localStorage.setItem('curToken','');
    }
    this.appUserId='';
    this.provider={};
    this.token=null;
    window.mqccInactiveCount=0; // dangerous global variables, but I want expiration to be app wide and independent.
    router.push('/');
  }

}

const loginData = new LoginDataModel();
export default loginData;
