import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import routes from './UI/Router/Routes'

// import ReactAI from 'react-appinsights';
import { Router, browserHistory } from 'react-router'

// ReactAI.init({ instrumentationKey: '239aa151-31bd-43da-91b9-1e2575450ccf' }, browserHistory);
// ReactAI.setAppContext({ urlReferrer: document.referrer });

ReactDOM.render(
  <Router history={browserHistory} routes={routes}/>,
  document.getElementById('root')
);
