import React, { Component } from 'react';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import coreCSS from './Core.css';

//=== State Model Handling
import {spy} from 'mobx';                                               // monitor model activity
import {Provider} from 'mobx-react';                                    // provide model data to components

import ScreenDataStore from '../../Data/Screen/ScreenData';             // track real window sizes
import LoginData from '../../Data/Auth/LoginData';
import DeskData from '../../Data/Desk/DeskData';
import SupplierData from '../../Data/Desk/SupplierData';
import ClientData from '../../Data/Desk/ClientData';
import HelpData from '../../Data/Desk/HelpData';
import ImageCatalog from '../../Data/Desk/ImageCatalogData';
import MaterialCatalog from '../../Data/Desk/MaterialCatalogData';
import QuoteData from '../../Data/Desk/QuoteData';
import PayData from '../../Data/Desk/PayData';
import RFPData from '../../Data/Desk/RFPData';
import Spinner from './Spinner';                                        // contains the busy component
import TxnHistory from '../../Data/Desk/TxnHistoryData';

import moment from 'moment';
import ReactTimeout from 'react-timeout'

import momentLocalizer from "react-widgets-moment";

import 'react-widgets/dist/css/react-widgets.css';
import 'react-datepicker/dist/react-datepicker.css';



//================ start force production error handling
//import ReactUpdates from "react-dom/lib/ReactUpdates";
// import ReactDefaultBatchingStrategy from "react-dom/lib/ReactDefaultBatchingStrategy";

// try to avoid a Safari issue with the Intl polyfil
if (Intl.__disableRegExpRestore){
  Intl.__disableRegExpRestore();
}


//

let isHandlingError = false;

//================ end force production error handling


const stores={ ScreenDataStore,LoginData,DeskData,ClientData, SupplierData,QuoteData,ImageCatalog,HelpData,MaterialCatalog,PayData,TxnHistory,RFPData };

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4  // The week that contains Jan 4th is the first week of the year.
  }
});

momentLocalizer(moment);

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:hover': {
          '&:before': {
            borderBottom: 'none',
          }
        },
        '&:before': {
          borderBottom: 'none',
        }
      }
    },
  },
});

//=== Actual Component
@ReactTimeout
class Core extends Component 
{
  constructor(props) {
    super(props);
    autoBind(this);
  }
  static contextTypes={
    router: PropTypes.object
  }

  render() {
    return (
      <Provider {...stores}>
        <ThemeProvider theme={theme}>
        <div>
          <Spinner/>
          <div className="">
              {this.props.children}
          </div>
        </div>
        </ThemeProvider>
      </Provider>
    );
  }

  // inform mobx when the window size changes
  noticeWindowResize(event)
  {
    // store the values where mobx can react to it  
    ScreenDataStore.screenWide = window.innerWidth;
    ScreenDataStore.screenHigh = window.innerHeight;
  }

  // put some listeners in place
  componentDidMount()
  {
    // listen for re-size
    if(window.attachEvent) {
      window.attachEvent('onresize', this.noticeWindowResize);
    }
    else if(window.addEventListener) {
      window.addEventListener('resize',this.noticeWindowResize, true);
    }
    // set initial values
    ScreenDataStore.screenWide = window.innerWidth;
    ScreenDataStore.screenHigh = window.innerHeight;

    // VITAL SECTION
    // This controls the timeout and autosave features. 
    // It uses global variables so it can apply to the whole framework
    // It counts the number of Mobx changes that have occurred, but it does not use observables to do so.
    window.mqcc=0;                    // change counter
    window.mqccOld=-1;                // value of change counter at the last timeout check interval.  Used to compare for changes occuring
    window.mqccInactiveCount=0;       // number of intervals that have passed with no activity.
    spy((event) => {                  // causes this method to be called whenever anything in Mobx happens
      window.mqcc++;                  // increment the counter
    }) 

    this.props.setInterval(this.checkForInactivity, 5*60*1000); // every 5 minutes, check if anything has been happening
  }

  // check if anything has happened during the last timeout interval.
  // if nothing happens for a long enough time, auto-logout.
  checkForInactivity()
  {
    // This must be broken.   Just don't do it any more

    /***
    if(this.props.location.pathname !=='/'){      // don't bother if we're already logged out.
      if(window.mqcc === window.mqccOld){         // has the change counter changed?
        // no activity
        window.mqccInactiveCount++;               // increment the no-activity counter
        console.log('inactivity check: '+window.mqccInactiveCount); 
        if(window.mqccInactiveCount>5){           // if 6 increments have passed..
          // inactivity has timed out.  Logout of app & trust that other timers have already done their save
          console.log('inactivity limit reached. Logging Out');
          LoginData.logOut(this.props.router);  // Log out and return to home screen
        }
      }
      else{                                       // some activity has occured.  bring the counter up to date.
        window.mqccOld = window.mqcc;             // 'old' is brought up to current so we know what to look at next time.
        window.mqccInactiveCount=0;               // the user is no longer inactive
      }
    }
    **/

  }

  // detach listeners
  componentWillUnmount()
  {
    if(window.detachEvent) {
        window.detachEvent('onresize', this.noticeWindowResize);
    }
    else if(window.removeEventListener) {
        window.removeEventListener('resize', this.noticeWindowResize);
    }  
  }
  
}

export default Core;
