import React from 'react';
import autoBind from 'react-autobind';
import {observer,inject} from 'mobx-react';

import BarImage from '../../../Tools/BarImage';
import BarImageLeft from '../../../Tools/BarImageLeft';
import BarImageRight from '../../../Tools/BarImageRight';
import BarContentRight from '../../../Tools/BarContentRight';
import BarContentCenter from '../../../Tools/BarContentCenter';

import MenuHeaderButton from '../../../Tools/MenuHeaderButton';
import {Link} from 'react-router';

export default 
@inject("ScreenDataStore") @observer 
class ContentEasy extends React.Component 
{
  constructor(props) {
    super(props);
    autoBind(this);    
  }
  render(){

    var oneSixth = (this.props.ScreenDataStore.screenWide/6)+'px';
    return(
      <div className=''>
          <div className='marketingBlue'>            
            <BarImageLeft src='/img/marketing/bouquet001.jpg' title='photo by: www.eleganteventsmedia.com'/>
            <BarContentRight>
            <h3 className="HomeHeader">Florists,<br/>Let's book more events</h3>
            <div className="marketingBlockText">
              ModaQuote helps you write beautiful, interactive proposals.<br/><br/>
            </div>
            </BarContentRight>
          </div>

          <div className='marketingWhite row' style={{margin:'0px',padding:'0px'}}> 
            <div className='col l6 s12 center-align' style={{ verticalAlign: 'top' }}>          
              <h3 className="mf_titleFont">Beautiful Designs</h3>
              <div className="marketingBlockText" style={{padding:'10px'}}>
                <ul>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >create</i><span style={{verticalAlign:'middle'}}>&nbsp;Easiest proposal design tools</span></li>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >lightbulb_outline</i><span style={{verticalAlign:'middle'}}>&nbsp;Accept ideas and payments online</span></li>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >color_lens</i><span style={{verticalAlign:'middle'}}>&nbsp;Color and Imagery get you noticed</span></li>
                </ul>
              </div>
            </div>
            <div className="col l6 s12 center-align" style={{ verticalAlign: 'top' }}>
              <h3 className="mf_titleFont">Easy Ordering</h3>
              <div className="marketingBlockText" style={{padding:'10px'}}>
                <ul>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >local_florist</i><span style={{verticalAlign:'middle'}}>&nbsp;Sums up the materials you need</span></li>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >shopping_cart</i><span style={{verticalAlign:'middle'}}>&nbsp;Generate orders from quotes</span></li>
                  <li className='liSpacerOnHome'><i className="material-icons" style={{fontSize:"150%",verticalAlign:'middle'}} >flash_on</i><span style={{verticalAlign:'middle'}}>&nbsp;Many events on one purchase order</span></li>
                </ul>
              </div>
            </div>
          </div>

          <div className='marketingBlue center-align'>  
            <br/><br/>
            <h3 className="HomeHeader ">Easy, Simple, Fast</h3>
            <div className="center-align" style={{fontWeight:'500',fontSize:'125%',padding:'5px'}}>
              ModaQuote is designed for Florists that hate computers.<br/><br/>
              <b>You are an artist, not a bean counter.</b>
            </div>
          </div>

          <div className='marketingBlue row'>  
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">Just Type</h3>                
                <div className="marketingBlockText">
                  Flower names are detected as you type.<br/>
                  One click makes an Order matching the Quote.<br/><br/>
                  <div className="center-align">No more spreadsheets!</div>
                </div>
            </div>
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">No Florist Math</h3>                
                <div className="marketingBlockText">
                  ModaQuote calculates everything you need.<br/>
                  We even break down price by delivery location.<br/><br/>
                  <div className="center-align">No more math headaches!</div>
                </div>
                
            </div>
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">Instant Feedback</h3>
                <div className="marketingBlockText">
                  See quotes exactly as a client sees them.<br/>
                  Track changes and chat on the quote page.<br/><br/>
                  <div className="center-align">No more lost e-mails!</div>                
                </div>
            </div>            
          </div>

          <div className='marketingWhite row' style={{margin:'0px',padding:'0px'}}> 
            <div className='col l6 s12 center-align' style={{verticalAlign:'top'}}>          
              <h3 className="HomeHeader">Floristry is Art<br/>
              <span style={{ fontSize: '65%' }}>Imagery &amp; Color</span></h3>
              <div className="marketingBlockText" style={{padding:'5px'}} >
                Each quote becomes a mini pinboard for collaboration.<br/>
                Add images to each item from the web, computer or personal catalog.<br/>                
              </div>
              <div className="marketingBlockText" style={{marginLeft:'auto',marginRight:'auto'}}>
                <h3><span style={{fontSize:'65%'}}>Florists offer proposals:</span></h3>
                <div className="marketingBlockText" title='Selection of styles offered by one of our florists...'>
                  <img src='/img/marketing/floristProposal.png'  style={{height:'125px'}} alt='' />                
                </div>
              </div>
            </div>

            <div className='col l6 s12 center-align' style={{verticalAlign:'top'}}>          
              <h3 className="HomeHeader center-align">Teamwork is Science<br/><span style={{fontSize:'65%'}}>Discussion &amp; Engagement</span></h3>
              <div className="marketingBlockText" style={{padding:'5px'}} >
                ModaQuote tracks changes directly on the quote page.<br/>
                Clients requests must be approved by the florist.
              </div>
              <h3><span style={{fontSize:'65%'}}>Clients give feedback:</span></h3>
              <div className="marketingBlockText" title='Selection of styles with client feedback...'>
                <img src='/img/marketing/floristFeedback.png'  style={{height:'125px'}} alt='' />
              </div>
            </div>
          </div>
          

          <div className='marketingBlue'>  
            <br/><br/>
            <h3 className="HomeHeader center-align">Purchase Orders</h3><br/>
            <div className='row'>
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">Track Materials</h3>                
                <div className="marketingBlockText">
                  Each Item has a material list.<br/>
                  We count up the totals needed.<br/>
                </div>
              </div>
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">Build Orders</h3>                
                <div className="marketingBlockText">                  
                  Track orders by delivery date and vendor,<br/>
                  then include multiple clients in one order.<br/>
                </div>
              </div>
            <div className='col l4 m4 s12 center-align'>
                <h3 className="mf_titleFont">Assign Costs</h3>
                <div className="marketingBlockText">
                  Each Order assigns costs back <br/>
                  to the client when delivered.<br/>
                </div>
              </div>
            </div>            
            <br/><br/>
          </div>


          <div className='marketingBlue'>  
            <div className='row' style={{verticalAlign:'top'}}>
              <div className='col l6 s12 center-align' style={{ verticalAlign: 'top' }}>
                <h3 className="HomeHeader ">Payments Made Easy</h3>
                <div className="marketingBlockText"  >                
                  List the payment due dates in your quote.<br/>
                  Client clicks 'Pay Now' on each due date to pay.<br/>
                  The amount due changes when the quote changes.
                </div>
              </div>
              <div className='col l6 s12  center-align' style={{ verticalAlign: 'top' }}>
                <h3 className="HomeHeader">Payments Made Safe</h3>
                <div className="marketingBlockText" >                
                  ModaQuote links to a PayPal account that you supply.<br/>
                  Clients pay an invoice that funds your account.<br/>
                  ModaQuote never sees the money or bank data.
                </div>
              </div>
            </div>
          </div>



        <div className='marketingWhite center-align'>            
          <br/>
          <h3 className="HomeHeader">Proof is in the Peonies</h3>
          <div className="marketingBlockText">
            <br/><b>Every image on this page shows a real wedding that was Designed, Signed, Paid and Delivered using ModaQuote.com</b><br/>            
          </div>
          <br/>
          <div className='row'>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/arch002.jpg'  title='photo by: mlukin.com' /></div>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/table011.jpg' title='photo by: Lyndsay Undseth Photography' /></div>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/table.jpg'    title='photo by: www.eleganteventsmedia.com' /></div>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/table002.jpg' title='photo by: brianmacstay.com' /></div>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/bouquet001.jpg' title='photo by: mlukin.com' /></div>
            <div className='col s6 l2'><BarImage minHeight={oneSixth} src='/img/marketing/table010.jpg' title='photo by: brianmacstay.com' /></div>
          </div>              
        </div>

        <div className='marketingBlue center-align'  style={{minHeight:'550px'}}>  
            <BarImageLeft src='/img/marketing/box001.jpg' style={{ transform: 'scaleX(-1)'}} title='photo by: Lyndsay Undseth Photography'/>
            <BarContentCenter >
            <br/>
            <h3 className="HomeHeader">Receipts &amp;<br/>Finances</h3>
            <div className="marketingBlockText">
              The hardest part of being a florist is predicting your income from one month to the next.<br/><br/>
              ModaQuote breaks it all down:
              <ul>
              <li>- Quotes sent</li>
              <li>- Quotes booked</li>
              <li>- Pay received</li>
              <li>- Costs paid</li>
              </ul>
              Easily understood numbers, displayed by month and year.<br/>              
            </div>
            </BarContentCenter>
            <BarImageRight src='/img/marketing/box001.jpg' title='photo by: Lyndsay Undseth Photography'/>
          </div>

          <div className='marketingWhite center-align'>  
            <br/><br/>
            <h3 className="HomeHeader">Already Have Quotes?<br/>We can convert them</h3>
            <div className="marketingBlockText">
              You almost certainly have booked clients already.
              Even good growth requires learning and change so we can help there too:<br/>
              <br/>
              Send us one of your quotes, and we will translate it into a ModaQuote.
              It is free of charge once you start a free trial.<br/>
              <br/>
              If you like the conversion, but don't have time to convert files yourself
              we will convert your entire book of business for a very small fee.<br/>
              Just contact us for an estimate: help@modaquote.com<br/>
            </div>
          </div>
          
          <div className='marketingBlue center-align'>
            <br/>
              <h3 className="HomeHeader">Any Questions?</h3>
              <div className="marketingBlockText" >
                We are always looking for ideas or ways to help.<br/>
                Just let us know what you need:<br/><br/><br/>
                <h3 className="HomeHeader">help@modaquote.com</h3>
              </div>
              <br/><br/>
          </div>


      </div>
        
    );
  }

}

