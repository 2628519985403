

import React from 'react';

import Loadable from 'react-loadable';
import Loading from './Loading';

import Core from '../Core/Core';
import { Route, IndexRoute } from 'react-router'
import HomePage2 from '../Pages/Marketing/HomePage2';
import LoginData from '../../Data/Auth/LoginData';

const LoginClientPage = Loadable({ loader: () => import('../Pages/App/Login/LoginClientPage'), loading: Loading, webpackRequireWeakId: () => require.resolveWeak('../Pages/App/Login/LoginClientPage') });
const LoginFloristPage = Loadable({ loader: () => import('../Pages/App/Login/LoginFloristPage'), loading: Loading, webpackRequireWeakId: () => require.resolveWeak('../Pages/App/Login/LoginFloristPage') });
const LoginFloristEmailPage = Loadable({ loader: () => import('../Pages/App/Login/LoginFloristEmailPage'), loading: Loading, webpackRequireWeakId: () => require.resolveWeak('../Pages/App/Login/LoginFloristEmailPage') });
const LoginFloristFaceBookPage = Loadable({ loader: () => import('../Pages/App/Login/LoginFloristFaceBookPage'), loading: Loading, webpackRequireWeakId: () => require.resolveWeak('../Pages/App/Login/LoginFloristFaceBookPage') });

const RegisterFloristPage = Loadable({ loader: () => import('../Pages/App/Login/RegisterFloristPage'), loading: Loading, });
const RegisterChooserPage = Loadable({ loader: () => import('../Pages/App/Login/RegisterChooserPage'), loading: Loading, });
const RegisterJoinPage = Loadable({ loader: () => import('../Pages/App/Login/RegisterJoinPage'), loading: Loading, });
const RegisterFloristFaceBookPage = Loadable({ loader: () => import('../Pages/App/Login/RegisterFloristFaceBookPage'), loading: Loading, });
const RegisterFloristEmailPage = Loadable({ loader: () => import('../Pages/App/Login/RegisterFloristEmailPage'), loading: Loading, });
const ResetFloristEmailPage = Loadable({ loader: () => import('../Pages/App/Login/ResetFloristEmailPage'), loading: Loading, });

const SettingsPage = Loadable({ loader: () => import('../Pages/App/Desk/SettingsPage'), loading: Loading, });
const PayPalPermissionConfirmation = Loadable({ loader: () => import('../Pages/App/Login/PayPalPermissionConfirmation'), loading: Loading, });
const SubPayPage = Loadable({ loader: () => import('../Pages/App/Login/SubPayPage'), loading: Loading, });
const SubCancelPage = Loadable({ loader: () => import('../Pages/App/Login/SubCancelPage'), loading: Loading, });

const QuoteChooserPage = Loadable({ loader: () => import('../Pages/App/Desk/QuoteChooserPage'), loading: Loading, });
const QuoteCalendarPage = Loadable({ loader: () => import('../Pages/App/Desk/QuoteCalendarPage'), loading: Loading, });
const QuoteActivityPage = Loadable({ loader: () => import('../Pages/App/Desk/QuoteActivityPage'), loading: Loading, });
const QuoteReportPage = Loadable({ loader: () => import('../Pages/App/Desk/QuoteReportPage'), loading: Loading, });
const QuotePullReportPage = Loadable({ loader: () => import('../Pages/App/Desk/QuotePullReportPage'), loading: Loading, });

const DeskTopPage = Loadable({ loader: () => import('../Pages/App/Desk/DeskTopPage'), loading: Loading, });
const TxnHistoryPage = Loadable({ loader: () => import('../Pages/App/Desk/TxnHistoryPage'), loading: Loading, });

const PurchaseOrderEdit = Loadable({ loader: () => import('../Pages/App/PurchaseOrder/PurchaseOrderEditor'), loading: Loading, });
const OrderChooserPage = Loadable({ loader: () => import('../Pages/App/Desk/OrderChooserPage'), loading: Loading, });

const QuoteReaderPage = Loadable({ loader: () =>  import('../Pages/App/QuoteRead/QuoteReaderPage'), loading: Loading});
const QuoteLanderPage = Loadable({ loader: () =>  import('../Pages/App/QuoteRead/QuoteReaderLandingPage'), loading: Loading});

const QuoteControlPage = Loadable({ loader: () =>  import('../Pages/App/QuoteEdit/QuoteControlPage'), loading: Loading});
const WizardPage1 = Loadable({ loader: () =>  import('../Pages/App/QuoteEdit/WizardPage1'), loading: Loading, });
const WizardPage2 = Loadable({ loader: () =>  import('../Pages/App/QuoteEdit/WizardPage2'), loading: Loading, });

const WizardPageX = Loadable({ loader: () => import('../Pages/App/QuoteEdit/WizardPage1'), loading: Loading, });

const TestPage = Loadable({ loader: () => import('../Pages/App/Login/TestPage'), loading: Loading, });

const InventoryPage = Loadable({ loader: () => import('../Pages/App/Desk/InventoryPage'), loading: Loading, });
const SupplierPage = Loadable({ loader: () => import('../Pages/App/Desk/SupplierPage'), loading: Loading, });
const ClientPage = Loadable({ loader: () => import('../Pages/App/Desk/ClientPage'), loading: Loading, });


function reqAuth(nextState, replace) {
    // this will override the getProvider goalPage.
    if(nextState){
        LoginData.secretGoal = nextState.location.pathname;

        if (!LoginData.routerCheckIsLoginOK) {
            replace({
                pathname: '/login.start',
                state: { nextPathname: nextState.location.pathname }
            });
        }
    }
}

export default (
    <Route path="/" component={Core} >
        <IndexRoute component={HomePage2} />

        <Route path="mq.home" component={HomePage2} />
        <Route path="clients" component={LoginClientPage} />

        <Route path="test" component={TestPage} />

        <Route path="login.start" component={LoginFloristPage} />
        <Route path="login.email" component={LoginFloristEmailPage} />
        <Route path="login.facebook" component={LoginFloristFaceBookPage} />


        <Route path="register.chooser" component={RegisterChooserPage} />
        <Route path="register.join" component={RegisterJoinPage} />
        <Route path="register.start" component={RegisterFloristPage} />

        <Route path="register.email" component={RegisterFloristEmailPage} />
        <Route path="register.facebook" component={RegisterFloristFaceBookPage} />

        <Route path="reset.email" component={ResetFloristEmailPage} />

        <Route path="pay.subscribe" component={SubPayPage} />
        <Route path="pay.cancel" component={SubCancelPage} />

        <Route path="rfp.edit" component={PurchaseOrderEdit} />
        <Route path="rfp.list" component={OrderChooserPage} />

        <Route path="desk.quotes" component={QuoteChooserPage} onEnter={reqAuth} />
        <Route path="desk.home" component={DeskTopPage} onEnter={reqAuth} />
        <Route path="desk.calendar" component={QuoteCalendarPage} onEnter={reqAuth} />
        <Route path="desk.activity" component={QuoteActivityPage} onEnter={reqAuth} />
        <Route path="desk.reports" component={QuoteReportPage} onEnter={reqAuth} />
        <Route path="desk.txn" component={TxnHistoryPage} onEnter={reqAuth} />
        <Route path="desk.settings" component={SettingsPage} onEnter={reqAuth} />
        <Route path="desk.pull" component={QuotePullReportPage} onEnter={reqAuth} />
        

        <Route path="wizard.1" component={WizardPage1} onEnter={reqAuth} />
        <Route path="wizard.2" component={WizardPage2}  />

        <Route path="paypal.confirmPermission" component={PayPalPermissionConfirmation} />

        <Route path="desk.inventory" component={InventoryPage} />
        <Route path="desk.vendor" component={SupplierPage} />
        <Route path="desk.client" component={ClientPage} />
        


        <Route path="quote.read" component={QuoteReaderPage} />
        <Route path="quote.readAll" component={QuoteLanderPage} />

        <Route path="quote.edit" component={QuoteControlPage} />

        <Route path="quote.start" component={WizardPageX} />


        <Route path="*" component={HomePage2} />
    </Route>
);

/*****
  

  
 





 ****/