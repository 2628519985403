
import { observable, computed, action, autorun } from 'mobx';
import moment from 'moment';
import uuid from 'uuid';


class SupplierDataModel {
  @observable isWorking = 0;

  @observable isEditing = false;
  @observable isImporting = false;
  @observable isExporting = false;


  @observable curSupplier = {
    id:"",
    supplierId:"",
    name:"",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    fax: "",
    contact: "",
    alt_phone: "",
    category: "",
    note: "",
    keywords: ""
  };

  @action supplierNew() {
    this.curSupplier = {
      id: "",
      supplierId: uuid.v4(),
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      fax: "",
      contact: "",
      alt_phone: "",
      category: "",
      note: "",
      keywords: ""
    };
  }


  @observable supplierFilter = "";
  @observable supplierCatFilter = "";
  @observable supplierList = [];
  @observable supplierSelected = "";

  @observable nameDir = 'u';
  @observable catDir='-';

  @action clickSort(type){
    if('name'===type){
      this.catDir='-';
      if (this.nameDir==='u'){
        this.nameDir='d';
      }else{
        this.nameDir = 'u';
      }
    }
    if ('cat' === type) {
      this.nameDir = '-';
      if (this.catDir === 'u') {
        this.catDir = 'd';
      } else {
        this.catDir = 'u';
      }
    }
    console.log(this.nameDir, this.catDir);
  }

  @computed get supplierFilteredList() {
    var res = [];
    if (this.supplierList) {
      for (var ctr = 0; ctr < this.supplierList.length; ctr++) {
        if (this.supplierList[ctr].name.toLowerCase().contains(this.supplierFilter.toLowerCase()) && 
          this.supplierList[ctr].category.toLowerCase().contains(this.supplierCatFilter.toLowerCase())) {
          res.push(this.supplierList[ctr]);
        }
      }
    }

    var saneThis = this;
    if(this.catDir!=='-'){
      return res.sort(function (a, b) {
        var nameA = a.category.toLowerCase(), nameB = b.category.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return (saneThis.catDir === 'u') ? -1 : 1
        if (nameA > nameB)
          return (saneThis.catDir === 'u') ? 1 : -1
        return 0
      });
    }
    else{
      return res.sort(function (a, b) {
        var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return (saneThis.nameDir === 'u') ? -1 : 1
        if (nameA > nameB)
          return (saneThis.nameDir === 'u') ? 1 : -1
        return 0
      });
    }
  }

  //================ Supplier work
  @action supplierSave(token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/supplier/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(this.curSupplier)
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) { window.Materialize.toast('Settings Saved', 3000, 'rounded'); }
        else { console.log(res); window.Materialize.toast(res, 3000, 'rounded'); }
        saneThis.supplierLoadList(token); // in case of clones or adds
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save provider changes.', 3000, 'rounded'); }
      );
  }

  @action supplierImport(token,jsonString) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/supplier/import",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "POST",
        body: jsonString
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this supplier import.";
          return;
        }
        return res.text()
      })
      .then(function (res) {
        window.Materialize.toast('Import Complete', 3000, 'rounded');
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action supplierExport(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/supplier/export",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this supplier export.";
          return;
        }
        return res.json()
      })
      .then(function (res) {

        // send the file to be downloaded
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(res)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "supplierExport.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action supplierLoadList(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/supplier/search",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this team.";
          return;
        }
        return res.json()
      })
      .then(function (res) {
        saneThis.supplierList = res;
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action supplierLoad(supplierData, token) {

    this.supplierSelected = supplierData.name;
    this.isEditing = true;
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/supplier/load/" + supplierData.supplierId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "GET",
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        saneThis.curSupplier = res;
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load supplier changes.', 3000, 'rounded'); }
      );
  }


  @action supplierDelete(token) 
  {
    this.isEditing = false;
    this.curSupplier.name='';
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/supplier/delete/" + this.curSupplier.supplierId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "GET",
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        saneThis.supplierLoadList(token); // in case of clones or adds
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load supplier changes.', 3000, 'rounded'); }
      );
  }

  @action supplierCopy( token) {
    this.curSupplier.supplierId = uuid.v4();
    this.supplierSave(token);
  }

  @action supplierCancel() {
    this.isEditing = false;
  }


}


const SupplierData = new SupplierDataModel();
export default SupplierData;
