
import { observable, computed, action, autorun } from 'mobx';
import moment from 'moment';
import uuid from 'uuid';


class DeskDataModel {
  @observable isWorking = 0;
  @observable quoteList = [];            // all quotes matching the open filter

  @observable pullList = [];            // all quotes matching the open filter
  @observable pullListData = [];            // all quotes matching the open filterpullListData = observable.map({a:'b'});         // all quotes matching the open filter
  @observable pullListStart = new Date();            
  @observable pullListDept = 'all';            

  @observable calendarList = [];         // calendar quote items
  @observable calendarSelectedList = []; // calendar items on the selected date

  @observable curQuoteId = "";           // quote to open
  @observable reportData = "";           // counts and amounts
  @observable activityData = [];         // recent comments
  @observable teamList = [];         // recent comments

  @observable filterYear = new Date().getFullYear();
  @observable nameTextFilter = '';

  @observable isPayPalReady = 'Checking Status';  // to see if paypal is already integrated.
  @observable payPalCheckOK = '';                 // for results of a permissions integration callout to paypal


  @observable curDeptFilter = '--';
  @observable curCategoryFilter = '--';
  @observable curThemeFilter = '--';

  @observable selectedStockId = '';

  @observable knownUnitList = ['each','per ft', 'per sq. ft','per hour','per half hour','per set','per pair'];

  @action setDeptFilter(val) { this.curDeptFilter = val; }

  @action setThemeFilter(val) { this.curThemeFilter = val; }

  @observable stockHasChanges = false;



  @observable invNameFilter = '';
  @observable curInvItem = {
    name: '',
    dept: '',
    category: '',
    theme: '',
    desc: '',
    dimensions: '',

    qtyStock: 0,
    qtyBuild: 0,
    price: 0,

    createDate: '',
    lastEditDate: '',
    lastEditUser: ''
  };
  @observable stockItemList = [];

  @observable openMenuItems = [];            // not as reusable as I'd prefer

  @observable curBuildNote ='';

  @computed get calendarListForYear() {
    var res = [];
    if (this.calendarList) {
      for (var ctr = 0; ctr < this.calendarList.length; ctr++) {
        if (this.filterYear === (new Date(this.calendarList[ctr].eventDate)).getFullYear()) {
          res.push(this.calendarList[ctr]);
        }
      }
    }
    return res;
  }



  @action updateData(token, appUserId, filterIndex) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/list",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(
          { appUserId: appUserId, sort: filterIndex, rnd: Math.random() }
        )
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        return res.json()
      })
      .then(function (res) {
        this.quoteList = res;
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  @observable quoteSortPlan = 'edit';

  @computed get filteredQuoteList() {
    const today = new Date().toISOString().split('T')[0];
    var res = [];
    for (var ctr = 0; ctr < this.quoteList.length; ctr++) {
      if (this.quoteList[ctr].clientName.toLowerCase().includes(this.nameTextFilter)) { // nameTextFilter always in lower case
        if ( (this.quoteSortPlan === 'future' && this.quoteList[ctr].EventDate>today) ||
             (this.quoteSortPlan === 'past' && this.quoteList[ctr].EventDate < today) ||
              this.quoteSortPlan === 'edit'
        ){
          if (this.quoteList[ctr].statusCode!==60){
            res.push(this.quoteList[ctr]);
          }
        }
        if (this.quoteSortPlan === 'archived' && this.quoteList[ctr].statusCode === 60) {
          res.push(this.quoteList[ctr]);
        }

      }
    }

    if(this.quoteSortPlan==='edit'){
      return res.sort(function (a, b) {
        var nameA = a.LastEdited, nameB = b.LastEdited;
        if (nameA < nameB) //sort string ascending
          return 1
        if (nameA > nameB)
          return -1
        return 0
      });
    }
    else if (this.quoteSortPlan === 'future') {
      return res.sort(function (a, b) {
        var nameA = a.EventDate, nameB = b.EventDate;
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0
      });
    }
    else if (this.quoteSortPlan === 'past') {
      return res.sort(function (a, b) {
        var nameA = a.EventDate, nameB = b.EventDate;
        if (nameA < nameB) //sort string ascending
          return 1
        if (nameA > nameB)
          return -1
        return 0
      });
    }

    return res;
  }

  @action doPayPalCheck(token) {
    var saneThis = this;
    saneThis.isWorking++;
    saneThis.isPayPalReady = "Checking Status";

    fetch("/mqc/integrate/pp/permission/check",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",

      })
      .then(function (res) {
        saneThis.isWorking--;
        return res.text()
      })
      .then(function (res) {
        res = res.substr(1, res.length - 2);
        saneThis.isPayPalReady = res;
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action doPayPalAsk(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/integrate/pp/permission/send",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",

      })
      .then(function (res) {
        saneThis.isWorking--;
        return res.text()
      })
      .then(function (res) {
        res = res.substr(1, res.length - 2);
        window.open(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  @action doPayPalOK(token, requestToken, verificationCode) {
    var saneThis = this;
    saneThis.isWorking++;
    saneThis.payPalCheckOK = 'Saving Integration';
    console.log('new version ' + requestToken + '  ' + verificationCode);

    fetch(`/mqc/integrate/pp/permission/ok`,  //-- note! trailing slash is required to avoid HTTP 406 error
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({
          'request_token': requestToken,
          'verification_code': verificationCode
        }
        )
      })
      .then(function (res) {
        saneThis.isWorking--;
        return res.text()
      })
      .then(function (res) {
        res = res.substr(1, res.length - 2);
        saneThis.payPalCheckOK = res;
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action saveProvider(provider, token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/provider/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(provider)
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) { window.Materialize.toast('Settings Saved', 3000, 'rounded'); }
        else { console.log(res); window.Materialize.toast(res, 3000, 'rounded'); }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save provider changes.', 3000, 'rounded'); }
      );
  }



  @action savePassword(data, token) {
    var saneThis = this;
    saneThis.isWorking++;


    fetch("api/Account/ChangePassword",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(data)
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.ok) {
          window.Materialize.toast('Password Updated', 3000, 'rounded');
          return {}
        }
        return res.json()
      })
      .then(function (res) {
        if (res.Message) { console.log(res); window.Materialize.toast('Invalid current password', 3000, 'rounded red'); }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  //=== For the pull report, will take a minute to load ========================================
  @action updatePullData(token, appUserId, filterIndex) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/list",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(
          { appUserId: appUserId, sort: filterIndex, rnd: Math.random() }
        )
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        return res.json()
      })
      .then(function (res) {
        console.log('pl');
        saneThis.pullListData.clear();
        this.pullList = res;

      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @computed get filteredPullData()
  {
    var res = [];
    var mStart = moment(this.pullListStart);
    var mEnd = mStart.clone().add(1, 'week');

    for (var ctr = 0; ctr < this.pullListData.length; ctr++) {
      var mActual = moment(this.pullListData[ctr].doc.metaData.date);
      console.log('>s' + mStart.format('LLL'))
      console.log('>a' + mActual.format('LLL'))
      console.log('>e' + mEnd.format('LLL'))

      if (mActual.isBetween(mStart,mEnd,"day","[]") ){
        res.push(this.pullListData[ctr]);
      }
    }
    return res;

  }

  loadQuoteGuts = autorun(() => {
    var res = [];
    if (this.pullList && this.pullList.length > 0) {
      this.pullList.map(d => {
        console.log(d);
        console.log('fetch' + d.clientAccessCode);
        this.fetchServerDoc(d.clientAccessCode)
      });
    }
  })

  @action fetchServerDoc(docId) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/load/" + docId,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        console.log('got it' + docId, res);
        saneThis.pullListData.push(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  //==== stock work
  @action stockSave(token,optionalPostSaveAction,curUserName) {
    var saneThis = this;
    saneThis.isWorking++;

    
    // update the audit log - security issue.  This should be done server side, but it's easier here.
    if (!this.curInvItem.auditList){
      this.curInvItem.auditList=[];
    }
    this.curInvItem.auditList.unshift("" + new Date().toISOString().slice(0, 16) + " : " + curUserName );

    fetch("/mqc/desk/stock/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(
          this.curInvItem
        )
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (!res.ok) {
          console.log(res.error);
          saneThis.lastError = "Unable to save stock item.";
          window.Materialize.toast('Unable to save stock item.', 3000, 'rounded red');
          return;
        }
        return res.json()
      })
      .then(function (res) {
        window.Materialize.toast('Stock Item Saved', 3000, 'rounded green');
        if (optionalPostSaveAction){
          optionalPostSaveAction();
        }
        saneThis.stockList(token);
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  //==== stock work
  @action stockLoad(stockId,token) {
    var saneThis = this;

    saneThis.isWorking++;
    console.log(this.curInvItem);

    fetch("/mqc/desk/stock/load/"+stockId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (!res.ok) {
          console.log(res.error);
          saneThis.lastError = "Unable to load stock item.";
          window.Materialize.toast('Unable to load stock item.', 3000, 'rounded red');
          return;
        }
        return res.json()
      })
      .then(function (res) {
        console.log(res);
        res.dept = (res.dept||'--').toLowerCase();
        res.theme = (res.theme || '--').toLowerCase();
        res.setupTime = res.setupTime||'';
        res.setupCost = res.setupCost || '';
        if (!res.buildNoteList) res.buildNoteList=[];
        if (!res.photoList) res.photoList= [];
        if (!res.auditList) res.auditList  = [];
        if (!res.buildText) res.buildText='';
        console.log(res);

        saneThis.curInvItem=res;
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action stockDelete(stockId, token) {
    var saneThis = this;

    saneThis.isWorking++;
    console.log(this.curInvItem);

    fetch("/mqc/desk/stock/delete/" + stockId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        console.log(res);
        if (!res.ok) {
          console.log(res.error);
          saneThis.lastError = "Unable to delete stock item.";
          window.Materialize.toast('Unable to delete stock item.', 3000, 'rounded red');
          return;
        }
        else{
          window.Materialize.toast('Stock item deleted.', 3000, 'rounded green');
          saneThis.stockList(token);
          saneThis.selectedStockId='';
        }
      }
      .bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action stockList(token) {
    var saneThis = this;

    saneThis.isWorking++;

    fetch("/mqc/desk/stock/list",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET",
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to list stock items.";
          window.Materialize.toast('Unable to save stock item.', 3000, 'rounded red');
          return;
        }
        return res.json()
      })
      .then(function (res) {
        if(res && res.length){
          saneThis.stockItemList.clear();
          for(var ctr=0;ctr<res.length;ctr++){
            res[ctr].dept = res[ctr].dept.toLowerCase();
            res[ctr].category = res[ctr].category.toLowerCase();
            res[ctr].theme = res[ctr].theme.toLowerCase();
          }
          saneThis.stockItemList.replace(res);
        }
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @computed get unFilteredStockList() {
    var res = {};
    res['--'] = {};

    var item={}
    var key={};
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      item = this.stockItemList[ctr];
      key = item.category.toLowerCase() + "." + item.theme.toLowerCase() + '.' + item.name.toLowerCase();
      if (!res[item.category]) {
        res[item.category] = {};
        res[item.category]['--'] = {};
      }
      if (!res[item.category][item.theme]) {
        res[item.category][item.theme] = {};
      }
      if (!res[item.category][item.theme][item.name]) {
        res[item.category][item.theme][item.name] = item.stockId;
      }
    }

    // same again for the current item being edited
    if (this.curInvItem && this.curInvItem.name){
      item = this.curInvItem;
      key = item.category.toLowerCase() + "." + item.theme.toLowerCase() + '.' + item.name.toLowerCase();
      if (!res[item.category]) {
        res[item.category] = {};
        res[item.category]['--'] = {};
      }
      if (!res[item.category][item.theme]) {
        res[item.category][item.theme] = {};
      }
      if (!res[item.category][item.theme][item.name]) {
        res[item.category][item.theme][item.name] = item.stockId;
      }
    }


    return res;
  }

  @computed get unFilteredDeptList() {
    var res = {};
    res['--'] = {};

    var item = {}
    var key = {};
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      item = this.stockItemList[ctr];
      key = item.dept.toLowerCase() + "." + item.theme.toLowerCase() + '.' + item.name.toLowerCase();
      if (!res[item.dept]) {
        res[item.dept] = {};
        res[item.dept]['--'] = {};
      }
    }

    // same again for the current item being edited
    if (this.curInvItem && this.curInvItem.name) {
      item = this.curInvItem;
      if (!res[item.dept]) {
        res[item.dept] = {};
      }
    }
    return res;
  }


  @computed get stockImage() {
    var res = {};
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      if(this.stockItemList[ctr].primeImageURL){
        res[this.stockItemList[ctr].stockId] = this.stockItemList[ctr].primeImageURL;
      }
    }
    return res;
  }

  @computed get stockById() {
    var res = {};
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      res[this.stockItemList[ctr].stockId] = this.stockItemList[ctr];
    }
    return res;
  }


  @computed get filteredStockList() {
    var res = {};
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      var item = this.stockItemList[ctr];      
      var key = item.category.toLowerCase() + "." + item.theme.toLowerCase() + '.' + item.name.toLowerCase();
      if (
          item.name.toLowerCase().includes(this.invNameFilter)
          && (this.curCategoryFilter  == '--' || this.curCategoryFilter === item.dept.toLowerCase())
          && (this.curThemeFilter == '--' || this.curThemeFilter === item.theme.toLowerCase())
        ) {
        if (!res[item.category]) {
          res[item.category] = {};
        }
        if (!res[item.category][item.theme]) {
          res[item.category][item.theme] = {};
        }
        if (!res[item.category][item.theme][item.name]) {
          res[item.category][item.theme][item.name] = item.stockId;
        }
      }
    }

    return res;
  }

  @computed get knownDeptList() {
    var res = ['purchase', 'rental', 'other', 'note', 'delivery', 'signature', 'payment'];
    /**
    var res = ['note','floral','other','delivery','signature','payment','carpet','printing','decor','furniture','lighting','linen'];
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      var item = this.stockItemList[ctr];
      if (!res.includes(item.dept.toLowerCase())) {
        res.push(item.dept.toLowerCase());
      }
    }
    if (this.curInvItem.dept && !res.includes(this.curInvItem.dept.toLowerCase())) {
      res.push(this.curInvItem.dept.toLowerCase());
    }
    res.push('other');
    res.push('--');
    */
    return res;
  }

  @computed get knownThemeList() {
    var res = [];
    for (var ctr = 0; ctr < this.stockItemList.length; ctr++) {
      var item = this.stockItemList[ctr];
      if (!res.includes(item.theme) &&
          (this.curCategoryFilter == '--' || this.curCategoryFilter === item.category)
      ) {
        res.push(item.theme);
      }
    }
    if (!res.includes(this.curInvItem.theme)) {
      res.push(this.curInvItem.theme);
    }
    res.push('other');
    res.push('--');
    return res;
  }

  @action saveImportData(data, token) {
    var formData = new FormData();
    var impStr = '';
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      impStr = binaryStr;

      var saneThis = this;
      saneThis.isWorking++;
      fetch('mqc/desk/stock/import/', {
        method: 'POST',
        headers: { 'Accept': 'text/plain', 'Content-Type': 'text/plain', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        body: binaryStr
      })
      .then(function (res) { return res.text() })
      .then(function (res) {
        saneThis.isWorking--;
        window.Materialize.toast('Import File Uploaded', 3000, 'rounded');
        saneThis.stockList(token);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save image banner.', 3000, 'rounded'); })

    }
    reader.readAsText(data[0]);
  }

  //=== For the pull report, will take a minute to load ========================================
  @action loadTeamList(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/team/list",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this team.";
          return;
        }
        return res.json()
      })
      .then(function (res) {
        console.log(res);
        saneThis.teamList.clear();
        saneThis.teamList = res;

      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }





}



  const DeskData = new DeskDataModel();
  export default DeskData;
