
import React from 'react';
import styled from 'styled-components';
import {observer,inject} from 'mobx-react';
//import {Link} from 'react-router';
//import {observer,inject} from 'mobx-react';
//import autoBind from 'react-autobind';



const MenuHeaderButtonBox = styled.div`
font-size:125%;
line-height:0.9;
max-width:175px;
width:175px;
border: 2px solid grey;
border-radius:10px;
color:white;
margin:5px;
padding-top:-15px;
max-height:50px;
`;

export default 
@inject("ScreenDataStore") @observer 
class MenuHeaderButton extends React.Component
{
  render(){

    var btnHeight = '50px';

    if(this.props.ScreenDataStore.screenWide<560){
      return (
        <MenuHeaderButtonBox
          style={{ height: btnHeight, backgroundColor: 'steelblue', maxWidth:'60px' }}
          className={"waves-effect waves-darken center hoverEnabled " + this.props.go}
          onClick={this.props.onClick} id={this.props.tid}>
          <div style={{ fontSize: '14pt',lineHeight:'1.1', marginTop: '4px' }}>{this.props.bigLabel}</div>
        </MenuHeaderButtonBox>
      );
    }
    else{
      return (
        <MenuHeaderButtonBox
          style={{ height: btnHeight, backgroundColor: 'steelblue' }}
          className={"waves-effect waves-darken center hoverEnabled " + this.props.go}
          onClick={this.props.onClick} id={this.props.tid}>
          <div style={{ fontSize: '18pt', marginTop: '4px' }}>{this.props.bigLabel}</div>
          <div style={{ fontSize: '10pt', margin: '7px 10px 3px 10px' }}>{this.props.smallLabel}</div>
        </MenuHeaderButtonBox>
      );
    }

  }
}
