


import {observable, computed, action} from 'mobx';


class MaterialCatalogModel
{
    // loaded from server
    @observable isWorking=0;
    @observable matCatalog=[];            // full catalog data
    @observable matNameFilter='';         // filter on data
    @observable matName = '';  
    @observable matCost = 0;              // mat cost text
    @observable showDetails = true;
    

    @computed get matCostNumber()
    {
      try{
        if(this.matCost){
          return Number(this.matCost.replace(/[^0-9.]+/g,""));
        }
        return 0;
      }
      catch(err){
        return 0;
      }
    }

    @computed get materialReadyToAdd()
    {
      return (this.matName && this.matCost);
    }

    @computed get errMessage()
    {
      if(!this.matCost){
        return 'Please enter a cost for material.';
      }
      if(!this.imgName){
        return 'Please enter a name for this material.';
      }
      return '';
    }

    @computed get matListFiltered()
    {
      console.log(this.showDetails);

      var nameList = [];
      if(this.matCatalog){
        this.matCatalog.forEach( (mat) => {
          if(mat.name.includes(this.matNameFilter) && 
                (( this.showDetails===true) ||
                 ( this.showDetails===false && mat.stockDesc!=='*'))
        ){
            nameList.push({
              name: mat.name,
              cost: mat.cost,
              stockDesc: mat.stockDesc
            });
          }
        });
      }
      return nameList;
    }

    @computed get nameList()
    {
      var nameList = [];
      if(this.matCatalog){
        this.matCatalog.forEach( (mat) => {
          nameList.push(mat.name.toLowerCase());
        });
      }
      return nameList;
    }

    @computed get SizeSortedNameList()
    {
      var allList=[];
      if(this.matCatalog){
        this.matCatalog.forEach( (mat) => {
            allList.push(mat);
        });
      }
      allList.sort(function(a, b){ return b.length - a.length; });
      return allList;
    }

    @computed get costLookup()
    {
      var costList = {};
      if(this.matCatalog){
        this.matCatalog.forEach( (mat) => {
          costList[mat.name] = mat.cost;
        });
      }
      return costList;
    }
    
    

    @action clearData()
    {
      this.matNameFilter='';           // filter on data
      this.matName = '';  
      this.matCost = 0;  
    }

    @action loadMaterialCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/florist/material/list",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({  } ) // handy for testing.  should be appUserId
      })
      .then(function(res){saneThis.isWorking--;return res.json() })
      .then(function(res){ 
        if(res){ 
          this.matCatalog = res;
        }
        else{ console.log(res);window.Materialize.toast(res, 1000, 'rounded'); }
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to load material catalog.', 1000, 'rounded'); })        
    }

    @action saveMaterialToCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/florist/material/add",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({   "name":this.matName,  "cost":this.matCost } )
      })
      .then(function(res){saneThis.isWorking--;return res.text() })
      .then(function(res){ 
        this.loadMaterialCatalog(token);
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to save material to catalog.', 1000, 'rounded'); })        
    }

    // for use then the observables have not been updated yet
    @action saveMaterialToCatalogSpecific(token, name, cost)
    {
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/florist/material/add",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({  "name":name,  "cost":cost } )
      })
      .then(function(res){saneThis.isWorking--;return res.text() })
      .then(function(res){ 
        this.loadMaterialCatalog(token);
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to save material to catalog.', 1000, 'rounded'); })        
    }    

    @action deleteMaterialFromCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/florist/material/delete",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({ "name":this.matName,   } )
      })
      .then(function(res){saneThis.isWorking--;return res.text() })
      .then(function(res){ 
        this.loadMaterialCatalog(token);
        window.Materialize.toast('Removed '+this.matName+' from your catalog.', 1000, 'rounded');
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to delete material from catalog.', 1000, 'rounded'); })        
    }    

    @action addNewMaterialToItem(token, curItem, name, qty, cost, doc, skipUpdateDB, skipWarning)
    {
      //--- update the material to the database, if the name doesnt't exist or match the cost
      if(skipUpdateDB===false){
        this.saveMaterialToCatalogSpecific(token,name,cost);
      }

      //--- check if the material is already present on the item
      /** No longer doing this to allow multiple items of different colors */

      //--- if this material is already in use, find the first color used.
      var foundColor='';
      for(var pctr=0;pctr<doc.phaseList.data.length;pctr++){
        for(var ictr=0;ictr<doc.phaseList.data[pctr].lineItemList.length;ictr++){
          for(var cctr=0;cctr<doc.phaseList.data[pctr].lineItemList[ictr].costList.length;cctr++){
            if(name === doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name){
              if(doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color){
                foundColor = doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color;
              }
            }
          }
        }
      }
    
      //--- add the material to the quote
      var newMat = observable({
        name:name,
        qty:qty,
        cost:cost,
        color:foundColor,
        note:'',
        markup: (1+((curItem.markup||100)/100)) ,
      });    
      curItem.costList.unshift(newMat);
      window.Materialize.toast('Added material '+name+'.', 1000, 'rounded right green');

      curItem.costList = curItem.costList.sort(
        function(a,b) {return (a.name+a.color).localeCompare(b.name+b.color) }
      )
    }

    // used for add from typing
    @action addNewMaterialToItemFromTyping(curItem, name, foundColor, doc)
    {
      //--- no need for DB add

      //--- if this material is already in use, find the first color used.
      if(!foundColor){
        for(var pctr=0;pctr<doc.phaseList.data.length;pctr++){
          for(var ictr=0;ictr<doc.phaseList.data[pctr].lineItemList.length;ictr++){
            for(var cctr=0;cctr<doc.phaseList.data[pctr].lineItemList[ictr].costList.length;cctr++){
              if(name === doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name){
                if(doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color){
                  foundColor = doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color;
                }
              }
            }
          }
        }      
      }

      // on a per-item basis, do not re-type things that have been removed, respecting item and color
      if(!curItem.noAutoType){
        curItem.noAutoType = [];
      }
      if(curItem.noAutoType.includes(name+':'+foundColor)){
        return false;
      }      

      for(var ctr=0;ctr<curItem.costList.length;ctr++){
        if(curItem.costList[ctr].name === name &&
           curItem.costList[ctr].color === foundColor
          ){
          return false;   // duplicate name and color
        }
      }       
      
      //--- add the material to the quote
      var newMat = observable({
        name:name,
        color:foundColor,
        note:'',
        qty:1,
        cost: (this.costLookup[name]||1)
      });    
      curItem.costList.unshift(newMat);

      curItem.costList = curItem.costList.sort(
        function(a,b) {return (a.name+a.color).localeCompare(b.name+b.color) }
      )
      
    }
    
}

const materialCatalog = new MaterialCatalogModel();
export default materialCatalog;

