
//  mqc/desk/report/txn


import {observable, computed, action} from 'mobx';

class TxnHistoryDataModel
{
  // loaded from server
  @observable isWorking=0;
  @observable basicReport=null; 
  @observable reportYear=2017; 



  @action loadData(token)
  {
    try{
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/report/txn",
    {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
      method: "POST",
      body: JSON.stringify({
              year:this.reportYear,
            })
    })
    .then(function(res){saneThis.isWorking--;return res.json() })
    .then(function(res){ 
      saneThis.basicReport = res.data;
    } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ console.log(res); window.Materialize.toast('Unable to load transaction history.', 3000, 'rounded red'); })    
    }
    catch(e){console.log(e);}
  } 

            //  -- 0: due date
          //  -- 1: actual payment
          //  ----   0: cash / check
          //  ----   1: online
          //  -- 2: est material cost
          //  -- 3: actual cost
          //  ----   0: receipt
          //  ----   1: labor
          //  ----   2: travel
          //  ----   3: tax

  @computed get monthMarkersPlan()
  {
    var res = {
      list:[],
      income:0,
      cost:0,
      total:0
    };

    if(!this.basicReport){
      return res;
    }
    for(var ctr=0;ctr<this.basicReport.length;ctr++){
      if(this.basicReport[ctr].mainType==='0' || this.basicReport[ctr].mainType==='2'){
        var ym = this.basicReport[ctr].yearMonth;
        if(!res[ym]){
          res.list.push(ym);
          res[ym]={
            itemList:[],
            income:0,
            cost:0,
            yearMonth:ym,
          }
        }
        res[ym].itemList.push(this.basicReport[ctr]);
        if(this.basicReport[ctr].mainType==='0'){
          res[ym].income += (Number(this.basicReport[ctr].amount)||0);
          res.income += (Number(this.basicReport[ctr].amount)||0);
        }
        else{
          res[ym].cost += (Number(this.basicReport[ctr].amount)||0);
          res.cost += (Number(this.basicReport[ctr].amount)||0);
        }
      }
    }
    res.total = res.income - res.cost;
    return res;
  }


  @computed get monthMarkersActual()
  {
    var res = {
      list:[],
      income:0,
      cost:0,
      total:0
    };

    if(!this.basicReport){
      return res;
    }

    for(var ctr=0;ctr<this.basicReport.length;ctr++){
      if(this.basicReport[ctr].mainType==='1' || this.basicReport[ctr].mainType==='3'){
        var ym = this.basicReport[ctr].yearMonth;      
        if(!res[ym]){
          res.list.push(ym);
          res[ym]={
            itemList:[],
            income:0,
            cost:0,
            yearMonth:ym,
          }
        }
        res[ym].itemList.push(this.basicReport[ctr]);
        if(this.basicReport[ctr].mainType==='1'){
          res[ym].income += (Number(this.basicReport[ctr].amount)||0);
          res.income += (Number(this.basicReport[ctr].amount)||0);
        }
        else{
          res[ym].cost += (Number(this.basicReport[ctr].amount)||0);
          res.cost += (Number(this.basicReport[ctr].amount)||0);
        }
      }
    }
    res.total = res.income - res.cost;


    return res;
  }

  savePDF(year,appUserId)
  {
    window.open(`https://modaquote.azurewebsites.net/mqc/yearend/pdf/${year}/${appUserId}`, '_blank');      
  }


}

const TxnHistory = new TxnHistoryDataModel();
export default TxnHistory;
