import ColorList from './ColorList';

var ColorNameMap = {}
ColorList.forEach( (itemArray) => { 
  itemArray.forEach( (item) => {
    ColorNameMap[item.n.trim().toLowerCase()] = item.c; 
  });
});

export default ColorNameMap;
