

import { observable } from 'mobx';


class HelpDataModel {
  // loaded from server
  @observable helpVersion = 8;

  
}

const HelpData = new HelpDataModel();
export default HelpData;
