

import {observable, computed, action} from 'mobx';
//import {observable} from 'mobx';


class RFPDataModel
{
    // loaded from server
    @observable isWorking=0;
    @observable saveNeeded=false;

    // order data
    @observable doc={};
    @observable selectedQuotes = [];
    @observable selectedQuoteNames = [];
    @observable skipItemList = [];

    // order load information
    @observable orderList=[];
    @observable vendorList = [];
    @observable docId='';
    @observable status='New';

    // ui support
    @observable isMenuOpen=false;
    @observable isShowingPaid=false;
    @observable isShowingDelete=false;
    @observable gridBuilder = observable.map({});  //react to addition of new keys
    @observable gridNotes = observable.map({});  //react to addition of new keys
    

    @action toggleClient(docId,clientName,token)
    {
      // gurgle.  paralelle arrays?  *urp*
      if(this.selectedQuotes.includes(docId)){ 
        var i = this.selectedQuotes.indexOf(docId);
        if(i !== -1) {        this.selectedQuotes.splice(i, 1);              }
        i = this.selectedQuoteNames.indexOf(clientName);
        if(i !== -1) {        this.selectedQuoteNames.splice(i, 1);              }
        this.gridBuilder.set(docId,{});
      }else{
        // order matters here
        docId = docId.replace(/\s+/g, ''); // can the mystery spaces
        this.fetchMaterialUsage(docId,clientName,token);
        this.selectedQuotes.push(docId);
        this.selectedQuoteNames.push(clientName);
      }
    }

    @action fetchMaterialUsage(docId,clientName,token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      this.gridBuilder[docId] = observable({'loading':0}); // so we know it's observable later
      fetch("mqc/quote/load/materials/"+docId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "GET"
      })
      .then(function(res){saneThis.isWorking--;return res.json() })
      .then(function(res){ 
        if(res.error){
          console.log('ERROR',res.error);
          return;
        }
        console.log('mat usage',res);

        saneThis.gridBuilder.set(docId,observable(res));  
      } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); })    
    }

    @computed get materialNameList()
    {
      var res=[];
      for(var ctr=0;ctr<this.selectedQuotes.length;ctr++){
        var docId = this.selectedQuotes[ctr];
        if(this.gridBuilder.has(docId)){
            var curList = this.gridBuilder.get(docId);
            var keys = Object.keys(curList);
            for(var m=0;m<keys.length;m++){
                if(!res.includes(keys[m])){
                    res.push(keys[m]);
                }
            }
        }
      }
      res.sort();
      return res;
    }
    
    // an array in parallel to materialNameList with counts of items needed by docId
    @computed get gridObjectList()
    {
      var res = [];

      for(var m=0;m<this.materialNameList.length;m++){
        var curLine={};
        curLine.name=this.materialNameList[m];
        curLine.total=0;
        var docIdList = Array.from(this.gridBuilder.keys());
        for(var ctr=0;ctr<docIdList.length;ctr++){
          var qty = ( Number( this.gridBuilder.get(docIdList[ctr])[curLine.name] ) || 0);
          curLine[ docIdList[ctr] ] = qty; // count for this docID
          curLine.total+=qty;
        }
        res.push(curLine);
      }

      return res;
    }

    @computed get completenessNote()
    {
      if(this.materialNameList.length===0){ return '';}
      return " Includes "+ (this.materialNameList.length-this.skipItemList.length) +
                  " of "+(this.materialNameList.length)+" materials for "+this.selectedQuoteNames.join(',');
    }

    @computed get gridListWithNotes()
    {
      var res = [];

      this.gridNotes.toJS();

      for(var i=0;i<this.materialNameList.length;i++){
        var curLine={};
        var curName = this.gridObjectList[i].name

        var name = curName;
        var color = curName;
        var idx = name.indexOf('=');
        if(-1!==idx){
          name = name.substr(0,idx).trim();
          color = color.substr(idx+1).trim();
        }
        else{
          color='';
        }

        if(this.skipItemList.includes(curName)){
          curLine = {
            name:name,
            color:color,
            inc:this.gridObjectList[i].name,
            included:'N',
            count:'-',
            note:this.gridNotes.get(curName)
          };
          for(var ctrz=0;ctrz<this.selectedQuotes.length;ctrz++){
            curLine['c'+ctrz]='-';
          }    
        }
        else{
          curLine = {
            name:name,
            color:color,
            note:this.gridNotes.get(curName),
            inc:this.gridObjectList[i].name,
            included:'Y',
            count:this.gridObjectList[i].total,
          };

          for(var ctr=0;ctr<this.selectedQuotes.length;ctr++){
            var docId = this.selectedQuotes[ctr];
            curLine['c'+ctr]=this.gridObjectList[i][docId];
          }    
        }

        res.push(curLine);
      }
      return res;
    }


    @computed get fullDoc()
    { 
      //-- actual data
      var res = this.doc;
      //-- stored for ease of access when building PDF upstairs.  recalculated by UI
      res.records = this.gridListWithNotes;
      res.completenessNote = this.completenessNote;
      //-- stored for laziness when refreshing the UI after an order load
      res.selectedQuotes = this.selectedQuotes;
      res.selectedQuoteNames = this.selectedQuoteNames;
      res.skipItemList = this.skipItemList;
      res.gridNotes = this.gridNotes;
      return res;
    }

    // list vendors
    @action listVendors(token) {
      var saneThis = this;
      saneThis.isWorking++;

      fetch("mqc/vendor/list/",
        {
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
          method: "GET",
        })
        .then(function (res) { saneThis.isWorking--; return res.json() })
        .then(function (res) {
          saneThis.vendorList = res;
        }) // don't forget to bind or JavaScript with drop it's brain on the floor.
        .catch(function (res) { console.log(res); window.Materialize.toast('Unable to list vendors.', 3000, 'rounded red'); })
    }

    // this.props.LoginData.token
    @action saveOrder(token, router, goalPage)
    {
      this.saveNeeded=false;  // would rather this was in the handler, but doesn't seem to work
      var saneThis = this;
      saneThis.isWorking++;
      saneThis.router=router;
      saneThis.goalPage =goalPage;

      fetch("mqc/order/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify(this.fullDoc)
      })
      .then(function(res){saneThis.isWorking--;
        console.log(res);
        if (!res.ok) { console.log( '>> not OK >>'+JSON.stringify(res));    throw res; }
          return res.text() }
        )
      .then(function(res){ 
        var fix = res.substring(1,res.length-1);
        if(-1===fix.indexOf('Error')){ 
          window.Materialize.toast('Order Saved', 3000, 'rounded'); 
        }
        else{ 
          window.Materialize.toast(fix, 3000, 'rounded'); 
        }
        if(saneThis.goalPage){
          saneThis.router.push(saneThis.goalPage);      
        }
      } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to save order changes.', 3000, 'rounded red'); })    
    }

    @action listOrder(token, year)
    {
      var saneThis = this;
      saneThis.isWorking++;

      fetch("mqc/order/list/"+year,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "GET",
      })
      .then(function(res){saneThis.isWorking--;return res.json() })
      .then(function(res){ 
        saneThis.orderList = res;
      } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to list orders.', 3000, 'rounded red'); })    
    }

    @action loadOrder(token)
    {
      var saneThis = this;
      saneThis.isWorking++;

      fetch("mqc/order/load/"+this.docId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "GET",
      })
      .then(function(res){saneThis.isWorking--;return res.json() })
      .then(function(res){ 
        saneThis.doc = res;
        saneThis.gridBuilder = observable.map({});  //react to addition of new keys
        //saneThis.gridNotes = observable.map({});  //react to addition of new keys
        saneThis.skipItemList.replace(res.skipItemList);
        saneThis.gridNotes = observable.map(res.gridNotes);
        saneThis.selectedQuotes.clear();
        saneThis.selectedQuoteNames.clear();
        if(res.selectedQuotes){
          for(var ctr=0;ctr<res.selectedQuotes.length;ctr++){
            saneThis.toggleClient(res.selectedQuotes[ctr], res.selectedQuoteNames[ctr], token);
          }
        }

        // need to collect up the correct users 
        // collect correct material names
        // put the notes back in the correct place
        saneThis.docId='';
      } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to load order.', 3000, 'rounded red'); })    
    }    

    @action deleteOrder(token,router)
    {
      var saneThis = this;
      saneThis.isWorking++;
      console.log("mqc/order/delete/"+this.doc.docId);

      fetch("mqc/order/delete/"+this.doc.docId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "GET",
      })
      .then(function(res){
        saneThis.isWorking--;
        if(!res.ok){
          window.Materialize.toast('Unable to delete order.', 3000, 'rounded red');
          return;
        }
        else{
          window.Materialize.toast('Order deleted.', 3000, 'rounded green');
          router.push('desk.home');
          return;
        }
      })
      .catch(function(res){ 
        console.log(res); 
        router.push('desk.home');
        window.Materialize.toast('Unable to delete order.', 3000, 'rounded red'); 
      })    

    }    


    @action sendPDF(token)
    {
      var saneThis = this;
      saneThis.isWorking++;

      fetch("mqc/order/send/"+saneThis.doc.docId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "GET",
      })
      .then(function(res){saneThis.isWorking--;  
        if(res.ok){
          window.Materialize.toast('Order Sent - Changing Status', 3000, 'rounded'); 
          saneThis.doc.status='SENT';
          saneThis.saveOrder(token);
        }
        else{console.log(res);window.Materialize.toast('Unable to send e-mail.', 3000, 'rounded red');}
      })
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to send e-mail.', 3000, 'rounded red'); })    
    }    

    @action savePDF(token,appUserId)
    {
      window.open(`https://modaquote.azurewebsites.net/mqc/order/pdf/${this.doc.docId}/${appUserId}`, '_blank');      
    }    
    

    @action toggleIsMenuOpen()
    { 
      if(!this){return;} // why?
      this.isMenuOpen = !this.isMenuOpen;
    }


}

const RFPData = new RFPDataModel();
export default RFPData;




