


import React from 'react';

import autoBind from 'react-autobind';
import {observer,inject} from 'mobx-react';

import styled from 'styled-components';

import HeaderBar from './Parts/HeaderBar';
//import FooterBar from './Parts/FooterBar';

import ContentEasy from './Parts/ContentEasy';

const ContentHolder = styled.div`  
  position:absolute; 
  top: 60px;
  left:0px; 
  right:-20px; 
`;



@inject("LoginData","ScreenDataStore") @observer 
class HomePage2 extends React.Component {
  
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    autoBind(this);    
  }

  componentDidMount()
  {
    //--- ensure user is logged out
    this.props.LoginData.fbToken = "";
    this.props.LoginData.appUserId = "";
  }


  render() {

    var settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe:true,
      autoplay:true,
      autoplaySpeed:4000,
      centerMode:true,
      fade:true,
      arrows:true,
      pauseOnHover:true,
    };

    var bottomOffset='0px';

    return (
      <div>
        <HeaderBar/>

        <ContentHolder style={{bottom:bottomOffset}}>
          <div style={{padding:'0px',height:'100%',overflowY:'auto',overflowX:'hidden'}}>
            <ContentEasy settings={settings}/>
          </div>
        </ContentHolder>
      </div>
      
    );
  }
}

export default HomePage2;
