
import React from 'react';
import styled from 'styled-components';
//import {Link} from 'react-router';
import {observer,inject} from 'mobx-react';
//import autoBind from 'react-autobind';
import MenuHeaderButton from '../../../Tools/MenuHeaderButton';
import {Link} from 'react-router';

const MenuTitle = styled.span`
  font-family: 'Tangerine', cursive;
  color: #000000 !important;
`;

const MenuHolder = styled.div`  
  position:absolute; 
  top: 0px; 
  left:0px; 
  right:0px; 
  height:60px;
  z-index:5;
  background-color: seashell;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.55);
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.55);  
  vertical-align:top;
  line-height:1;
`;


export default 
@inject("ScreenDataStore") @observer 
class HeaderBar extends React.Component
{

  render(){
    var titleLoc = 'left';

    var title = <MenuTitle tid='home1' className={"waves-effect " + titleLoc} style={{ margin: '5px', paddingTop: '1px', fontSize: '36pt' }}>&nbsp;&nbsp;ModaQuote&nbsp;</MenuTitle>
    if (this.props.ScreenDataStore.screenWide < 370) {
      title = <MenuTitle tid='home1' className={"waves-effect " + titleLoc} style={{ margin: '5px', paddingTop: '1px', fontSize: '24pt' }}>&nbsp;&nbsp;ModaQuote&nbsp;</MenuTitle>
    }

    return(
        <MenuHolder>
        <div>
          <Link to="/" >
            {title}
          </Link>
          &nbsp;
          <Link to="/register.chooser" ><MenuHeaderButton style={{maxWidth:'150px',width:'150px'}} tid='register1' bigLabel='Sign Up' smallLabel="Let's start your account" go='right' /></Link>
          <Link to="/login.start" ><MenuHeaderButton style={{ maxWidth: '150px', width: '150px' }} tid='signIn1' bigLabel='Sign In' smallLabel="Let's book some quotes" go='right' /></Link>
        </div>
        </MenuHolder>
    );
  }
}
