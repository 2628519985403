
import { observable, computed, action, autorun } from 'mobx';
import moment from 'moment';
import uuid from 'uuid';


class ClientDataModel {
  @observable isWorking = 0;

  @observable isEditing = false;
  @observable isImporting = false;
  @observable isExporting = false;
  @observable showActiveClients = 1;


  @observable curClient = {

    id: '',
    clientId: '',
    name: '',
    category: '',

    main_contact: '',
    main_fax: "",
    main_phone: "",
    main_ext: '',

    billing_contact_title: '',
    billing_contact_first: '',
    billing_contact_last: '',
    billing_address_1: "",
    billing_address_2: '',
    billing_city: "Sacramento",
    billing_state: "CA",
    billing_zip: "95815",
    billing_contact_phone: '',
    billing_contact_ext: '',
    billing_contact_email: '',
    billing_pers_phone: '',

    note: '',
    order_notes: '',
    resale_number: '',
    tax_id: '',
    show_in_list: '',

    cc_type: '',
    cc_number: '',
    cc_name: '',
    cc_exp_date: '',
    cc_auth_num: '',

    contactList: [
      {
        contact_title: "",
        first_name: "",
        last_name: "",
        ct_email: "",

        ct_bus_phone: "",
        ct_bus_phone_ext: "",
        ct_pers_phone: "",
        ct_bus_fax: "",
      }
      ,
      {
        contact_title: "",
        first_name: "",
        last_name: "",
        ct_email: "",

        ct_bus_phone: "",
        ct_bus_phone_ext: "",
        ct_pers_phone: "",
        ct_bus_fax: "",
      }
      ,
      {
        contact_title: "",
        first_name: "",
        last_name: "",
        ct_email: "",

        ct_bus_phone: "",
        ct_bus_phone_ext: "",
        ct_pers_phone: "",
        ct_bus_fax: "",
      }

    ]
  };

  @action clientNew() {
    this.curClient = {

      id: '',
      clientId: uuid.v4(),
      name: '',
      category: '',

      main_contact: '',
      main_fax: "",
      main_phone: "",
      main_ext: '',

      billing_contact_title: '',
      billing_contact_first:'',
      billing_contact_last:'',
      billing_address_1: "",
      billing_address_2:'',
      billing_city: "Sacramento",
      billing_state: "CA",
      billing_zip: "95815",
      billing_contact_phone:'',
      billing_contact_ext:'',
      billing_contact_email:'',
      billing_pers_phone: '',

      note: '',
      order_notes:'',
      resale_number: '',
      tax_id:'',
      show_in_list: '',

      cc_type:'',
      cc_number:'',
      cc_name:'',
      cc_exp_date:'',
      cc_auth_num:'',

      contactList:[
        {
          contact_title: "",
          first_name: "Annette",
          last_name: "Pereira",
          ct_email: "",

          ct_bus_phone: "9168254522",
          ct_bus_phone_ext: "",
          ct_pers_phone: "",
          ct_bus_fax: "9164851004",
        }
      ,
        {
          contact_title: "",
          first_name: "",
          last_name: "",
          ct_email: "",

          ct_bus_phone: "",
          ct_bus_phone_ext: "",
          ct_pers_phone: "",
          ct_bus_fax: "",
        }

      ]
    };
  }


  @observable clientFilter = "";
  @observable clientCatFilter = "";
  @observable clientList = [];
  @observable clientSelected = "";

  @observable nameDir = 'u';
  @observable catDir='-';

  @action clickSort(type){
    if('name'===type){
      this.catDir='-';
      if (this.nameDir==='u'){
        this.nameDir='d';
      }else{
        this.nameDir = 'u';
      }
    }
    if ('cat' === type) {
      this.nameDir = '-';
      if (this.catDir === 'u') {
        this.catDir = 'd';
      } else {
        this.catDir = 'u';
      }
    }
    console.log(this.nameDir, this.catDir);
  }

  @computed get clientCategoryList() {
    var res = [];

    if (this.clientList) {
      for (var ctr = 0; ctr < this.clientList.length; ctr++) {
        var cur = (this.clientList[ctr].category || '--').toLowerCase();
        if (!res.includes(cur)) {
          res.push(cur);
        }
      }
    }

    // add cur editor item
    var cur = (this.curClient.category || '--').toLowerCase();
    if (!res.includes(cur)) {
      res.push(cur);
    }

    res.sort();
    return res;
  }

  @computed get clientFilteredList() {
    var res = [];
    res.push('--');
    if (this.clientList) {
      for (var ctr = 0; ctr < this.clientList.length; ctr++) {
        var curCat = (this.clientList[ctr].category || this.clientList[ctr].type || '--').toLowerCase();
        if (
          this.clientList[ctr].name.toLowerCase().contains(this.clientFilter.toLowerCase()) && 
          (curCat.contains(this.clientCatFilter.toLowerCase()) || this.clientCatFilter === '--')
        ) {
          res.push(this.clientList[ctr]);
        }
      }
    }

    var saneThis = this;
    if(this.catDir!=='-'){
      return res.sort(function (a, b) {
        var nameA = (a.category || '--').toLowerCase(), nameB = (b.category || '--').toLowerCase()
        if (nameA < nameB) //sort string ascending
          return (saneThis.catDir === 'u') ? -1 : 1
        if (nameA > nameB)
          return (saneThis.catDir === 'u') ? 1 : -1
        return 0
      });
    }
    else{
      return res.sort(function (a, b) {
        var nameA = (a.name || '').toLowerCase(), nameB = (b.name || '').toLowerCase()
        if (nameA < nameB) //sort string ascending
          return (saneThis.nameDir === 'u') ? -1 : 1
        if (nameA > nameB)
          return (saneThis.nameDir === 'u') ? 1 : -1
        return 0
      });
    }
  }

  //================ Client work
  @action clientSave(token) {
    var saneThis = this;
    saneThis.isWorking++;

    if(!this.curClient.clientId){
      this.curClient.clientId=uuid.v4();
    }


    fetch("mqc/desk/client/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(this.curClient)
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) { window.Materialize.toast('Settings Saved', 3000, 'rounded'); }
        else { console.log(res); window.Materialize.toast(res, 3000, 'rounded'); }
        saneThis.clientLoadList(token); // in case of clones or adds
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save provider changes.', 3000, 'rounded'); }
      );
  }

  async clientImport(token,jsonString) {
    var saneThis = this;
    saneThis.isWorking++;

    console.log(jsonString.length);
    var t = JSON.parse(jsonString);

    var idx=0;
    while(idx<t.length){
      var end=idx+50;
      var curSet = [];
      if(end>t.length){ 
        curSet = t.slice(idx);
      }
      else{
        curSet = t.slice(idx,end);
      }
      idx +=50;

      await fetch("/mqc/desk/client/import",
        {
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
          method: "POST",
          body: JSON.stringify(curSet)
        })
        .then(function (res) {
          saneThis.isWorking--;
          if (res.error) {
            console.log(res.error);
            saneThis.lastError = "Unable to load this client import.";
            return;
          }
          return res.text()
        })
        .then(function (res) {
          console.log('complete idx: ',idx);
          // window.Materialize.toast('Import Complete', 3000, 'rounded');
        }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
        .catch(function (res) { console.log(res); })
    }
  }
  

  @action clientExport(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/client/export",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this Client export.";
          return;
        }
        return res.json()
      })
      .then(function (res) {

        // send the file to be downloaded
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(res)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "ClientExport.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action clientLoadList(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch(`/mqc/desk/client/search?showActiveClients=${this.showActiveClients}`,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this team.";
          return;
        }
        return res.json()
      })
      .then(function (res) {
        for(var ctr=0;ctr<res.length;ctr++){
          res[ctr].category = (res[ctr].category || res[ctr].type ||'--').toLowerCase();
          if (res[ctr].category === 'assn./nonprofit') { res[ctr].category = 'assn/nonprofit';}
          if (res[ctr].category === 'hotels/caters') { res[ctr].category = 'hotels/caterers'; }
          if (res[ctr].category === 'vender') { res[ctr].category = 'vendors'; }
        }
        saneThis.clientList = res;
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action clientLoad(clientData, token) {

    this.clientSelected = clientData.name;
    this.isEditing = true;
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/client/load/" + clientData.clientId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "GET",
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {

        res.category = res.category || res.type || '--';
        if(!res.contactList){
          res.contactList= [];
        }
        saneThis.curClient = res;
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load Client changes.', 3000, 'rounded'); }
      );
  }


  @action clientDelete(token) 
  {
    this.isEditing = false;
    this.curClient.name='';
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/desk/client/delete/" + this.curClient.clientId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "GET",
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        saneThis.clientLoadList(token); // in case of clones or adds
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load Client changes.', 3000, 'rounded'); }
      );
  }

  @action clientCopy( token) {
    this.curClient.clientId = uuid.v4();
    this.clientSave(token);
  }

  @action clientCancel() {
    this.isEditing = false;
  }


}


const ClientData = new ClientDataModel();
export default ClientData;
