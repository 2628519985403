
import styled from 'styled-components';

const BarContentRight = styled.div`
  position:absolute;
  top:0px;
  bottom:0px;
  right:25%;
  left:25%;
  background-image:url(${props => props.src});
  overFlow:hidden;
  background-size: cover;
  background-position: center center;
  padding:25px;
`;

export default BarContentRight;