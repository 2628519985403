
import {observable,computed} from "mobx";

class ScreenData
{
    @observable screenWide = 320;
    @observable screenHigh = 320;

    @observable hideChatWindow = true;

    @observable marketingIndex = 0; // cheat.  This should be in it's own separate store.  But yuck.

    @computed get screenMaxVidWide() 
    {
        var borderSize= 80;
        if( ((this.screenWide/16)*9) > (this.screenHigh - borderSize) ){
            return ((this.screenHigh-borderSize)/9)*16;
        }
        return this.screenWide;
    }

    @computed get menuActualWide()
    {
        return this.screenWide/3;
    }
    @computed get detailsActualWide()
    {
        return ((this.screenWide/3)*2)-25;
    }
    
}

const ScreenDataStore = new ScreenData();
export default ScreenDataStore;