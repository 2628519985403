
import styled from 'styled-components';

const BarImageRight = styled.div`
  position:absolute;
  top:0px;
  bottom:0px;
  right:0px;
  background-image:url(${props => props.src});
  overFlow:hidden;
  background-size: cover;
  background-position: center center;
  width:25%;
  max-width: 400px;
  vertical-align:bottom;
  color: darkblue;
  font-size:50%;
`;

export default BarImageRight;