
import {observable, computed, action} from 'mobx';
import moment from 'moment';



class PayDataModel
{
    // loaded from server
    @observable isWorking=0;
    @observable basicReport={};            
    @observable reportYear=2017;            


  @action updateData(token, yearVal)
  {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("/mqc/desk/report/quote",
    {
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
      method: "POST",
      body: JSON.stringify({ year: yearVal })
    })
    .then(function(res){saneThis.isWorking--;return res.json() })
    .then(function(res){ 
      // replace with something real
      console.log(res);
      saneThis.basicReport = res;
    } ) // don't forget to bind or JavaScript with drop it's brain on the floor.
    .catch(function(res){ console.log(res); })    
  }


    @computed get eventTotals()
    {
      var holdNow = new moment();
      if(!this.basicReport){
        console.log('no data');
        return null;
      };
      let res = {
        costMistakeCount:0,
        costMistakeAmount:0,
      };
      var ykey;
      for(var yctr=2015;yctr<2030;yctr++){
          ykey='G'+yctr+'P'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
          ykey='G'+yctr+'F'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
          ykey='R'+yctr+'P'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
          ykey='R'+yctr+'F'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
      }
      ykey='G-P'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
      ykey='G-F'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
      ykey='R-P'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};
      ykey='R-F'; res[ykey]={payGoal:0,costGoal:0,payReal:0,costReal:0,count:0};

      for(var ctr=0;ctr<this.basicReport.length;ctr++){
          var eventDate = moment(this.basicReport[ctr].eventDate);
          var targetYear = eventDate.year();
          var isPast = eventDate.isBefore( holdNow );

          var realOrGoal = 'G';
          if(this.basicReport[ctr].payReal>0 || this.basicReport[ctr].costReal>0){
            realOrGoal='R'
          }
          var key = realOrGoal+targetYear+ ( isPast ? 'P' : 'F' );
          var keyAll = realOrGoal+'-'+ ( isPast ? 'P' : 'F' );

          if(this.basicReport[ctr].costReal > this.basicReport[ctr].costGoal){
            res.costMistakeCount += 1;
            res.costMistakeAmount += (this.basicReport[ctr].costReal-this.basicReport[ctr].costGoal);
          }

          res[key].count += 1;
          res[key].payGoal += this.basicReport[ctr].payGoal;
          res[key].costGoal += this.basicReport[ctr].costGoal;
          res[key].payReal += this.basicReport[ctr].payReal;
          res[key].costReal += this.basicReport[ctr].costReal;

          res[keyAll].count += 1;
          res[keyAll].payGoal += this.basicReport[ctr].payGoal;
          res[keyAll].costGoal += this.basicReport[ctr].costGoal;
          res[keyAll].payReal += this.basicReport[ctr].payReal;
          res[keyAll].costReal += this.basicReport[ctr].costReal;
          
      }

      return res;
    }
    
}

const PayData = new PayDataModel();
export default PayData;
