

const ColorList = [
[
  {
    "n": "white",
    "c": "#FFFFFF"
  },
  {
    "n": "snow",
    "c": "#FFFAFA"
  },
  {
    "n": "mint cream",
    "c": "#F5FFFA"
  },
  {
    "n": "ghost white",
    "c": "#F8F8FF"
  },
  {
    "n": "pale grey",
    "c": "#FDFDFE"
  },
  {
    "n": "white smoke",
    "c": "#F5F5F5"
  },
  {
    "n": "very light pink",
    "c": "#FFF4F2"
  },
  {
    "n": "seashell",
    "c": "#FFF5EE"
  },
  {
    "n": "floral white",
    "c": "#FFFAF0"
  },
  {
    "n": "ivory",
    "c": "#FFFFF0"
  },
  {
    "n": "honeydew",
    "c": "#F0FFF0"
  },
  {
    "n": "azure",
    "c": "#F0FFFF"
  },
  {
    "n": "alice blue",
    "c": "#F0F8FF"
  },
  {
    "n": "lavender blush",
    "c": "#FFF0F5"
  },
  {
    "n": "misty rose",
    "c": "#FFE4E1"
  },
  {
    "n": "linen",
    "c": "#FAF0E6"
  },
  {
    "n": "old lace",
    "c": "#FDF5E6"
  },
  {
    "n": "off white",
    "c": "#FFFFE4"
  },
  {
    "n": "lavender",
    "c": "#E6E6FA"
  },
  {
    "n": "cornsilk",
    "c": "#FFF8DC"
  },
  {
    "n": "light yellow",
    "c": "#FFFFE0"
  },
  {
    "n": "ice blue",
    "c": "#D7FFFE"
  },
  {
    "n": "light cyan",
    "c": "#E0FFFF"
  },
  {
    "n": "antique white",
    "c": "#FAEBD7"
  },
  {
    "n": "blanched almond",
    "c": "#FFEBCD"
  },
  {
    "n": "papaya whip",
    "c": "#FFEFD5"
  },
  {
    "n": "pale",
    "c": "#FFF9D0"
  },
  {
    "n": "lemon chiffon",
    "c": "#FFFACD"
  },
  {
    "n": "beige",
    "c": "#F5F5DC"
  },
  {
    "n": "light goldenrod yellow",
    "c": "#FAFAD2"
  },
  {
    "n": "eggshell",
    "c": "#FFFFD4"
  },
  {
    "n": "ice",
    "c": "#D6FFFA"
  },
  {
    "n": "very pale blue",
    "c": "#D6FFFE"
  },
  {
    "n": "really light blue",
    "c": "#D4FFFF"
  },
  {
    "n": "very light blue",
    "c": "#D5FFFF"
  },
  {
    "n": "pale blue",
    "c": "#D0FEFE"
  },
  {
    "n": "pale lavender",
    "c": "#EECFFE"
  },
  {
    "n": "pale mauve",
    "c": "#FED0FC"
  },
  {
    "n": "pale pink",
    "c": "#FFCFDC"
  },
  {
    "n": "bisque",
    "c": "#FFE4C4"
  },
  {
    "n": "egg shell",
    "c": "#FFFCC4"
  },
  {
    "n": "cream",
    "c": "#FFFFC2"
  },
  {
    "n": "eggshell blue",
    "c": "#C4FFF7"
  },
  {
    "n": "light light blue",
    "c": "#CAFFFB"
  },
  {
    "n": "light lavender",
    "c": "#DFC5FE"
  },
  {
    "n": "pale lilac",
    "c": "#E4CBFF"
  },
  {
    "n": "light lilac",
    "c": "#EDC8FF"
  },
  {
    "n": "very light purple",
    "c": "#F6CEFC"
  },
  {
    "n": "light rose",
    "c": "#FFC5CB"
  },
  {
    "n": "gainsboro",
    "c": "#DCDCDC"
  },
  {
    "n": "peach puff",
    "c": "#FFDAB9"
  },
  {
    "n": "very light green",
    "c": "#D1FFBD"
  },
  {
    "n": "very pale green",
    "c": "#CFFDBC"
  },
  {
    "n": "pale aqua",
    "c": "#B8FFEB"
  },
  {
    "n": "duck egg blue",
    "c": "#C3FBF4"
  },
  {
    "n": "pale sky blue",
    "c": "#BDF6FE"
  },
  {
    "n": "light periwinkle",
    "c": "#C1C6FC"
  },
  {
    "n": "light lavendar",
    "c": "#EFC0FE"
  },
  {
    "n": "pastel pink",
    "c": "#FFBACD"
  },
  {
    "n": "pink",
    "c": "#FFC0CB"
  },
  {
    "n": "pale rose",
    "c": "#FDC1C5"
  },
  {
    "n": "light peach",
    "c": "#FFD8B1"
  },
  {
    "n": "moccasin",
    "c": "#FFE4B5"
  },
  {
    "n": "parchment",
    "c": "#FEFCAF"
  },
  {
    "n": "light beige",
    "c": "#FFFEB6"
  },
  {
    "n": "creme",
    "c": "#FFFFB6"
  },
  {
    "n": "light light green",
    "c": "#C8FFB0"
  },
  {
    "n": "celadon",
    "c": "#BEFDB7"
  },
  {
    "n": "light mint",
    "c": "#B6FFBB"
  },
  {
    "n": "pale cyan",
    "c": "#B7FFFA"
  },
  {
    "n": "light violet",
    "c": "#D6B4FC"
  },
  {
    "n": "powder pink",
    "c": "#FFB2D0"
  },
  {
    "n": "baby pink",
    "c": "#FFB7CE"
  },
  {
    "n": "soft pink",
    "c": "#FDB0C0"
  },
  {
    "n": "light pink",
    "c": "#FFB6C1"
  },
  {
    "n": "light grey",
    "c": "#D3D3D3"
  },
  {
    "n": "navajo white",
    "c": "#FFDEAD"
  },
  {
    "n": "wheat",
    "c": "#F5DEB3"
  },
  {
    "n": "pale peach",
    "c": "#FFE5AD"
  },
  {
    "n": "light tan",
    "c": "#FBEEAC"
  },
  {
    "n": "light seafoam green",
    "c": "#A7FFB5"
  },
  {
    "n": "pale violet",
    "c": "#CEAEFA"
  },
  {
    "n": "pale salmon",
    "c": "#FFB19A"
  },
  {
    "n": "dark cream",
    "c": "#FFF39A"
  },
  {
    "n": "pale goldenrod",
    "c": "#EEE8AA"
  },
  {
    "n": "buff",
    "c": "#FEF69E"
  },
  {
    "n": "tea green",
    "c": "#BDF8A3"
  },
  {
    "n": "washed out green",
    "c": "#BCF5A6"
  },
  {
    "n": "light sage",
    "c": "#BCECAC"
  },
  {
    "n": "pastel green",
    "c": "#B0FF9D"
  },
  {
    "n": "light pastel green",
    "c": "#B2FBA5"
  },
  {
    "n": "light mint green",
    "c": "#A6FBB2"
  },
  {
    "n": "light seafoam",
    "c": "#A0FEBF"
  },
  {
    "n": "pale turquoise",
    "c": "#AFEEEE"
  },
  {
    "n": "baby blue",
    "c": "#A2CFFE"
  },
  {
    "n": "light blue grey",
    "c": "#B7C9E2"
  },
  {
    "n": "pastel blue",
    "c": "#A2BFFE"
  },
  {
    "n": "pastel purple",
    "c": "#CAA0FF"
  },
  {
    "n": "light khaki",
    "c": "#E6F2A2"
  },
  {
    "n": "celery",
    "c": "#C1FD95"
  },
  {
    "n": "pale light green",
    "c": "#B1FC99"
  },
  {
    "n": "pale green",
    "c": "#98FB98"
  },
  {
    "n": "mint green",
    "c": "#8FFF9F"
  },
  {
    "n": "robin's egg blue",
    "c": "#98EFF9"
  },
  {
    "n": "powder blue",
    "c": "#B0E0E6"
  },
  {
    "n": "light blue",
    "c": "#ADD8E6"
  },
  {
    "n": "light steel blue",
    "c": "#B0C4DE"
  },
  {
    "n": "baby purple",
    "c": "#CA9BF7"
  },
  {
    "n": "peachy pink",
    "c": "#FF9A8A"
  },
  {
    "n": "manilla",
    "c": "#FFFA86"
  },
  {
    "n": "light yellowish green",
    "c": "#C2FF89"
  },
  {
    "n": "pistachio",
    "c": "#C0FA8B"
  },
  {
    "n": "foam green",
    "c": "#90FDA9"
  },
  {
    "n": "light aqua",
    "c": "#8CFFDB"
  },
  {
    "n": "robin egg blue",
    "c": "#8AF1FE"
  },
  {
    "n": "cloudy blue",
    "c": "#ACC2D9"
  },
  {
    "n": "carolina blue",
    "c": "#8AB8FE"
  },
  {
    "n": "periwinkle blue",
    "c": "#8F99FB"
  },
  {
    "n": "liliac",
    "c": "#C48EFD"
  },
  {
    "n": "rosa",
    "c": "#FE86A4"
  },
  {
    "n": "silver",
    "c": "#C0C0C0"
  },
  {
    "n": "blush",
    "c": "#F29E8E"
  },
  {
    "n": "peach",
    "c": "#FFB07C"
  },
  {
    "n": "khaki",
    "c": "#F0E68C"
  },
  {
    "n": "banana",
    "c": "#FFFF7E"
  },
  {
    "n": "yellowish tan",
    "c": "#FCFC81"
  },
  {
    "n": "butter",
    "c": "#FFFF81"
  },
  {
    "n": "pale yellow",
    "c": "#FFFF84"
  },
  {
    "n": "faded yellow",
    "c": "#FEFF7F"
  },
  {
    "n": "light yellow green",
    "c": "#CCFD7F"
  },
  {
    "n": "light pea green",
    "c": "#C4FE82"
  },
  {
    "n": "light grey green",
    "c": "#B7E1A1"
  },
  {
    "n": "easter green",
    "c": "#8CFD7E"
  },
  {
    "n": "light green",
    "c": "#90EE90"
  },
  {
    "n": "aquamarine",
    "c": "#7FFFD4"
  },
  {
    "n": "light sky blue",
    "c": "#87CEFA"
  },
  {
    "n": "sky",
    "c": "#82CAFC"
  },
  {
    "n": "lavender blue",
    "c": "#8B88F8"
  },
  {
    "n": "plum",
    "c": "#DDA0DD"
  },
  {
    "n": "bubblegum pink",
    "c": "#FE83CC"
  },
  {
    "n": "pinky",
    "c": "#FC86AA"
  },
  {
    "n": "faded pink",
    "c": "#DE9DAC"
  },
  {
    "n": "blush pink",
    "c": "#FE828C"
  },
  {
    "n": "light coral",
    "c": "#F08080"
  },
  {
    "n": "pinkish grey",
    "c": "#C8ACA9"
  },
  {
    "n": "light salmon",
    "c": "#FFA07A"
  },
  {
    "n": "sandy yellow",
    "c": "#FDEE73"
  },
  {
    "n": "straw",
    "c": "#FCF679"
  },
  {
    "n": "custard",
    "c": "#FFFD78"
  },
  {
    "n": "butter yellow",
    "c": "#FFFD74"
  },
  {
    "n": "pastel yellow",
    "c": "#FFFE71"
  },
  {
    "n": "pale lime",
    "c": "#BEFD73"
  },
  {
    "n": "lightgreen",
    "c": "#76FF7B"
  },
  {
    "n": "light bluish green",
    "c": "#76FDA8"
  },
  {
    "n": "seafoam",
    "c": "#80F9AD"
  },
  {
    "n": "seafoam green",
    "c": "#7AF9AB"
  },
  {
    "n": "light blue green",
    "c": "#7EFBB3"
  },
  {
    "n": "light teal",
    "c": "#90E4C1"
  },
  {
    "n": "light aquamarine",
    "c": "#7BFDC7"
  },
  {
    "n": "light turquoise",
    "c": "#7EF4CC"
  },
  {
    "n": "sky blue",
    "c": "#87CEEB"
  },
  {
    "n": "lightblue",
    "c": "#7BC8F6"
  },
  {
    "n": "light grey blue",
    "c": "#9DBCD4"
  },
  {
    "n": "perrywinkle",
    "c": "#8F8CE7"
  },
  {
    "n": "pig pink",
    "c": "#E78EA5"
  },
  {
    "n": "salmon pink",
    "c": "#FE7B7C"
  },
  {
    "n": "salmon",
    "c": "#FA8072"
  },
  {
    "n": "apricot",
    "c": "#FFB16D"
  },
  {
    "n": "pale gold",
    "c": "#FDDE6C"
  },
  {
    "n": "sandy",
    "c": "#F1DA7A"
  },
  {
    "n": "yellow tan",
    "c": "#FFE36E"
  },
  {
    "n": "light lime",
    "c": "#AEFD6C"
  },
  {
    "n": "key lime",
    "c": "#AEFF6E"
  },
  {
    "n": "tiffany blue",
    "c": "#7BF2DA"
  },
  {
    "n": "easter purple",
    "c": "#C071FE"
  },
  {
    "n": "light purple",
    "c": "#BF77F6"
  },
  {
    "n": "hot pink",
    "c": "#FF69B4"
  },
  {
    "n": "bubblegum",
    "c": "#FF6CB5"
  },
  {
    "n": "bubble gum pink",
    "c": "#FF69AF"
  },
  {
    "n": "dark salmon",
    "c": "#E9967A"
  },
  {
    "n": "pinkish tan",
    "c": "#D99B82"
  },
  {
    "n": "burly wood",
    "c": "#DEB887"
  },
  {
    "n": "tan",
    "c": "#D2B48C"
  },
  {
    "n": "sand yellow",
    "c": "#FCE166"
  },
  {
    "n": "yellowish",
    "c": "#FAEE66"
  },
  {
    "n": "canary",
    "c": "#FDFF63"
  },
  {
    "n": "light lime green",
    "c": "#B9FF66"
  },
  {
    "n": "pale lime green",
    "c": "#B1FF65"
  },
  {
    "n": "light grass green",
    "c": "#9AF764"
  },
  {
    "n": "lighter green",
    "c": "#75FD63"
  },
  {
    "n": "pale purple",
    "c": "#B790D4"
  },
  {
    "n": "bright lavender",
    "c": "#C760FF"
  },
  {
    "n": "violet pink",
    "c": "#FB5FFC"
  },
  {
    "n": "lavender pink",
    "c": "#DD85D7"
  },
  {
    "n": "purply pink",
    "c": "#F075E6"
  },
  {
    "n": "candy pink",
    "c": "#FF63E9"
  },
  {
    "n": "dull pink",
    "c": "#D5869D"
  },
  {
    "n": "rosy pink",
    "c": "#F6688E"
  },
  {
    "n": "dusty pink",
    "c": "#D58A94"
  },
  {
    "n": "coral pink",
    "c": "#FF6163"
  },
  {
    "n": "dark grey",
    "c": "#A9A9A9"
  },
  {
    "n": "grapefruit",
    "c": "#FD5956"
  },
  {
    "n": "orange pink",
    "c": "#FF6F52"
  },
  {
    "n": "melon",
    "c": "#FF7855"
  },
  {
    "n": "sandy brown",
    "c": "#F4A460"
  },
  {
    "n": "pale orange",
    "c": "#FFA756"
  },
  {
    "n": "very light brown",
    "c": "#D3B683"
  },
  {
    "n": "light mustard",
    "c": "#F7D560"
  },
  {
    "n": "sand",
    "c": "#E2CA76"
  },
  {
    "n": "light gold",
    "c": "#FDDC5C"
  },
  {
    "n": "lemon",
    "c": "#FDFF52"
  },
  {
    "n": "pear",
    "c": "#CBF85F"
  },
  {
    "n": "light bright green",
    "c": "#53FE5C"
  },
  {
    "n": "light green blue",
    "c": "#56FCA2"
  },
  {
    "n": "light greenish blue",
    "c": "#63F7B4"
  },
  {
    "n": "cornflower blue",
    "c": "#6495ED"
  },
  {
    "n": "medium slate blue",
    "c": "#7B68EE"
  },
  {
    "n": "bright lilac",
    "c": "#C95EFB"
  },
  {
    "n": "light magenta",
    "c": "#FA5FF7"
  },
  {
    "n": "medium pink",
    "c": "#F36196"
  },
  {
    "n": "light mauve",
    "c": "#C292A1"
  },
  {
    "n": "grey pink",
    "c": "#C3909B"
  },
  {
    "n": "greyish pink",
    "c": "#C88D94"
  },
  {
    "n": "rosy brown",
    "c": "#BC8F8F"
  },
  {
    "n": "pinkish orange",
    "c": "#FF724C"
  },
  {
    "n": "coral",
    "c": "#FF7F50"
  },
  {
    "n": "pastel orange",
    "c": "#FF964F"
  },
  {
    "n": "fawn",
    "c": "#CFAF7B"
  },
  {
    "n": "putty",
    "c": "#BEAE8A"
  },
  {
    "n": "maize",
    "c": "#F4D054"
  },
  {
    "n": "dull yellow",
    "c": "#EEDC5B"
  },
  {
    "n": "banana yellow",
    "c": "#FAFE4B"
  },
  {
    "n": "greenish beige",
    "c": "#C9D179"
  },
  {
    "n": "pale olive",
    "c": "#B9CC81"
  },
  {
    "n": "pale olive green",
    "c": "#B1D27B"
  },
  {
    "n": "dark sea green",
    "c": "#8FBC8F"
  },
  {
    "n": "light neon green",
    "c": "#4EFD54"
  },
  {
    "n": "pale teal",
    "c": "#82CBB2"
  },
  {
    "n": "seafoam blue",
    "c": "#78D1B6"
  },
  {
    "n": "soft blue",
    "c": "#6488EA"
  },
  {
    "n": "medium purple",
    "c": "#9370DB"
  },
  {
    "n": "lighter purple",
    "c": "#A55AF4"
  },
  {
    "n": "orchid",
    "c": "#DA70D6"
  },
  {
    "n": "pale violet red",
    "c": "#DB7093"
  },
  {
    "n": "muted pink",
    "c": "#D1768F"
  },
  {
    "n": "warm pink",
    "c": "#FB5581"
  },
  {
    "n": "old rose",
    "c": "#C87F89"
  },
  {
    "n": "tomato",
    "c": "#FF6347"
  },
  {
    "n": "orangish",
    "c": "#FC824A"
  },
  {
    "n": "orangeish",
    "c": "#FD8D49"
  },
  {
    "n": "faded orange",
    "c": "#F0944D"
  },
  {
    "n": "mushroom",
    "c": "#BA9E88"
  },
  {
    "n": "light orange",
    "c": "#FDAA48"
  },
  {
    "n": "butterscotch",
    "c": "#FDB147"
  },
  {
    "n": "toupe",
    "c": "#C7AC7D"
  },
  {
    "n": "sandstone",
    "c": "#C9AE74"
  },
  {
    "n": "greyish",
    "c": "#A8A495"
  },
  {
    "n": "canary yellow",
    "c": "#FFFE40"
  },
  {
    "n": "greenish tan",
    "c": "#BCCB7A"
  },
  {
    "n": "light moss green",
    "c": "#A6C875"
  },
  {
    "n": "lightish green",
    "c": "#61E160"
  },
  {
    "n": "bright cyan",
    "c": "#41FDFE"
  },
  {
    "n": "wisteria",
    "c": "#A87DC2"
  },
  {
    "n": "heliotrope",
    "c": "#D94FF5"
  },
  {
    "n": "pale magenta",
    "c": "#D767AD"
  },
  {
    "n": "dusky pink",
    "c": "#CC7A8B"
  },
  {
    "n": "pinkish",
    "c": "#D46A7E"
  },
  {
    "n": "old pink",
    "c": "#C77986"
  },
  {
    "n": "watermelon",
    "c": "#FD4659"
  },
  {
    "n": "dirty pink",
    "c": "#CA7B80"
  },
  {
    "n": "light red",
    "c": "#FF474C"
  },
  {
    "n": "brownish pink",
    "c": "#C27E79"
  },
  {
    "n": "dark peach",
    "c": "#DE7E5D"
  },
  {
    "n": "taupe",
    "c": "#B9A281"
  },
  {
    "n": "stone",
    "c": "#ADA587"
  },
  {
    "n": "cement",
    "c": "#A5A391"
  },
  {
    "n": "sunshine yellow",
    "c": "#FFFD37"
  },
  {
    "n": "lemon yellow",
    "c": "#FDFF38"
  },
  {
    "n": "off yellow",
    "c": "#F1F33F"
  },
  {
    "n": "kiwi",
    "c": "#9CEF43"
  },
  {
    "n": "greenish grey",
    "c": "#96AE8D"
  },
  {
    "n": "medium aquamarine",
    "c": "#66CDAA"
  },
  {
    "n": "cool grey",
    "c": "#95A3A6"
  },
  {
    "n": "bluegrey",
    "c": "#85A3B2"
  },
  {
    "n": "lightish blue",
    "c": "#3D7AFD"
  },
  {
    "n": "purpley",
    "c": "#8756E4"
  },
  {
    "n": "lightish purple",
    "c": "#A552E6"
  },
  {
    "n": "dusty lavender",
    "c": "#AC86A8"
  },
  {
    "n": "dusty rose",
    "c": "#C0737A"
  },
  {
    "n": "indian red",
    "c": "#CD5C5C"
  },
  {
    "n": "pastel red",
    "c": "#DB5856"
  },
  {
    "n": "dusty orange",
    "c": "#F0833A"
  },
  {
    "n": "mango",
    "c": "#FFA62B"
  },
  {
    "n": "sand brown",
    "c": "#CBA560"
  },
  {
    "n": "desert",
    "c": "#CCAD60"
  },
  {
    "n": "dark khaki",
    "c": "#BDB76B"
  },
  {
    "n": "light olive",
    "c": "#ACBF69"
  },
  {
    "n": "tan green",
    "c": "#A9BE70"
  },
  {
    "n": "green yellow",
    "c": "#ADFF2F"
  },
  {
    "n": "sage green",
    "c": "#88B378"
  },
  {
    "n": "soft green",
    "c": "#6FC276"
  },
  {
    "n": "bright light green",
    "c": "#2DFE54"
  },
  {
    "n": "greenish cyan",
    "c": "#2AFEB7"
  },
  {
    "n": "greyblue",
    "c": "#77A1B5"
  },
  {
    "n": "dark sky blue",
    "c": "#448EE4"
  },
  {
    "n": "light royal blue",
    "c": "#3A2EFE"
  },
  {
    "n": "dark periwinkle",
    "c": "#665FD1"
  },
  {
    "n": "light indigo",
    "c": "#6D5ACF"
  },
  {
    "n": "purpleish blue",
    "c": "#6140EF"
  },
  {
    "n": "deep lilac",
    "c": "#966EBD"
  },
  {
    "n": "heather",
    "c": "#A484AC"
  },
  {
    "n": "medium orchid",
    "c": "#BA55D3"
  },
  {
    "n": "purpleish pink",
    "c": "#DF4EC8"
  },
  {
    "n": "purplish pink",
    "c": "#CE5DAE"
  },
  {
    "n": "reddish pink",
    "c": "#FE2C54"
  },
  {
    "n": "lightish red",
    "c": "#FE2F4A"
  },
  {
    "n": "pale red",
    "c": "#D9544D"
  },
  {
    "n": "orangey red",
    "c": "#FA4224"
  },
  {
    "n": "pale brown",
    "c": "#B1916E"
  },
  {
    "n": "dust",
    "c": "#B2996E"
  },
  {
    "n": "camel",
    "c": "#C69F59"
  },
  {
    "n": "macaroni and cheese",
    "c": "#EFB435"
  },
  {
    "n": "sun yellow",
    "c": "#FFDF22"
  },
  {
    "n": "lemon lime",
    "c": "#BFFE28"
  },
  {
    "n": "kiwi green",
    "c": "#8EE53F"
  },
  {
    "n": "grey",
    "c": "#929591"
  },
  {
    "n": "grey/green",
    "c": "#86A17D"
  },
  {
    "n": "fresh green",
    "c": "#69D84F"
  },
  {
    "n": "greyish green",
    "c": "#82A67D"
  },
  {
    "n": "faded green",
    "c": "#7BB274"
  },
  {
    "n": "hot green",
    "c": "#25FF29"
  },
  {
    "n": "weird green",
    "c": "#3AE57F"
  },
  {
    "n": "turquoise",
    "c": "#40E0D0"
  },
  {
    "n": "bright light blue",
    "c": "#26F7FD"
  },
  {
    "n": "faded blue",
    "c": "#658CBB"
  },
  {
    "n": "clear blue",
    "c": "#247AFD"
  },
  {
    "n": "royal blue",
    "c": "#4169E1"
  },
  {
    "n": "warm blue",
    "c": "#4B57DB"
  },
  {
    "n": "slate blue",
    "c": "#6A5ACD"
  },
  {
    "n": "bluish purple",
    "c": "#703BE7"
  },
  {
    "n": "electric purple",
    "c": "#AA23FF"
  },
  {
    "n": "amethyst",
    "c": "#9B5FC0"
  },
  {
    "n": "soft purple",
    "c": "#A66FB5"
  },
  {
    "n": "pinkish purple",
    "c": "#D648D7"
  },
  {
    "n": "pink purple",
    "c": "#DB4BDA"
  },
  {
    "n": "purple pink",
    "c": "#E03FD8"
  },
  {
    "n": "darkish pink",
    "c": "#DA467D"
  },
  {
    "n": "mauve",
    "c": "#AE7181"
  },
  {
    "n": "red pink",
    "c": "#FA2A55"
  },
  {
    "n": "pinky red",
    "c": "#FC2647"
  },
  {
    "n": "dusky rose",
    "c": "#BA6873"
  },
  {
    "n": "strawberry",
    "c": "#FB2943"
  },
  {
    "n": "dark coral",
    "c": "#CF524E"
  },
  {
    "n": "reddish orange",
    "c": "#F8481C"
  },
  {
    "n": "warm grey",
    "c": "#978A84"
  },
  {
    "n": "sunny yellow",
    "c": "#FFF917"
  },
  {
    "n": "lime yellow",
    "c": "#D0FE1D"
  },
  {
    "n": "yellowy green",
    "c": "#BFF128"
  },
  {
    "n": "light olive green",
    "c": "#A4BE5C"
  },
  {
    "n": "radioactive green",
    "c": "#2CFA1F"
  },
  {
    "n": "dusty green",
    "c": "#76A973"
  },
  {
    "n": "spearmint",
    "c": "#1EF876"
  },
  {
    "n": "wintergreen",
    "c": "#20F986"
  },
  {
    "n": "aqua marine",
    "c": "#2EE8BB"
  },
  {
    "n": "medium turquoise",
    "c": "#48D1CC"
  },
  {
    "n": "dodger blue",
    "c": "#1E90FF"
  },
  {
    "n": "vivid blue",
    "c": "#152EFF"
  },
  {
    "n": "purpley blue",
    "c": "#5F34E7"
  },
  {
    "n": "purple blue",
    "c": "#632DE9"
  },
  {
    "n": "purplish blue",
    "c": "#601EF9"
  },
  {
    "n": "burple",
    "c": "#6832E3"
  },
  {
    "n": "deep lavender",
    "c": "#8D5EB7"
  },
  {
    "n": "pinky purple",
    "c": "#C94CBE"
  },
  {
    "n": "faded red",
    "c": "#D3494E"
  },
  {
    "n": "pinkish brown",
    "c": "#B17261"
  },
  {
    "n": "orangered",
    "c": "#FE420F"
  },
  {
    "n": "terracotta",
    "c": "#CA6641"
  },
  {
    "n": "terracota",
    "c": "#CB6843"
  },
  {
    "n": "dull orange",
    "c": "#D8863B"
  },
  {
    "n": "peru",
    "c": "#CD853F"
  },
  {
    "n": "yellowish orange",
    "c": "#FFAB0F"
  },
  {
    "n": "dark beige",
    "c": "#AC9362"
  },
  {
    "n": "orangey yellow",
    "c": "#FDB915"
  },
  {
    "n": "golden yellow",
    "c": "#FEC615"
  },
  {
    "n": "poison green",
    "c": "#40FD14"
  },
  {
    "n": "grey green",
    "c": "#789B73"
  },
  {
    "n": "neon green",
    "c": "#0CFF0C"
  },
  {
    "n": "tea",
    "c": "#65AB7C"
  },
  {
    "n": "greyish teal",
    "c": "#719F91"
  },
  {
    "n": "bright turquoise",
    "c": "#0FFEF9"
  },
  {
    "n": "bluish grey",
    "c": "#748B97"
  },
  {
    "n": "grey blue",
    "c": "#6B8BA4"
  },
  {
    "n": "light slate grey",
    "c": "#778899"
  },
  {
    "n": "blue violet",
    "c": "#8A2BE2"
  },
  {
    "n": "neon purple",
    "c": "#BC13FE"
  },
  {
    "n": "dark lilac",
    "c": "#9C6DA5"
  },
  {
    "n": "purpley grey",
    "c": "#947E94"
  },
  {
    "n": "pink/purple",
    "c": "#EF1DE7"
  },
  {
    "n": "deep pink",
    "c": "#FF1493"
  },
  {
    "n": "dark pink",
    "c": "#CB416B"
  },
  {
    "n": "deep rose",
    "c": "#C74767"
  },
  {
    "n": "reddish",
    "c": "#C44240"
  },
  {
    "n": "reddish grey",
    "c": "#997570"
  },
  {
    "n": "vermillion",
    "c": "#F4320C"
  },
  {
    "n": "red orange",
    "c": "#FD3C06"
  },
  {
    "n": "clay",
    "c": "#B66A50"
  },
  {
    "n": "blood orange",
    "c": "#FE4B03"
  },
  {
    "n": "terra cotta",
    "c": "#C9643B"
  },
  {
    "n": "adobe",
    "c": "#BD6C48"
  },
  {
    "n": "pumpkin orange",
    "c": "#FB7D07"
  },
  {
    "n": "tangerine",
    "c": "#FF9408"
  },
  {
    "n": "orange yellow",
    "c": "#FFAD01"
  },
  {
    "n": "squash",
    "c": "#F2AB15"
  },
  {
    "n": "dark sand",
    "c": "#A88F59"
  },
  {
    "n": "amber",
    "c": "#FEB308"
  },
  {
    "n": "golden rod",
    "c": "#F9BC08"
  },
  {
    "n": "marigold",
    "c": "#FCC006"
  },
  {
    "n": "sunflower yellow",
    "c": "#FFDA03"
  },
  {
    "n": "dandelion",
    "c": "#FEDF08"
  },
  {
    "n": "bright yellow",
    "c": "#FFFD01"
  },
  {
    "n": "neon yellow",
    "c": "#CFFF04"
  },
  {
    "n": "greeny yellow",
    "c": "#C6F808"
  },
  {
    "n": "yellowgreen",
    "c": "#BBF90F"
  },
  {
    "n": "electric lime",
    "c": "#A8FF04"
  },
  {
    "n": "acid green",
    "c": "#8FFE09"
  },
  {
    "n": "bright lime",
    "c": "#87FD05"
  },
  {
    "n": "bright lime green",
    "c": "#65FE08"
  },
  {
    "n": "turtle green",
    "c": "#75B84F"
  },
  {
    "n": "dull green",
    "c": "#74A662"
  },
  {
    "n": "green grey",
    "c": "#77926F"
  },
  {
    "n": "highlighter green",
    "c": "#1BFC06"
  },
  {
    "n": "electric green",
    "c": "#21FC0D"
  },
  {
    "n": "fluro green",
    "c": "#0AFF02"
  },
  {
    "n": "fluorescent green",
    "c": "#08FF08"
  },
  {
    "n": "dark pastel green",
    "c": "#56AE57"
  },
  {
    "n": "bright green",
    "c": "#01FF07"
  },
  {
    "n": "algae",
    "c": "#54AC68"
  },
  {
    "n": "dark mint",
    "c": "#48C072"
  },
  {
    "n": "minty green",
    "c": "#0BF77D"
  },
  {
    "n": "bright sea green",
    "c": "#05FFA6"
  },
  {
    "n": "bright aqua",
    "c": "#0BF9EA"
  },
  {
    "n": "neon blue",
    "c": "#04D9FF"
  },
  {
    "n": "bright sky blue",
    "c": "#02CCFE"
  },
  {
    "n": "cool blue",
    "c": "#4984B8"
  },
  {
    "n": "dusty blue",
    "c": "#5A86AD"
  },
  {
    "n": "steel",
    "c": "#738595"
  },
  {
    "n": "off blue",
    "c": "#5684AE"
  },
  {
    "n": "slate grey",
    "c": "#708090"
  },
  {
    "n": "azul",
    "c": "#1D5DEC"
  },
  {
    "n": "electric blue",
    "c": "#0652FF"
  },
  {
    "n": "blurple",
    "c": "#5539CC"
  },
  {
    "n": "bluey purple",
    "c": "#6241C7"
  },
  {
    "n": "purply blue",
    "c": "#661AEE"
  },
  {
    "n": "bright violet",
    "c": "#AD0AFD"
  },
  {
    "n": "greyish purple",
    "c": "#887191"
  },
  {
    "n": "bright purple",
    "c": "#BE03FD"
  },
  {
    "n": "faded purple",
    "c": "#916E99"
  },
  {
    "n": "purple/pink",
    "c": "#D725DE"
  },
  {
    "n": "bright magenta",
    "c": "#FF08E8"
  },
  {
    "n": "purpley pink",
    "c": "#C83CB9"
  },
  {
    "n": "shocking pink",
    "c": "#FE02A2"
  },
  {
    "n": "electric pink",
    "c": "#FF0490"
  },
  {
    "n": "strong pink",
    "c": "#FF0789"
  },
  {
    "n": "neon red",
    "c": "#FF073A"
  },
  {
    "n": "dusty red",
    "c": "#B9484E"
  },
  {
    "n": "red",
    "c": "#FF0000"
  },
  {
    "n": "dull red",
    "c": "#BB3F3F"
  },
  {
    "n": "orangish red",
    "c": "#F43605"
  },
  {
    "n": "orange red",
    "c": "#FF4500"
  },
  {
    "n": "bright orange",
    "c": "#FF5B00"
  },
  {
    "n": "tan brown",
    "c": "#AB7E4C"
  },
  {
    "n": "light brown",
    "c": "#AD8150"
  },
  {
    "n": "puce",
    "c": "#A57E52"
  },
  {
    "n": "dark orange",
    "c": "#FF8C00"
  },
  {
    "n": "dark tan",
    "c": "#AF884A"
  },
  {
    "n": "orange",
    "c": "#FFA500"
  },
  {
    "n": "yellow orange",
    "c": "#FCB001"
  },
  {
    "n": "goldenrod",
    "c": "#DAA520"
  },
  {
    "n": "brown grey",
    "c": "#8D8468"
  },
  {
    "n": "gold",
    "c": "#F5BF03"
  },
  {
    "n": "yellow",
    "c": "#FFFF00"
  },
  {
    "n": "greenish yellow",
    "c": "#CDFD02"
  },
  {
    "n": "lemon green",
    "c": "#ADF802"
  },
  {
    "n": "yellow green",
    "c": "#9ACD32"
  },
  {
    "n": "bright yellow green",
    "c": "#9DFF00"
  },
  {
    "n": "chartreuse",
    "c": "#7FFF00"
  },
  {
    "n": "lawn green",
    "c": "#7CFC00"
  },
  {
    "n": "green apple",
    "c": "#5EDC1F"
  },
  {
    "n": "medium grey",
    "c": "#7D7F7C"
  },
  {
    "n": "off green",
    "c": "#6BA353"
  },
  {
    "n": "vivid green",
    "c": "#2FEF10"
  },
  {
    "n": "lime",
    "c": "#00FF00"
  },
  {
    "n": "lime green",
    "c": "#32CD32"
  },
  {
    "n": "spring green",
    "c": "#00FF7F"
  },
  {
    "n": "turquoise green",
    "c": "#04F489"
  },
  {
    "n": "medium spring green",
    "c": "#00FA9A"
  },
  {
    "n": "greenish turquoise",
    "c": "#00FBB0"
  },
  {
    "n": "grey teal",
    "c": "#5E9B8A"
  },
  {
    "n": "greeny blue",
    "c": "#42B395"
  },
  {
    "n": "dull teal",
    "c": "#5F9E8F"
  },
  {
    "n": "bright teal",
    "c": "#01F9C6"
  },
  {
    "n": "cyan",
    "c": "#00FFFF"
  },
  {
    "n": "cadet blue",
    "c": "#5F9EA0"
  },
  {
    "n": "deep sky blue",
    "c": "#00BFFF"
  },
  {
    "n": "steel grey",
    "c": "#6F828A"
  },
  {
    "n": "greyish blue",
    "c": "#5E819D"
  },
  {
    "n": "steel blue",
    "c": "#4682B4"
  },
  {
    "n": "windows blue",
    "c": "#3778BF"
  },
  {
    "n": "bright blue",
    "c": "#0165FC"
  },
  {
    "n": "vibrant blue",
    "c": "#0339F8"
  },
  {
    "n": "rich blue",
    "c": "#021BF9"
  },
  {
    "n": "blue",
    "c": "#0000FF"
  },
  {
    "n": "primary blue",
    "c": "#0804F9"
  },
  {
    "n": "strong blue",
    "c": "#0C06F7"
  },
  {
    "n": "blue purple",
    "c": "#5729CE"
  },
  {
    "n": "vivid purple",
    "c": "#9900FA"
  },
  {
    "n": "dark lavender",
    "c": "#856798"
  },
  {
    "n": "dark orchid",
    "c": "#9932CC"
  },
  {
    "n": "grey purple",
    "c": "#826D8C"
  },
  {
    "n": "hot purple",
    "c": "#CB00F5"
  },
  {
    "n": "magenta",
    "c": "#FF00FF"
  },
  {
    "n": "purple grey",
    "c": "#866F85"
  },
  {
    "n": "hot magenta",
    "c": "#F504C9"
  },
  {
    "n": "bright pink",
    "c": "#FE01B1"
  },
  {
    "n": "neon pink",
    "c": "#FE019A"
  },
  {
    "n": "pink red",
    "c": "#F5054F"
  },
  {
    "n": "pinkish red",
    "c": "#F10C45"
  },
  {
    "n": "dark rose",
    "c": "#B5485D"
  },
  {
    "n": "cherry red",
    "c": "#F7022A"
  },
  {
    "n": "bright red",
    "c": "#FF000D"
  },
  {
    "n": "fire engine red",
    "c": "#FE0002"
  },
  {
    "n": "tomato red",
    "c": "#EC2D01"
  },
  {
    "n": "brownish",
    "c": "#9C6D57"
  },
  {
    "n": "chocolate",
    "c": "#D2691E"
  },
  {
    "n": "clay brown",
    "c": "#B2713D"
  },
  {
    "n": "mocha",
    "c": "#9D7651"
  },
  {
    "n": "brownish orange",
    "c": "#CB7723"
  },
  {
    "n": "coffee",
    "c": "#A6814C"
  },
  {
    "n": "yellowish green",
    "c": "#B0DD16"
  },
  {
    "n": "apple green",
    "c": "#76CD26"
  },
  {
    "n": "moss",
    "c": "#769958"
  },
  {
    "n": "leafy green",
    "c": "#51B73B"
  },
  {
    "n": "muted green",
    "c": "#5FA052"
  },
  {
    "n": "mid green",
    "c": "#50A747"
  },
  {
    "n": "slate green",
    "c": "#658D6D"
  },
  {
    "n": "cool green",
    "c": "#33B864"
  },
  {
    "n": "medium sea green",
    "c": "#3CB371"
  },
  {
    "n": "dark seafoam green",
    "c": "#3EAF76"
  },
  {
    "n": "greenish teal",
    "c": "#32BF84"
  },
  {
    "n": "aqua green",
    "c": "#12E193"
  },
  {
    "n": "aqua blue",
    "c": "#02D8E9"
  },
  {
    "n": "battleship grey",
    "c": "#6B7C85"
  },
  {
    "n": "blue grey",
    "c": "#607C8E"
  },
  {
    "n": "grey/blue",
    "c": "#647D8E"
  },
  {
    "n": "stormy blue",
    "c": "#507B9C"
  },
  {
    "n": "cerulean blue",
    "c": "#056EEE"
  },
  {
    "n": "french blue",
    "c": "#436BAD"
  },
  {
    "n": "purple/blue",
    "c": "#5D21D0"
  },
  {
    "n": "purply",
    "c": "#983FB2"
  },
  {
    "n": "purpleish",
    "c": "#98568D"
  },
  {
    "n": "light plum",
    "c": "#9D5783"
  },
  {
    "n": "crimson",
    "c": "#DC143C"
  },
  {
    "n": "pumpkin",
    "c": "#E17701"
  },
  {
    "n": "brownish grey",
    "c": "#86775F"
  },
  {
    "n": "avocado",
    "c": "#90B134"
  },
  {
    "n": "flat green",
    "c": "#699D4C"
  },
  {
    "n": "vibrant green",
    "c": "#0ADD08"
  },
  {
    "n": "medium green",
    "c": "#39AD48"
  },
  {
    "n": "greenish",
    "c": "#40A368"
  },
  {
    "n": "seaweed green",
    "c": "#35AD6B"
  },
  {
    "n": "algae green",
    "c": "#21C36F"
  },
  {
    "n": "tealish green",
    "c": "#0CDC73"
  },
  {
    "n": "seaweed",
    "c": "#18D17B"
  },
  {
    "n": "greenblue",
    "c": "#23C48B"
  },
  {
    "n": "dull blue",
    "c": "#49759C"
  },
  {
    "n": "bluish",
    "c": "#2976BB"
  },
  {
    "n": "flat blue",
    "c": "#3C73A8"
  },
  {
    "n": "blue blue",
    "c": "#2242C7"
  },
  {
    "n": "pure blue",
    "c": "#0203E2"
  },
  {
    "n": "vibrant purple",
    "c": "#AD03DE"
  },
  {
    "n": "purplish grey",
    "c": "#7A687F"
  },
  {
    "n": "muted purple",
    "c": "#805B87"
  },
  {
    "n": "dusty purple",
    "c": "#825F87"
  },
  {
    "n": "purplish",
    "c": "#94568C"
  },
  {
    "n": "dusky purple",
    "c": "#895B7B"
  },
  {
    "n": "cerise",
    "c": "#DE0C62"
  },
  {
    "n": "light burgundy",
    "c": "#A8415B"
  },
  {
    "n": "light maroon",
    "c": "#A24857"
  },
  {
    "n": "deep orange",
    "c": "#DC4D01"
  },
  {
    "n": "earth",
    "c": "#A2653E"
  },
  {
    "n": "copper",
    "c": "#B66325"
  },
  {
    "n": "leather",
    "c": "#AC7434"
  },
  {
    "n": "burnt yellow",
    "c": "#D5AB09"
  },
  {
    "n": "dark yellow",
    "c": "#D5B60A"
  },
  {
    "n": "mustard yellow",
    "c": "#D2BD0A"
  },
  {
    "n": "dirty yellow",
    "c": "#CDC50A"
  },
  {
    "n": "pea",
    "c": "#A4BF20"
  },
  {
    "n": "camo",
    "c": "#7F8F4E"
  },
  {
    "n": "leaf",
    "c": "#71AA34"
  },
  {
    "n": "grass",
    "c": "#5CAC2D"
  },
  {
    "n": "dark sage",
    "c": "#598556"
  },
  {
    "n": "light forest green",
    "c": "#4F9153"
  },
  {
    "n": "jade green",
    "c": "#2BAF6A"
  },
  {
    "n": "dark mint green",
    "c": "#20C073"
  },
  {
    "n": "bluey green",
    "c": "#2BB179"
  },
  {
    "n": "dusty teal",
    "c": "#4C9085"
  },
  {
    "n": "tealish",
    "c": "#24BCA8"
  },
  {
    "n": "dirty blue",
    "c": "#3F829D"
  },
  {
    "n": "water blue",
    "c": "#0E87CC"
  },
  {
    "n": "metallic blue",
    "c": "#4F738E"
  },
  {
    "n": "muted blue",
    "c": "#3B719F"
  },
  {
    "n": "mid blue",
    "c": "#276AB3"
  },
  {
    "n": "dusky blue",
    "c": "#475F94"
  },
  {
    "n": "twilight",
    "c": "#4E518B"
  },
  {
    "n": "blueberry",
    "c": "#464196"
  },
  {
    "n": "ultramarine blue",
    "c": "#1805DB"
  },
  {
    "n": "dull purple",
    "c": "#84597E"
  },
  {
    "n": "medium violet red",
    "c": "#C71585"
  },
  {
    "n": "dark hot pink",
    "c": "#D90166"
  },
  {
    "n": "dim grey",
    "c": "#696969"
  },
  {
    "n": "brown",
    "c": "#A52A2A"
  },
  {
    "n": "fire brick",
    "c": "#B22222"
  },
  {
    "n": "sienna",
    "c": "#A0522D"
  },
  {
    "n": "rusty orange",
    "c": "#CD5909"
  },
  {
    "n": "rust orange",
    "c": "#C45508"
  },
  {
    "n": "browny orange",
    "c": "#CA6B02"
  },
  {
    "n": "dark taupe",
    "c": "#7F684E"
  },
  {
    "n": "dirty orange",
    "c": "#C87606"
  },
  {
    "n": "dull brown",
    "c": "#876E4B"
  },
  {
    "n": "grey brown",
    "c": "#7F7053"
  },
  {
    "n": "yellow ochre",
    "c": "#CB9D06"
  },
  {
    "n": "mustard",
    "c": "#CEB301"
  },
  {
    "n": "brownish yellow",
    "c": "#C9B003"
  },
  {
    "n": "gross green",
    "c": "#A0BF16"
  },
  {
    "n": "fern green",
    "c": "#548D44"
  },
  {
    "n": "ocean green",
    "c": "#3D9973"
  },
  {
    "n": "dark seafoam",
    "c": "#1FB57A"
  },
  {
    "n": "sea",
    "c": "#3C9992"
  },
  {
    "n": "topaz",
    "c": "#13BBAF"
  },
  {
    "n": "light sea green",
    "c": "#20B2AA"
  },
  {
    "n": "dark turquoise",
    "c": "#00CED1"
  },
  {
    "n": "cerulean",
    "c": "#0485D1"
  },
  {
    "n": "denim blue",
    "c": "#3B5B92"
  },
  {
    "n": "sapphire",
    "c": "#2138AB"
  },
  {
    "n": "dusk",
    "c": "#4E5481"
  },
  {
    "n": "true blue",
    "c": "#010FCC"
  },
  {
    "n": "medium blue",
    "c": "#0000CD"
  },
  {
    "n": "violet blue",
    "c": "#510AC9"
  },
  {
    "n": "dark violet",
    "c": "#9400D3"
  },
  {
    "n": "barney",
    "c": "#AC1DB8"
  },
  {
    "n": "light eggplant",
    "c": "#894585"
  },
  {
    "n": "dark mauve",
    "c": "#874C62"
  },
  {
    "n": "cherry",
    "c": "#CF0234"
  },
  {
    "n": "brick",
    "c": "#A03623"
  },
  {
    "n": "brick orange",
    "c": "#C14A09"
  },
  {
    "n": "cocoa",
    "c": "#875F42"
  },
  {
    "n": "sepia",
    "c": "#985E2B"
  },
  {
    "n": "greyish brown",
    "c": "#7A6A4F"
  },
  {
    "n": "dark goldenrod",
    "c": "#B8860B"
  },
  {
    "n": "ochre",
    "c": "#BF9005"
  },
  {
    "n": "ocre",
    "c": "#C69C04"
  },
  {
    "n": "ocher",
    "c": "#BF9B0C"
  },
  {
    "n": "dark gold",
    "c": "#B59410"
  },
  {
    "n": "muddy yellow",
    "c": "#BFAC05"
  },
  {
    "n": "olive yellow",
    "c": "#C2B709"
  },
  {
    "n": "drab",
    "c": "#828344"
  },
  {
    "n": "avocado green",
    "c": "#87A922"
  },
  {
    "n": "moss green",
    "c": "#658B38"
  },
  {
    "n": "frog green",
    "c": "#58BC08"
  },
  {
    "n": "shamrock green",
    "c": "#02C14D"
  },
  {
    "n": "teal green",
    "c": "#25A36F"
  },
  {
    "n": "jade",
    "c": "#1FA774"
  },
  {
    "n": "turquoise blue",
    "c": "#06B1C4"
  },
  {
    "n": "slate",
    "c": "#516572"
  },
  {
    "n": "denim",
    "c": "#3B638C"
  },
  {
    "n": "dark slate blue",
    "c": "#483D8B"
  },
  {
    "n": "indigo blue",
    "c": "#3A18B1"
  },
  {
    "n": "warm purple",
    "c": "#952E8F"
  },
  {
    "n": "ruby",
    "c": "#CA0147"
  },
  {
    "n": "lipstick red",
    "c": "#C0022F"
  },
  {
    "n": "brownish red",
    "c": "#9E3623"
  },
  {
    "n": "rusty red",
    "c": "#AF2F0D"
  },
  {
    "n": "burnt sienna",
    "c": "#B04E0F"
  },
  {
    "n": "burnt orange",
    "c": "#C04E01"
  },
  {
    "n": "burnt siena",
    "c": "#B75203"
  },
  {
    "n": "orange brown",
    "c": "#BE6400"
  },
  {
    "n": "brown orange",
    "c": "#B96902"
  },
  {
    "n": "caramel",
    "c": "#AF6F09"
  },
  {
    "n": "yellowy brown",
    "c": "#AE8B0C"
  },
  {
    "n": "mustard green",
    "c": "#A8B504"
  },
  {
    "n": "bright olive",
    "c": "#9CBB04"
  },
  {
    "n": "pea green",
    "c": "#8EAB12"
  },
  {
    "n": "khaki green",
    "c": "#728639"
  },
  {
    "n": "dark lime",
    "c": "#84B701"
  },
  {
    "n": "dark lime green",
    "c": "#7EBD01"
  },
  {
    "n": "swamp",
    "c": "#698339"
  },
  {
    "n": "military green",
    "c": "#667C3E"
  },
  {
    "n": "sea green",
    "c": "#2E8B57"
  },
  {
    "n": "green teal",
    "c": "#0CB577"
  },
  {
    "n": "green/blue",
    "c": "#01C08D"
  },
  {
    "n": "green blue",
    "c": "#06B48B"
  },
  {
    "n": "gunmetal",
    "c": "#536267"
  },
  {
    "n": "rouge",
    "c": "#AB1239"
  },
  {
    "n": "brownish purple",
    "c": "#76424E"
  },
  {
    "n": "scarlet",
    "c": "#BE0119"
  },
  {
    "n": "rust red",
    "c": "#AA2704"
  },
  {
    "n": "rust",
    "c": "#A83C09"
  },
  {
    "n": "burnt umber",
    "c": "#A0450E"
  },
  {
    "n": "cinnamon",
    "c": "#AC4F06"
  },
  {
    "n": "orangish brown",
    "c": "#B25F03"
  },
  {
    "n": "orangey brown",
    "c": "#B16002"
  },
  {
    "n": "raw umber",
    "c": "#A75E09"
  },
  {
    "n": "umber",
    "c": "#B26400"
  },
  {
    "n": "golden brown",
    "c": "#B27A01"
  },
  {
    "n": "mustard brown",
    "c": "#AC7E04"
  },
  {
    "n": "yellow brown",
    "c": "#B79400"
  },
  {
    "n": "brown yellow",
    "c": "#B29705"
  },
  {
    "n": "olive drab",
    "c": "#6B8E23"
  },
  {
    "n": "mossy green",
    "c": "#638B27"
  },
  {
    "n": "kermit green",
    "c": "#5CB200"
  },
  {
    "n": "shamrock",
    "c": "#01B44C"
  },
  {
    "n": "viridian",
    "c": "#1E9167"
  },
  {
    "n": "bluish green",
    "c": "#10A674"
  },
  {
    "n": "light navy blue",
    "c": "#2E5A88"
  },
  {
    "n": "dusk blue",
    "c": "#26538D"
  },
  {
    "n": "ultramarine",
    "c": "#2000B1"
  },
  {
    "n": "purplish red",
    "c": "#B0054B"
  },
  {
    "n": "burnt red",
    "c": "#9F2305"
  },
  {
    "n": "russet",
    "c": "#A13905"
  },
  {
    "n": "bronze",
    "c": "#A87900"
  },
  {
    "n": "hazel",
    "c": "#8E7618"
  },
  {
    "n": "dark mustard",
    "c": "#A88905"
  },
  {
    "n": "muddy green",
    "c": "#657432"
  },
  {
    "n": "dirty green",
    "c": "#667E2C"
  },
  {
    "n": "leaf green",
    "c": "#5CA904"
  },
  {
    "n": "grass green",
    "c": "#3F9B0B"
  },
  {
    "n": "forest green",
    "c": "#228B22"
  },
  {
    "n": "darkish green",
    "c": "#287C37"
  },
  {
    "n": "kelly green",
    "c": "#02AB2E"
  },
  {
    "n": "cobalt",
    "c": "#1E488F"
  },
  {
    "n": "cobalt blue",
    "c": "#030AA7"
  },
  {
    "n": "barney purple",
    "c": "#A00498"
  },
  {
    "n": "dark fuchsia",
    "c": "#9D0759"
  },
  {
    "n": "violet red",
    "c": "#A50055"
  },
  {
    "n": "berry",
    "c": "#990F4B"
  },
  {
    "n": "purplish brown",
    "c": "#6B4247"
  },
  {
    "n": "darkish red",
    "c": "#A90308"
  },
  {
    "n": "deep red",
    "c": "#9A0200"
  },
  {
    "n": "red brown",
    "c": "#8B2E16"
  },
  {
    "n": "auburn",
    "c": "#9A3001"
  },
  {
    "n": "saddle brown",
    "c": "#8B4513"
  },
  {
    "n": "milk chocolate",
    "c": "#7F4E1E"
  },
  {
    "n": "raw sienna",
    "c": "#9A6200"
  },
  {
    "n": "yellowish brown",
    "c": "#9B7A01"
  },
  {
    "n": "pea soup",
    "c": "#929901"
  },
  {
    "n": "dark olive green",
    "c": "#556B2F"
  },
  {
    "n": "sap green",
    "c": "#5C8B15"
  },
  {
    "n": "grassy green",
    "c": "#419C03"
  },
  {
    "n": "emerald",
    "c": "#01A049"
  },
  {
    "n": "teal blue",
    "c": "#01889F"
  },
  {
    "n": "sea blue",
    "c": "#047495"
  },
  {
    "n": "ocean blue",
    "c": "#03719C"
  },
  {
    "n": "light navy",
    "c": "#155084"
  },
  {
    "n": "royal",
    "c": "#0C1793"
  },
  {
    "n": "grape",
    "c": "#6C3461"
  },
  {
    "n": "red violet",
    "c": "#9E0168"
  },
  {
    "n": "deep magenta",
    "c": "#A0025C"
  },
  {
    "n": "reddish purple",
    "c": "#910951"
  },
  {
    "n": "purple red",
    "c": "#990147"
  },
  {
    "n": "carmine",
    "c": "#9D0216"
  },
  {
    "n": "purple brown",
    "c": "#673A3F"
  },
  {
    "n": "brick red",
    "c": "#8F1402"
  },
  {
    "n": "brown red",
    "c": "#922B05"
  },
  {
    "n": "warm brown",
    "c": "#964E02"
  },
  {
    "n": "medium brown",
    "c": "#7F5112"
  },
  {
    "n": "dark yellow green",
    "c": "#728F02"
  },
  {
    "n": "tree green",
    "c": "#2A7E19"
  },
  {
    "n": "emerald green",
    "c": "#028F1E"
  },
  {
    "n": "irish green",
    "c": "#019529"
  },
  {
    "n": "blue green",
    "c": "#137E6D"
  },
  {
    "n": "greenish blue",
    "c": "#0B8B87"
  },
  {
    "n": "ocean",
    "c": "#017B92"
  },
  {
    "n": "peacock blue",
    "c": "#016795"
  },
  {
    "n": "blood red",
    "c": "#980002"
  },
  {
    "n": "dark red",
    "c": "#8B0000"
  },
  {
    "n": "reddish brown",
    "c": "#7F2B0A"
  },
  {
    "n": "rust brown",
    "c": "#8B3103"
  },
  {
    "n": "muddy brown",
    "c": "#886806"
  },
  {
    "n": "mud",
    "c": "#735C12"
  },
  {
    "n": "camo green",
    "c": "#526525"
  },
  {
    "n": "pine",
    "c": "#2B5D34"
  },
  {
    "n": "jungle green",
    "c": "#048243"
  },
  {
    "n": "dark cyan",
    "c": "#008B8B"
  },
  {
    "n": "deep aqua",
    "c": "#08787F"
  },
  {
    "n": "dark blue",
    "c": "#00008B"
  },
  {
    "n": "midnight blue",
    "c": "#191970"
  },
  {
    "n": "dark magenta",
    "c": "#8B008B"
  },
  {
    "n": "darkish purple",
    "c": "#751973"
  },
  {
    "n": "red purple",
    "c": "#820747"
  },
  {
    "n": "red wine",
    "c": "#8C0034"
  },
  {
    "n": "maroon",
    "c": "#800000"
  },
  {
    "n": "greenish brown",
    "c": "#696112"
  },
  {
    "n": "brown green",
    "c": "#706C11"
  },
  {
    "n": "olive",
    "c": "#808000"
  },
  {
    "n": "olive green",
    "c": "#677A04"
  },
  {
    "n": "dark grass green",
    "c": "#388004"
  },
  {
    "n": "green",
    "c": "#008000"
  },
  {
    "n": "dark green blue",
    "c": "#1F6357"
  },
  {
    "n": "bluegreen",
    "c": "#017A79"
  },
  {
    "n": "teal",
    "c": "#008080"
  },
  {
    "n": "dark slate grey",
    "c": "#2F4F4F"
  },
  {
    "n": "charcoal grey",
    "c": "#3C4142"
  },
  {
    "n": "deep sea blue",
    "c": "#015482"
  },
  {
    "n": "dark grey blue",
    "c": "#29465B"
  },
  {
    "n": "twilight blue",
    "c": "#0A437A"
  },
  {
    "n": "darkish blue",
    "c": "#014182"
  },
  {
    "n": "navy",
    "c": "#000080"
  },
  {
    "n": "indigo",
    "c": "#4B0082"
  },
  {
    "n": "purple",
    "c": "#800080"
  },
  {
    "n": "wine",
    "c": "#80013F"
  },
  {
    "n": "bordeaux",
    "c": "#7B002C"
  },
  {
    "n": "wine red",
    "c": "#7B0323"
  },
  {
    "n": "reddy brown",
    "c": "#6E1005"
  },
  {
    "n": "chestnut",
    "c": "#742802"
  },
  {
    "n": "browny green",
    "c": "#6F6C0A"
  },
  {
    "n": "brownish green",
    "c": "#6A6E09"
  },
  {
    "n": "army green",
    "c": "#4B5D16"
  },
  {
    "n": "camouflage green",
    "c": "#4B6113"
  },
  {
    "n": "dark aquamarine",
    "c": "#017371"
  },
  {
    "n": "deep turquoise",
    "c": "#017374"
  },
  {
    "n": "prussian blue",
    "c": "#004577"
  },
  {
    "n": "dark royal blue",
    "c": "#02066F"
  },
  {
    "n": "deep blue",
    "c": "#040273"
  },
  {
    "n": "grape purple",
    "c": "#5D1451"
  },
  {
    "n": "rich purple",
    "c": "#720058"
  },
  {
    "n": "merlot",
    "c": "#730039"
  },
  {
    "n": "blood",
    "c": "#770001"
  },
  {
    "n": "mud brown",
    "c": "#60460F"
  },
  {
    "n": "olive brown",
    "c": "#645403"
  },
  {
    "n": "greeny brown",
    "c": "#696006"
  },
  {
    "n": "mud green",
    "c": "#606602"
  },
  {
    "n": "spruce",
    "c": "#0A5F38"
  },
  {
    "n": "charcoal",
    "c": "#343837"
  },
  {
    "n": "dark aqua",
    "c": "#05696B"
  },
  {
    "n": "dark blue grey",
    "c": "#1F3B4D"
  },
  {
    "n": "marine blue",
    "c": "#01386A"
  },
  {
    "n": "darkblue",
    "c": "#030764"
  },
  {
    "n": "royal purple",
    "c": "#4B006E"
  },
  {
    "n": "claret",
    "c": "#680018"
  },
  {
    "n": "navy green",
    "c": "#35530A"
  },
  {
    "n": "forest",
    "c": "#0B5509"
  },
  {
    "n": "dark green",
    "c": "#006400"
  },
  {
    "n": "marine",
    "c": "#042E60"
  },
  {
    "n": "dark indigo",
    "c": "#1F0954"
  },
  {
    "n": "burgundy",
    "c": "#610023"
  },
  {
    "n": "green brown",
    "c": "#544E03"
  },
  {
    "n": "deep green",
    "c": "#02590F"
  },
  {
    "n": "pine green",
    "c": "#0A481E"
  },
  {
    "n": "dark blue green",
    "c": "#005249"
  },
  {
    "n": "deep teal",
    "c": "#00555A"
  },
  {
    "n": "plum purple",
    "c": "#4E0550"
  },
  {
    "n": "dried blood",
    "c": "#4B0101"
  },
  {
    "n": "mahogany",
    "c": "#4A0100"
  },
  {
    "n": "forrest green",
    "c": "#154406"
  },
  {
    "n": "hunter green",
    "c": "#0B4008"
  },
  {
    "n": "bottle green",
    "c": "#044A05"
  },
  {
    "n": "darkgreen",
    "c": "#054907"
  },
  {
    "n": "british racing green",
    "c": "#05480D"
  },
  {
    "n": "evergreen",
    "c": "#05472A"
  },
  {
    "n": "dark teal",
    "c": "#014D4E"
  },
  {
    "n": "dark",
    "c": "#1B2431"
  },
  {
    "n": "night blue",
    "c": "#040348"
  },
  {
    "n": "deep violet",
    "c": "#490648"
  },
  {
    "n": "eggplant purple",
    "c": "#430541"
  },
  {
    "n": "deep brown",
    "c": "#410200"
  },
  {
    "n": "chocolate brown",
    "c": "#411900"
  },
  {
    "n": "dark olive",
    "c": "#373E02"
  },
  {
    "n": "racing green",
    "c": "#014600"
  },
  {
    "n": "navy blue",
    "c": "#001146"
  },
  {
    "n": "dark purple",
    "c": "#35063E"
  },
  {
    "n": "deep purple",
    "c": "#36013F"
  },
  {
    "n": "aubergine",
    "c": "#3D0734"
  },
  {
    "n": "dark plum",
    "c": "#3F012C"
  },
  {
    "n": "dark brown",
    "c": "#341C02"
  },
  {
    "n": "dark navy",
    "c": "#000435"
  },
  {
    "n": "very dark blue",
    "c": "#000133"
  },
  {
    "n": "midnight purple",
    "c": "#280137"
  },
  {
    "n": "very dark purple",
    "c": "#2A0134"
  },
  {
    "n": "dark maroon",
    "c": "#3C0008"
  },
  {
    "n": "very dark green",
    "c": "#062E03"
  },
  {
    "n": "dark forest green",
    "c": "#002D04"
  },
  {
    "n": "dark navy blue",
    "c": "#00022E"
  },
  {
    "n": "midnight",
    "c": "#03012D"
  },
  {
    "n": "very dark brown",
    "c": "#1D0200"
  },
  {
    "n": "almost black",
    "c": "#070D0D"
  },
  {
    "n": "black",
    "c": "#000000"
  }
]
,

[{"n":"Pantone 100","c":"#F4ED7C"},
{"n":"Pantone 101",
"c":"#F4ED47"},
{"n":"Pantone 102",
"c":"#F9E814"},
{"n":"Pantone 103",
"c":"#C6AD0F"},
{"n":"Pantone 104",
"c":"#AD9B0C"},
{"n":"Pantone 105",
"c":"#82750F"}],
[{"n":"Pantone 106",
"c":"#F7E859"},
{"n":"Pantone 107",
"c":"#F9E526"},
{"n":"Pantone 108",
"c":"#F9DD16"},
{"n":"Pantone 109",
"c":"#F9D616"},
{"n":"Pantone 110",
"c":"#D8B511"},
{"n":"Pantone 111",
"c":"#AA930A"},
{"n":"Pantone 112",
"c":"#99840A"}],
[{"n":"Pantone 113",
"c":"#F9E55B"},
{"n":"Pantone 114",
"c":"#F9E24C"},
{"n":"Pantone 115",
"c":"#F9E04C"},
{"n":"Pantone 116",
"c":"#FCD116"},
{"n":"Pantone 117",
"c":"#C6A00C"},
{"n":"Pantone 118",
"c":"#AA8E0A"},
{"n":"Pantone 119",
"c":"#897719"}],
[{"n":"Pantone 120",
"c":"#F9E27F"},
{"n":"Pantone 121",
"c":"#F9E070"},
{"n":"Pantone 122",
"c":"#FCD856"},
{"n":"Pantone 123",
"c":"#FFC61E"},
{"n":"Pantone 124",
"c":"#E0AA0F"},
{"n":"Pantone 125",
"c":"#B58C0A"},
{"n":"Pantone 126",
"c":"#A38205"}],
[{"n":"Pantone 127",
"c":"#F4E287"},
{"n":"Pantone 128",
"c":"#F4DB60"},
{"n":"Pantone 129",
"c":"#F2D13D"},
{"n":"Pantone 130",
"c":"#EAAF0F"},
{"n":"Pantone 131",
"c":"#C6930A"},
{"n":"Pantone 132",
"c":"#9E7C0A"},
{"n":"Pantone 133",
"c":"#705B0A"}],
[{"n":"Pantone 134",
"c":"#FFD87F"},
{"n":"Pantone 135",
"c":"#FCC963"},
{"n":"Pantone 136",
"c":"#FCBF49"},
{"n":"Pantone 137",
"c":"#FCA311"},
{"n":"Pantone 138",
"c":"#D88C02"},
{"n":"Pantone 139",
"c":"#AF7505"},
{"n":"Pantone 140",
"c":"#7A5B11"}],
[{"n":"Pantone 141",
"c":"#F2CE68"},
{"n":"Pantone 142",
"c":"#F2BF49"},
{"n":"Pantone 143",
"c":"#EFB22D"},
{"n":"Pantone 144",
"c":"#E28C05"},
{"n":"Pantone 145",
"c":"#C67F07"},
{"n":"Pantone 146",
"c":"#9E6B05"},
{"n":"Pantone 147",
"c":"#725E26"}],
[{"n":"Pantone 148",
"c":"#FFD69B"},
{"n":"Pantone 149",
"c":"#FCCC93"},
{"n":"Pantone 150",
"c":"#FCAD56"},
{"n":"Pantone 151",
"c":"#F77F00"},
{"n":"Pantone 152",
"c":"#DD7500"},
{"n":"Pantone 153",
"c":"#BC6D0A"},
{"n":"Pantone 154",
"c":"#995905"}],
[{"n":"Pantone 155",
"c":"#F4DBAA"},
{"n":"Pantone 156",
"c":"#F2C68C"},
{"n":"Pantone 157",
"c":"#EDA04F"},
{"n":"Pantone 158",
"c":"#E87511"},
{"n":"Pantone 159",
"c":"#C66005"},
{"n":"Pantone 160",
"c":"#9E540A"},
{"n":"Pantone 161",
"c":"#633A11"}],
[{"n":"Pantone 162",
"c":"#F9C6AA"},
{"n":"Pantone 163",
"c":"#FC9E70"},
{"n":"Pantone 164",
"c":"#FC7F3F"},
{"n":"Pantone 165",
"c":"#F96302"},
{"n":"Pantone 166",
"c":"#DD5900"},
{"n":"Pantone 167",
"c":"#BC4F07"},
{"n":"Pantone 168",
"c":"#6D3011"}],
[{"n":"Pantone 169",
"c":"#F9BAAA"},
{"n":"Pantone 170",
"c":"#F98972"},
{"n":"Pantone 171",
"c":"#F9603A"},
{"n":"Pantone 172",
"c":"#F74902"},
{"n":"Pantone 173",
"c":"#D14414"},
{"n":"Pantone 174",
"c":"#933311"},
{"n":"Pantone 175",
"c":"#6D3321"}],
[{"n":"Pantone 176",
"c":"#F9AFAD"},
{"n":"Pantone 177",
"c":"#F9827F"},
{"n":"Pantone 178",
"c":"#F95E59"},
{"n":"Pantone 179",
"c":"#E23D28"},
{"n":"Pantone 180",
"c":"#C13828"},
{"n":"Pantone 181",
"c":"#7C2D23"}],
[{"n":"Pantone 182",
"c":"#F9BFC1"},
{"n":"Pantone 183",
"c":"#FC8C99"},
{"n":"Pantone 184",
"c":"#FC5E72"},
{"n":"Pantone 185",
"c":"#E8112D"},
{"n":"Pantone 186",
"c":"#CE1126"},
{"n":"Pantone 187",
"c":"#AF1E2D"},
{"n":"Pantone 188",
"c":"#7C2128"}],
[{"n":"Pantone 189",
"c":"#FFA3B2"},
{"n":"Pantone 190",
"c":"#FC758E"},
{"n":"Pantone 191",
"c":"#F4476B"},
{"n":"Pantone 192",
"c":"#E5053A"},
{"n":"Pantone 193",
"c":"#DB828C"},
{"n":"Pantone 194",
"c":"#992135"}],
[{"n":"Pantone 196",
"c":"#F4C9C9"},
{"n":"Pantone 197",
"c":"#EF99A3"},
{"n":"Pantone 198",
"c":"#772D35"}],
[{"n":"Pantone 199",
"c":"#D81C3F"},
{"n":"Pantone 200",
"c":"#C41E3A"},
{"n":"Pantone 201",
"c":"#A32638"},
{"n":"Pantone 202",
"c":"#8C2633"}],
[{"n":"Pantone 203",
"c":"#F2AFC1"},
{"n":"Pantone 204",
"c":"#ED7A9E"},
{"n":"Pantone 205",
"c":"#E54C7C"},
{"n":"Pantone 206",
"c":"#D30547"},
{"n":"Pantone 208",
"c":"#8E2344"},
{"n":"Pantone 209",
"c":"#75263D"}],
[{"n":"Pantone 210",
"c":"#FFA0BF"},
{"n":"Pantone 211",
"c":"#FF77A8"},
{"n":"Pantone 212",
"c":"#F94F8E"},
{"n":"Pantone 213",
"c":"#EA0F6B"},
{"n":"Pantone 214",
"c":"#CC0256"},
{"n":"Pantone 215",
"c":"#A50544"},
{"n":"Pantone 216",
"c":"#7C1E3F"}],
[{"n":"Pantone 217",
"c":"#F4BFD1"},
{"n":"Pantone 218",
"c":"#ED72AA"},
{"n":"Pantone 219",
"c":"#E22882"},
{"n":"Pantone 220",
"c":"#AA004F"},
{"n":"Pantone 221",
"c":"#930042"},
{"n":"Pantone 222",
"c":"#70193D"}],
[{"n":"Pantone 223",
"c":"#F993C4"},
{"n":"Pantone 224",
"c":"#F46BAF"},
{"n":"Pantone 225",
"c":"#ED2893"},
{"n":"Pantone 226",
"c":"#D60270"},
{"n":"Pantone 227",
"c":"#AD005B"},
{"n":"Pantone 228",
"c":"#8C004C"},
{"n":"Pantone 229",
"c":"#6D213F"}],
[{"n":"Pantone 230",
"c":"#FFA0CC"},
{"n":"Pantone 231",
"c":"#FC70BA"},
{"n":"Pantone 232",
"c":"#F43FA5"},
{"n":"Pantone 233",
"c":"#CE007C"},
{"n":"Pantone 234",
"c":"#AA0066"},
{"n":"Pantone 235",
"c":"#8E0554"}],
[{"n":"Pantone 236",
"c":"#F9AFD3"},
{"n":"Pantone 237",
"c":"#F484C4"},
{"n":"Pantone 238",
"c":"#ED4FAF"},
{"n":"Pantone 239",
"c":"#E0219E"},
{"n":"Pantone 240",
"c":"#C40F89"},
{"n":"Pantone 241",
"c":"#AD0075"},
{"n":"Pantone 242",
"c":"#7C1C51"}],
[{"n":"Pantone 243",
"c":"#F2BAD8"},
{"n":"Pantone 244",
"c":"#EDA0D3"},
{"n":"Pantone 245",
"c":"#E87FC9"},
{"n":"Pantone 246",
"c":"#CC00A0"},
{"n":"Pantone 247",
"c":"#B7008E"},
{"n":"Pantone 248",
"c":"#A3057F"},
{"n":"Pantone 249",
"c":"#7F2860"}],
[{"n":"Pantone 250",
"c":"#EDC4DD"},
{"n":"Pantone 251",
"c":"#E29ED6"},
{"n":"Pantone 252",
"c":"#D36BC6"},
{"n":"Pantone 253",
"c":"#AF23A5"},
{"n":"Pantone 254",
"c":"#A02D96"},
{"n":"Pantone 255",
"c":"#772D6B"}],
[{"n":"Pantone 256",
"c":"#E5C4D6"},
{"n":"Pantone 257",
"c":"#D3A5C9"},
{"n":"Pantone 258",
"c":"#9B4F96"},
{"n":"Pantone 259",
"c":"#72166B"},
{"n":"Pantone 260",
"c":"#681E5B"},
{"n":"Pantone 261",
"c":"#5E2154"},
{"n":"Pantone 262",
"c":"#542344"}],
[{"n":"Pantone 263",
"c":"#E0CEE0"},
{"n":"Pantone 264",
"c":"#C6AADB"},
{"n":"Pantone 265",
"c":"#9663C4"},
{"n":"Pantone 266",
"c":"#6D28AA"},
{"n":"Pantone 267",
"c":"#59118E"},
{"n":"Pantone 268",
"c":"#4F2170"},
{"n":"Pantone 269",
"c":"#442359"}],
[{"n":"Pantone 270",
"c":"#BAAFD3"},
{"n":"Pantone 271",
"c":"#9E91C6"},
{"n":"Pantone 272",
"c":"#8977BA"},
{"n":"Pantone 273",
"c":"#38197A"},
{"n":"Pantone 274",
"c":"#2B1166"},
{"n":"Pantone 275",
"c":"#260F54"},
{"n":"Pantone 276",
"c":"#2B2147"}],
[{"n":"Pantone 277",
"c":"#B5D1E8"},
{"n":"Pantone 278",
"c":"#99BADD"},
{"n":"Pantone 279",
"c":"#6689CC"},
{"n":"Pantone 280",
"c":"#002B7F"},
{"n":"Pantone 281",
"c":"#002868"},
{"n":"Pantone 282",
"c":"#002654"}],
[{"n":"Pantone 283",
"c":"#9BC4E2"},
{"n":"Pantone 284",
"c":"#75AADB"},
{"n":"Pantone 285",
"c":"#3A75C4"},
{"n":"Pantone 286",
"c":"#0038A8"},
{"n":"Pantone 287",
"c":"#003893"},
{"n":"Pantone 288",
"c":"#00337F"},
{"n":"Pantone 289",
"c":"#002649"}],
[{"n":"Pantone 290",
"c":"#C4D8E2"},
{"n":"Pantone 291",
"c":"#A8CEE2"},
{"n":"Pantone 292",
"c":"#75B2DD"},
{"n":"Pantone 293",
"c":"#0051BA"},
{"n":"Pantone 294",
"c":"#003F87"},
{"n":"Pantone 295",
"c":"#00386B"},
{"n":"Pantone 296",
"c":"#002D47"}],
[{"n":"Pantone 297",
"c":"#82C6E2"},
{"n":"Pantone 298",
"c":"#51B5E0"},
{"n":"Pantone 299",
"c":"#00A3DD"},
{"n":"Pantone 300",
"c":"#0072C6"},
{"n":"Pantone 301",
"c":"#005B99"},
{"n":"Pantone 302",
"c":"#004F6D"},
{"n":"Pantone 303",
"c":"#003F54"}],
[{"n":"Pantone 304",
"c":"#A5DDE2"},
{"n":"Pantone 305",
"c":"#70CEE2"},
{"n":"Pantone 306",
"c":"#00BCE2"},
{"n":"Pantone 307",
"c":"#007AA5"},
{"n":"Pantone 308",
"c":"#00607C"},
{"n":"Pantone 309",
"c":"#003F49"}],
[{"n":"Pantone 310",
"c":"#72D1DD"},
{"n":"Pantone 311",
"c":"#28C4D8"},
{"n":"Pantone 312",
"c":"#00ADC6"},
{"n":"Pantone 313",
"c":"#0099B5"},
{"n":"Pantone 314",
"c":"#00829B"},
{"n":"Pantone 315",
"c":"#006B77"},
{"n":"Pantone 316",
"c":"#00494F"}],
[{"n":"Pantone 317",
"c":"#C9E8DD"},
{"n":"Pantone 318",
"c":"#93DDDB"},
{"n":"Pantone 319",
"c":"#4CCED1"},
{"n":"Pantone 320",
"c":"#009EA0"},
{"n":"Pantone 321",
"c":"#008789"},
{"n":"Pantone 322",
"c":"#007272"},
{"n":"Pantone 323",
"c":"#006663"}],
[{"n":"Pantone 324",
"c":"#AADDD6"},
{"n":"Pantone 325",
"c":"#56C9C1"},
{"n":"Pantone 326",
"c":"#00B2AA"},
{"n":"Pantone 327",
"c":"#008C82"},
{"n":"Pantone 328",
"c":"#007770"},
{"n":"Pantone 329",
"c":"#006D66"},
{"n":"Pantone 330",
"c":"#005951"}],
[{"n":"Pantone 331",
"c":"#BAEAD6"},
{"n":"Pantone 332",
"c":"#A0E5CE"},
{"n":"Pantone 333",
"c":"#5EDDC1"},
{"n":"Pantone 334",
"c":"#00997C"},
{"n":"Pantone 335",
"c":"#007C66"},
{"n":"Pantone 336",
"c":"#006854"}],
[{"n":"Pantone 337",
"c":"#9BDBC1"},
{"n":"Pantone 338",
"c":"#7AD1B5"},
{"n":"Pantone 339",
"c":"#00B28C"},
{"n":"Pantone 340",
"c":"#009977"},
{"n":"Pantone 341",
"c":"#007A5E"},
{"n":"Pantone 342",
"c":"#006B54"},
{"n":"Pantone 343",
"c":"#00563F"}],
[{"n":"Pantone 344",
"c":"#B5E2BF"},
{"n":"Pantone 345",
"c":"#96D8AF"},
{"n":"Pantone 346",
"c":"#70CE9B"},
{"n":"Pantone 347",
"c":"#009E60"},
{"n":"Pantone 348",
"c":"#008751"},
{"n":"Pantone 349",
"c":"#006B3F"},
{"n":"Pantone 350",
"c":"#234F33"}],
[{"n":"Pantone 351",
"c":"#B5E8BF"},
{"n":"Pantone 352",
"c":"#99E5B2"},
{"n":"Pantone 353",
"c":"#84E2A8"},
{"n":"Pantone 354",
"c":"#00B760"},
{"n":"Pantone 355",
"c":"#009E49"},
{"n":"Pantone 356",
"c":"#007A3D"},
{"n":"Pantone 357",
"c":"#215B33"}],
[{"n":"Pantone 358",
"c":"#AADD96"},
{"n":"Pantone 359",
"c":"#A0DB8E"},
{"n":"Pantone 360",
"c":"#60C659"},
{"n":"Pantone 361",
"c":"#1EB53A"},
{"n":"Pantone 362",
"c":"#339E35"},
{"n":"Pantone 363",
"c":"#3D8E33"},
{"n":"Pantone 364",
"c":"#3A7728"}],
[{"n":"Pantone 365",
"c":"#D3E8A3"},
{"n":"Pantone 366",
"c":"#C4E58E"},
{"n":"Pantone 367",
"c":"#AADD6D"},
{"n":"Pantone 368",
"c":"#5BBF21"},
{"n":"Pantone 369",
"c":"#56AA1C"},
{"n":"Pantone 370",
"c":"#568E14"},
{"n":"Pantone 371",
"c":"#566B21"}],
[{"n":"Pantone 372",
"c":"#D8ED96"},
{"n":"Pantone 373",
"c":"#CEEA82"},
{"n":"Pantone 374",
"c":"#BAE860"},
{"n":"Pantone 375",
"c":"#8CD600"},
{"n":"Pantone 376",
"c":"#7FBA00"},
{"n":"Pantone 377",
"c":"#709302"},
{"n":"Pantone 378",
"c":"#566314"}],
[{"n":"Pantone 379",
"c":"#E0EA68"},
{"n":"Pantone 380",
"c":"#D6E542"},
{"n":"Pantone 381",
"c":"#CCE226"},
{"n":"Pantone 382",
"c":"#BAD80A"},
{"n":"Pantone 383",
"c":"#A3AF07"},
{"n":"Pantone 384",
"c":"#939905"},
{"n":"Pantone 385",
"c":"#707014"}],
[{"n":"Pantone 386",
"c":"#E8ED60"},
{"n":"Pantone 387",
"c":"#E0ED44"},
{"n":"Pantone 388",
"c":"#D6E80F"},
{"n":"Pantone 389",
"c":"#CEE007"},
{"n":"Pantone 390",
"c":"#BAC405"},
{"n":"Pantone 391",
"c":"#9E9E07"},
{"n":"Pantone 392",
"c":"#848205"}],
[{"n":"Pantone 393",
"c":"#F2EF87"},
{"n":"Pantone 394",
"c":"#EAED35"},
{"n":"Pantone 395",
"c":"#E5E811"},
{"n":"Pantone 396",
"c":"#E0E20C"},
{"n":"Pantone 397",
"c":"#C1BF0A"},
{"n":"Pantone 398",
"c":"#AFA80A"},
{"n":"Pantone 399",
"c":"#998E07"}],
[{"n":"Pantone 400",
"c":"#D1C6B5"},
{"n":"Pantone 401",
"c":"#C1B5A5"},
{"n":"Pantone 402",
"c":"#AFA593"},
{"n":"Pantone 403",
"c":"#998C7C"},
{"n":"Pantone 404",
"c":"#827566"},
{"n":"Pantone 405",
"c":"#6B5E4F"}],
[{"n":"Pantone 406",
"c":"#CEC1B5"},
{"n":"Pantone 408",
"c":"#A8998C"},
{"n":"Pantone 409",
"c":"#99897C"},
{"n":"Pantone 410",
"c":"#7C6D63"},
{"n":"Pantone 411",
"c":"#66594C"},
{"n":"Pantone 412",
"c":"#3D3028"}],
[{"n":"Pantone 413",
"c":"#C6C1B2"},
{"n":"Pantone 414",
"c":"#B5AFA0"},
{"n":"Pantone 415",
"c":"#A39E8C"},
{"n":"Pantone 416",
"c":"#8E8C7A"},
{"n":"Pantone 417",
"c":"#777263"},
{"n":"Pantone 418",
"c":"#605E4F"},
{"n":"Pantone 419",
"c":"#282821"}],
[{"n":"Pantone 420",
"c":"#D1CCBF"},
{"n":"Pantone 421",
"c":"#BFBAAF"},
{"n":"Pantone 422",
"c":"#AFAAA3"},
{"n":"Pantone 423",
"c":"#96938E"},
{"n":"Pantone 424",
"c":"#827F77"},
{"n":"Pantone 425",
"c":"#60605B"},
{"n":"Pantone 426",
"c":"#2B2B28"}],
[{"n":"Pantone 427",
"c":"#DDDBD1"},
{"n":"Pantone 428",
"c":"#D1CEC6"},
{"n":"Pantone 429",
"c":"#ADAFAA"},
{"n":"Pantone 430",
"c":"#919693"},
{"n":"Pantone 431",
"c":"#666D70"},
{"n":"Pantone 432",
"c":"#444F51"},
{"n":"Pantone 433",
"c":"#30383A"}],
[{"n":"Pantone 434",
"c":"#E0D1C6"},
{"n":"Pantone 435",
"c":"#D3BFB7"},
{"n":"Pantone 436",
"c":"#BCA59E"},
{"n":"Pantone 437",
"c":"#8C706B"},
{"n":"Pantone 438",
"c":"#593F3D"},
{"n":"Pantone 439",
"c":"#493533"},
{"n":"Pantone 440",
"c":"#3F302B"}],
[{"n":"Pantone 441",
"c":"#D1D1C6"},
{"n":"Pantone 442",
"c":"#BABFB7"},
{"n":"Pantone 443",
"c":"#A3A8A3"},
{"n":"Pantone 444",
"c":"#898E8C"},
{"n":"Pantone 445",
"c":"#565959"},
{"n":"Pantone 446",
"c":"#494C49"},
{"n":"Pantone 447",
"c":"#3F3F38"}],
[{"n":"Pantone 448",
"c":"#54472D"},
{"n":"Pantone 449",
"c":"#544726"},
{"n":"Pantone 450",
"c":"#60542B"},
{"n":"Pantone 451",
"c":"#ADA07A"},
{"n":"Pantone 452",
"c":"#C4B796"},
{"n":"Pantone 453",
"c":"#D6CCAF"},
{"n":"Pantone 454",
"c":"#E2D8BF"}],
[{"n":"Pantone 455",
"c":"#665614"},
{"n":"Pantone 456",
"c":"#998714"},
{"n":"Pantone 457",
"c":"#B59B0C"},
{"n":"Pantone 458",
"c":"#DDCC6B"},
{"n":"Pantone 459",
"c":"#E2D67C"},
{"n":"Pantone 460",
"c":"#EADD96"},
{"n":"Pantone 461",
"c":"#EDE5AD"}],
[{"n":"Pantone 462",
"c":"#5B4723"},
{"n":"Pantone 463",
"c":"#755426"},
{"n":"Pantone 464",
"c":"#876028"},
{"n":"Pantone 465",
"c":"#C1A875"},
{"n":"Pantone 466",
"c":"#D1BF91"},
{"n":"Pantone 467",
"c":"#DDCCA5"},
{"n":"Pantone 468",
"c":"#E2D6B5"}],
[{"n":"Pantone 469",
"c":"#603311"},
{"n":"Pantone 470",
"c":"#9B4F19"},
{"n":"Pantone 471",
"c":"#BC5E1E"},
{"n":"Pantone 472",
"c":"#EAAA7A"},
{"n":"Pantone 473",
"c":"#F4C4A0"},
{"n":"Pantone 474",
"c":"#F4CCAA"},
{"n":"Pantone 475",
"c":"#F7D3B5"}],
[{"n":"Pantone 476",
"c":"#593D2B"},
{"n":"Pantone 477",
"c":"#633826"},
{"n":"Pantone 478",
"c":"#7A3F28"},
{"n":"Pantone 479",
"c":"#AF8970"},
{"n":"Pantone 480",
"c":"#D3B7A3"},
{"n":"Pantone 481",
"c":"#E0CCBA"},
{"n":"Pantone 482",
"c":"#E5D3C1"}],
[{"n":"Pantone 483",
"c":"#6B3021"},
{"n":"Pantone 484",
"c":"#9B301C"},
{"n":"Pantone 485",
"c":"#D81E05"},
{"n":"Pantone 486",
"c":"#ED9E84"},
{"n":"Pantone 487",
"c":"#EFB5A0"},
{"n":"Pantone 488",
"c":"#F2C4AF"},
{"n":"Pantone 489",
"c":"#F2D1BF"}],
[{"n":"Pantone 490",
"c":"#5B2626"},
{"n":"Pantone 491",
"c":"#752828"},
{"n":"Pantone 492",
"c":"#913338"},
{"n":"Pantone 494",
"c":"#F2ADB2"},
{"n":"Pantone 495",
"c":"#F4BCBF"},
{"n":"Pantone 496",
"c":"#F7C9C6"}],
[{"n":"Pantone 497",
"c":"#512826"},
{"n":"Pantone 498",
"c":"#6D332B"},
{"n":"Pantone 499",
"c":"#7A382D"},
{"n":"Pantone 500",
"c":"#CE898C"},
{"n":"Pantone 501",
"c":"#EAB2B2"},
{"n":"Pantone 502",
"c":"#F2C6C4"},
{"n":"Pantone 503",
"c":"#F4D1CC"}],
[{"n":"Pantone 504",
"c":"#511E26"},
{"n":"Pantone 505",
"c":"#661E2B"},
{"n":"Pantone 506",
"c":"#7A2638"},
{"n":"Pantone 507",
"c":"#D8899B"},
{"n":"Pantone 508",
"c":"#E8A5AF"},
{"n":"Pantone 509",
"c":"#F2BABF"},
{"n":"Pantone 510",
"c":"#F4C6C9"}],
[{"n":"Pantone 511",
"c":"#602144"},
{"n":"Pantone 512",
"c":"#84216B"},
{"n":"Pantone 513",
"c":"#9E2387"},
{"n":"Pantone 514",
"c":"#D884BC"},
{"n":"Pantone 515",
"c":"#E8A3C9"},
{"n":"Pantone 516",
"c":"#F2BAD3"},
{"n":"Pantone 517",
"c":"#F4CCD8"}],
[{"n":"Pantone 518",
"c":"#512D44"},
{"n":"Pantone 519",
"c":"#63305E"},
{"n":"Pantone 520",
"c":"#703572"},
{"n":"Pantone 521",
"c":"#B58CB2"},
{"n":"Pantone 522",
"c":"#C6A3C1"},
{"n":"Pantone 523",
"c":"#D3B7CC"},
{"n":"Pantone 524",
"c":"#E2CCD3"}],
[{"n":"Pantone 525",
"c":"#512654"},
{"n":"Pantone 526",
"c":"#68217A"},
{"n":"Pantone 527",
"c":"#7A1E99"},
{"n":"Pantone 528",
"c":"#AF72C1"},
{"n":"Pantone 529",
"c":"#CEA3D3"},
{"n":"Pantone 530",
"c":"#D6AFD6"},
{"n":"Pantone 531",
"c":"#E5C6DB"}],
[{"n":"Pantone 532",
"c":"#353842"},
{"n":"Pantone 533",
"c":"#353F5B"},
{"n":"Pantone 534",
"c":"#3A4972"},
{"n":"Pantone 535",
"c":"#9BA3B7"},
{"n":"Pantone 536",
"c":"#ADB2C1"},
{"n":"Pantone 537",
"c":"#C4C6CE"},
{"n":"Pantone 538",
"c":"#D6D3D6"}],
[{"n":"Pantone 539",
"c":"#003049"},
{"n":"Pantone 540",
"c":"#00335B"},
{"n":"Pantone 541",
"c":"#003F77"},
{"n":"Pantone 542",
"c":"#6693BC"},
{"n":"Pantone 543",
"c":"#93B7D1"},
{"n":"Pantone 544",
"c":"#B7CCDB"},
{"n":"Pantone 545",
"c":"#C4D3DD"}],
[{"n":"Pantone 546",
"c":"#0C3844"},
{"n":"Pantone 547",
"c":"#003F54"},
{"n":"Pantone 548",
"c":"#004459"},
{"n":"Pantone 549",
"c":"#5E99AA"},
{"n":"Pantone 550",
"c":"#87AFBF"},
{"n":"Pantone 551",
"c":"#A3C1C9"},
{"n":"Pantone 552",
"c":"#C4D6D6"}],
[{"n":"Pantone 553",
"c":"#234435"},
{"n":"Pantone 554",
"c":"#195E47"},
{"n":"Pantone 555",
"c":"#076D54"},
{"n":"Pantone 556",
"c":"#7AA891"},
{"n":"Pantone 557",
"c":"#A3C1AD"},
{"n":"Pantone 558",
"c":"#B7CEBC"},
{"n":"Pantone 559",
"c":"#C6D6C4"}],
[{"n":"Pantone 560",
"c":"#2B4C3F"},
{"n":"Pantone 561",
"c":"#266659"},
{"n":"Pantone 562",
"c":"#1E7A6D"},
{"n":"Pantone 563",
"c":"#7FBCAA"},
{"n":"Pantone 564",
"c":"#05705E"},
{"n":"Pantone 565",
"c":"#BCDBCC"},
{"n":"Pantone 566",
"c":"#D1E2D3"}],
[{"n":"Pantone 567",
"c":"#265142"},
{"n":"Pantone 569",
"c":"#008772"},
{"n":"Pantone 570",
"c":"#7FC6B2"},
{"n":"Pantone 571",
"c":"#AADBC6"},
{"n":"Pantone 572",
"c":"#BCE2CE"},
{"n":"Pantone 573",
"c":"#CCE5D6"}],
[{"n":"Pantone 574",
"c":"#495928"},
{"n":"Pantone 575",
"c":"#547730"},
{"n":"Pantone 576",
"c":"#608E3A"},
{"n":"Pantone 577",
"c":"#B5CC8E"},
{"n":"Pantone 578",
"c":"#C6D6A0"},
{"n":"Pantone 579",
"c":"#C9D6A3"},
{"n":"Pantone 580",
"c":"#D8DDB5"}],
[{"n":"Pantone 581",
"c":"#605E11"},
{"n":"Pantone 582",
"c":"#878905"},
{"n":"Pantone 583",
"c":"#AABA0A"},
{"n":"Pantone 584",
"c":"#CED649"},
{"n":"Pantone 585",
"c":"#DBE06B"},
{"n":"Pantone 586",
"c":"#E2E584"},
{"n":"Pantone 587",
"c":"#E8E89B"}],
[{"n":"Pantone 600",
"c":"#F4EDAF"},
{"n":"Pantone 601",
"c":"#F2ED9E"},
{"n":"Pantone 602",
"c":"#F2EA87"},
{"n":"Pantone 603",
"c":"#EDE85B"},
{"n":"Pantone 604",
"c":"#E8DD21"},
{"n":"Pantone 605",
"c":"#DDCE11"},
{"n":"Pantone 606",
"c":"#D3BF11"}],
[{"n":"Pantone 607",
"c":"#F2EABC"},
{"n":"Pantone 608",
"c":"#EFE8AD"},
{"n":"Pantone 609",
"c":"#EAE596"},
{"n":"Pantone 610",
"c":"#E2DB72"},
{"n":"Pantone 611",
"c":"#D6CE49"},
{"n":"Pantone 612",
"c":"#C4BA00"},
{"n":"Pantone 613",
"c":"#AFA00C"}],
[{"n":"Pantone 614",
"c":"#EAE2B7"},
{"n":"Pantone 615",
"c":"#E2DBAA"},
{"n":"Pantone 616",
"c":"#DDD69B"},
{"n":"Pantone 617",
"c":"#CCC47C"},
{"n":"Pantone 618",
"c":"#B5AA59"},
{"n":"Pantone 619",
"c":"#968C28"},
{"n":"Pantone 620",
"c":"#847711"}],
[{"n":"Pantone 621",
"c":"#D8DDCE"},
{"n":"Pantone 622",
"c":"#C1D1BF"},
{"n":"Pantone 623",
"c":"#A5BFAA"},
{"n":"Pantone 624",
"c":"#7FA08C"},
{"n":"Pantone 625",
"c":"#5B8772"},
{"n":"Pantone 626",
"c":"#21543F"},
{"n":"Pantone 627",
"c":"#0C3026"}],
[{"n":"Pantone 628",
"c":"#CCE2DD"},
{"n":"Pantone 629",
"c":"#B2D8D8"},
{"n":"Pantone 630",
"c":"#8CCCD3"},
{"n":"Pantone 631",
"c":"#54B7C6"},
{"n":"Pantone 632",
"c":"#00A0BA"},
{"n":"Pantone 633",
"c":"#007F99"},
{"n":"Pantone 634",
"c":"#00667F"}],
[{"n":"Pantone 635",
"c":"#BAE0E0"},
{"n":"Pantone 636",
"c":"#99D6DD"},
{"n":"Pantone 637",
"c":"#6BC9DB"},
{"n":"Pantone 638",
"c":"#00B5D6"},
{"n":"Pantone 639",
"c":"#00A0C4"},
{"n":"Pantone 640",
"c":"#008CB2"},
{"n":"Pantone 641",
"c":"#007AA5"}],
[{"n":"Pantone 642",
"c":"#D1D8D8"},
{"n":"Pantone 643",
"c":"#C6D1D6"},
{"n":"Pantone 644",
"c":"#9BAFC4"},
{"n":"Pantone 645",
"c":"#7796B2"},
{"n":"Pantone 646",
"c":"#5E82A3"},
{"n":"Pantone 647",
"c":"#26547C"},
{"n":"Pantone 648",
"c":"#00305E"}],
[{"n":"Pantone 649",
"c":"#D6D6D8"},
{"n":"Pantone 650",
"c":"#BFC6D1"},
{"n":"Pantone 651",
"c":"#9BAABF"},
{"n":"Pantone 652",
"c":"#6D87A8"},
{"n":"Pantone 653",
"c":"#335687"},
{"n":"Pantone 654",
"c":"#0F2B5B"},
{"n":"Pantone 655",
"c":"#0C1C47"}],
[{"n":"Pantone 656",
"c":"#D6DBE0"},
{"n":"Pantone 657",
"c":"#C1C9DD"},
{"n":"Pantone 658",
"c":"#A5AFD6"},
{"n":"Pantone 659",
"c":"#7F8CBF"},
{"n":"Pantone 660",
"c":"#5960A8"},
{"n":"Pantone 661",
"c":"#2D338E"},
{"n":"Pantone 662",
"c":"#0C1975"}],
[{"n":"Pantone 663",
"c":"#E2D3D6"},
{"n":"Pantone 664",
"c":"#D8CCD1"},
{"n":"Pantone 665",
"c":"#C6B5C4"},
{"n":"Pantone 666",
"c":"#A893AD"},
{"n":"Pantone 667",
"c":"#7F6689"},
{"n":"Pantone 668",
"c":"#664975"},
{"n":"Pantone 669",
"c":"#472B59"}],
[{"n":"Pantone 670",
"c":"#F2D6D8"},
{"n":"Pantone 671",
"c":"#EFC6D3"},
{"n":"Pantone 672",
"c":"#EAAAC4"},
{"n":"Pantone 673",
"c":"#E08CB2"},
{"n":"Pantone 674",
"c":"#D36B9E"},
{"n":"Pantone 675",
"c":"#BC3877"},
{"n":"Pantone 676",
"c":"#A00054"}],
[{"n":"Pantone 677",
"c":"#EDD6D6"},
{"n":"Pantone 678",
"c":"#EACCCE"},
{"n":"Pantone 679",
"c":"#E5BFC6"},
{"n":"Pantone 680",
"c":"#D39EAF"},
{"n":"Pantone 681",
"c":"#B7728E"},
{"n":"Pantone 682",
"c":"#A05175"},
{"n":"Pantone 683",
"c":"#7F284F"}],
[{"n":"Pantone 684",
"c":"#EFCCCE"},
{"n":"Pantone 685",
"c":"#EABFC4"},
{"n":"Pantone 686",
"c":"#E0AABA"},
{"n":"Pantone 687",
"c":"#C9899E"},
{"n":"Pantone 688",
"c":"#B26684"},
{"n":"Pantone 689",
"c":"#934266"},
{"n":"Pantone 690",
"c":"#702342"}],
[{"n":"Pantone 691",
"c":"#EFD1C9"},
{"n":"Pantone 692",
"c":"#E8BFBA"},
{"n":"Pantone 693",
"c":"#DBA8A5"},
{"n":"Pantone 694",
"c":"#C98C8C"},
{"n":"Pantone 695",
"c":"#B26B70"},
{"n":"Pantone 696",
"c":"#8E4749"},
{"n":"Pantone 697",
"c":"#7F383A"}],
[{"n":"Pantone 698",
"c":"#F7D1CC"},
{"n":"Pantone 699",
"c":"#F7BFBF"},
{"n":"Pantone 700",
"c":"#F2A5AA"},
{"n":"Pantone 701",
"c":"#E8878E"},
{"n":"Pantone 702",
"c":"#D6606D"},
{"n":"Pantone 703",
"c":"#B73844"},
{"n":"Pantone 704",
"c":"#9E2828"}],
[{"n":"Pantone 705",
"c":"#F9DDD6"},
{"n":"Pantone 706",
"c":"#FCC9C6"},
{"n":"Pantone 707",
"c":"#FCADAF"},
{"n":"Pantone 708",
"c":"#F98E99"},
{"n":"Pantone 709",
"c":"#F26877"},
{"n":"Pantone 710",
"c":"#E04251"},
{"n":"Pantone 711",
"c":"#D12D33"}],
[{"n":"Pantone 712",
"c":"#FFD3AA"},
{"n":"Pantone 713",
"c":"#F9C9A3"},
{"n":"Pantone 714",
"c":"#F9BA82"},
{"n":"Pantone 715",
"c":"#FC9E49"},
{"n":"Pantone 716",
"c":"#F28411"},
{"n":"Pantone 717",
"c":"#D36D00"},
{"n":"Pantone 718",
"c":"#BF5B00"}],
[{"n":"Pantone 719",
"c":"#F4D1AF"},
{"n":"Pantone 720",
"c":"#EFC49E"},
{"n":"Pantone 721",
"c":"#E8B282"},
{"n":"Pantone 722",
"c":"#D18E54"},
{"n":"Pantone 723",
"c":"#BA7530"},
{"n":"Pantone 724",
"c":"#8E4905"},
{"n":"Pantone 725",
"c":"#753802"}],
[{"n":"Pantone 726",
"c":"#EDD3B5"},
{"n":"Pantone 727",
"c":"#E2BF9B"},
{"n":"Pantone 728",
"c":"#D3A87C"},
{"n":"Pantone 729",
"c":"#C18E60"},
{"n":"Pantone 730",
"c":"#AA753F"},
{"n":"Pantone 731",
"c":"#723F0A"},
{"n":"Pantone 732",
"c":"#60330A"}],
[{"n":"Pantone 801",
"c":"#00AACC"},
{"n":"Pantone 802",
"c":"#60DD49"},
{"n":"Pantone 803",
"c":"#FFED38"},
{"n":"Pantone 804",
"c":"#FF9338"},
{"n":"Pantone 805",
"c":"#F95951"},
{"n":"Pantone 806",
"c":"#FF0093"},
{"n":"Pantone 807",
"c":"#D6009E"}],
[{"n":"Pantone 808",
"c":"#00B59B"},
{"n":"Pantone 809",
"c":"#DDE00F"},
{"n":"Pantone 810",
"c":"#FFCC1E"},
{"n":"Pantone 811",
"c":"#FF7247"},
{"n":"Pantone 812",
"c":"#FC2366"},
{"n":"Pantone 813",
"c":"#E50099"},
{"n":"Pantone 814",
"c":"#8C60C1"}],
[{"n":"Pantone 1205",
"c":"#F7E8AA"},
{"n":"Pantone 1215",
"c":"#F9E08C"},
{"n":"Pantone 1225",
"c":"#FFCC49"},
{"n":"Pantone 1235",
"c":"#FCB514"},
{"n":"Pantone 1245",
"c":"#BF910C"},
{"n":"Pantone 1255",
"c":"#A37F14"},
{"n":"Pantone 1265",
"c":"#7C6316"}],
[{"n":"Pantone 1345",
"c":"#FFD691"},
{"n":"Pantone 1355",
"c":"#FCCE87"},
{"n":"Pantone 1365",
"c":"#FCBA5E"},
{"n":"Pantone 1375",
"c":"#F99B0C"},
{"n":"Pantone 1385",
"c":"#CC7A02"},
{"n":"Pantone 1395",
"c":"#996007"},
{"n":"Pantone 1405",
"c":"#6B4714"}],
[{"n":"Pantone NA",
"c":"#FFFFFF"},
{"n":"Pantone 1485",
"c":"#FFB777"},
{"n":"Pantone 1495",
"c":"#FF993F"},
{"n":"Pantone 1505",
"c":"#F47C00"},
{"n":"Pantone 1525",
"c":"#B55400"},
{"n":"Pantone 1535",
"c":"#8C4400"},
{"n":"Pantone 1545",
"c":"#4C280F"}],
[{"n":"Pantone 1555",
"c":"#F9BF9E"},
{"n":"Pantone 1565",
"c":"#FCA577"},
{"n":"Pantone 1575",
"c":"#FC8744"},
{"n":"Pantone 1585",
"c":"#F96B07"},
{"n":"Pantone 1595",
"c":"#D15B05"},
{"n":"Pantone 1605",
"c":"#A04F11"},
{"n":"Pantone 1615",
"c":"#843F0F"}],
[{"n":"Pantone 1625",
"c":"#F9A58C"},
{"n":"Pantone 1635",
"c":"#F98E6D"},
{"n":"Pantone 1645",
"c":"#F97242"},
{"n":"Pantone 1655",
"c":"#F95602"},
{"n":"Pantone 1665",
"c":"#DD4F05"},
{"n":"Pantone 1675",
"c":"#A53F0F"},
{"n":"Pantone 1685",
"c":"#843511"}],
[{"n":"Pantone 1765",
"c":"#F99EA3"},
{"n":"Pantone 1767",
"c":"#F9B2B7"},
{"n":"Pantone 1775",
"c":"#F9848E"},
{"n":"Pantone 1777",
"c":"#FC6675"},
{"n":"Pantone 1785",
"c":"#FC4F59"},
{"n":"Pantone 1787",
"c":"#F43F4F"},
{"n":"Pantone 1788",
"c":"#EF2B2D"}],
[{"n":"Pantone 1795",
"c":"#D62828"},
{"n":"Pantone 1797",
"c":"#CC2D30"},
{"n":"Pantone 1805",
"c":"#AF2626"},
{"n":"Pantone 1807",
"c":"#A03033"},
{"n":"Pantone 1810",
"c":"#7C211E"},
{"n":"Pantone 1817",
"c":"#5B2D28"}],
[{"n":"Pantone 1895",
"c":"#FCBFC9"},
{"n":"Pantone 1905",
"c":"#FC9BB2"},
{"n":"Pantone 1915",
"c":"#F4547C"},
{"n":"Pantone 1925",
"c":"#E00747"},
{"n":"Pantone 1935",
"c":"#C10538"},
{"n":"Pantone 1945",
"c":"#A80C35"},
{"n":"Pantone 1955",
"c":"#931638"}],
[{"n":"Pantone 2365",
"c":"#F7C4D8"},
{"n":"Pantone 2375",
"c":"#EA6BBF"},
{"n":"Pantone 2385",
"c":"#DB28A5"},
{"n":"Pantone 2395",
"c":"#C4008C"},
{"n":"Pantone 2405",
"c":"#A8007A"},
{"n":"Pantone 2415",
"c":"#9B0070"},
{"n":"Pantone 2425",
"c":"#87005B"}],
[{"n":"Pantone 2562",
"c":"#D8A8D8"},
{"n":"Pantone 2563",
"c":"#D1A0CC"},
{"n":"Pantone 2567",
"c":"#BF93CC"},
{"n":"Pantone 2572",
"c":"#C687D1"},
{"n":"Pantone 2573",
"c":"#BA7CBC"},
{"n":"Pantone 2577",
"c":"#AA72BF"}],
[{"n":"Pantone 2582",
"c":"#AA47BA"},
{"n":"Pantone 2583",
"c":"#9E4FA5"},
{"n":"Pantone 2587",
"c":"#8E47AD"},
{"n":"Pantone 2592",
"c":"#930FA5"},
{"n":"Pantone 2593",
"c":"#872B93"},
{"n":"Pantone 2597",
"c":"#66008C"},
{"n":"Pantone",
"c":"#820C8E"}],
[{"n":"Pantone 2603",
"c":"#70147A"},
{"n":"Pantone 2607",
"c":"#5B027A"},
{"n":"Pantone 2612",
"c":"#701E72"},
{"n":"Pantone 2613",
"c":"#66116D"},
{"n":"Pantone 2617",
"c":"#560C70"},
{"n":"Pantone 2622",
"c":"#602D59"},
{"n":"Pantone 2623",
"c":"#5B195E"}],
[{"n":"Pantone 2627",
"c":"#4C145E"},
{"n":"Pantone 2635",
"c":"#C9ADD8"},
{"n":"Pantone 2645",
"c":"#B591D1"},
{"n":"Pantone 2655",
"c":"#9B6DC6"},
{"n":"Pantone 2665",
"c":"#894FBF"},
{"n":"Pantone 2685",
"c":"#56008C"},
{"n":"Pantone 2695",
"c":"#44235E"}],
[{"n":"Pantone 2705",
"c":"#AD9ED3"},
{"n":"Pantone 2706",
"c":"#D1CEDD"},
{"n":"Pantone 2707",
"c":"#BFD1E5"},
{"n":"Pantone 2708",
"c":"#AFBCDB"},
{"n":"Pantone 2715",
"c":"#937ACC"},
{"n":"Pantone 2716",
"c":"#A5A0D6"},
{"n":"Pantone 2717",
"c":"#A5BAE0"}],
[{"n":"Pantone 2718",
"c":"#5B77CC"},
{"n":"Pantone 2725",
"c":"#7251BC"},
{"n":"Pantone 2726",
"c":"#6656BC"},
{"n":"Pantone 2727",
"c":"#5E68C4"},
{"n":"Pantone 2728",
"c":"#3044B5"},
{"n":"Pantone 2735",
"c":"#4F0093"},
{"n":"Pantone 2736",
"c":"#4930AD"}],
[{"n":"Pantone 2738",
"c":"#2D008E"},
{"n":"Pantone 2745",
"c":"#3F0077"},
{"n":"Pantone 2746",
"c":"#3F2893"},
{"n":"Pantone 2747",
"c":"#1C146B"},
{"n":"Pantone 2748",
"c":"#1E1C77"},
{"n":"Pantone 2755",
"c":"#35006D"},
{"n":"Pantone 2756",
"c":"#332875"}],
[{"n":"Pantone 2757",
"c":"#141654"},
{"n":"Pantone 2758",
"c":"#192168"},
{"n":"Pantone 2765",
"c":"#2B0C56"},
{"n":"Pantone 2766",
"c":"#2B265B"},
{"n":"Pantone 2767",
"c":"#14213D"},
{"n":"Pantone 2768",
"c":"#112151"}],
[{"n":"Pantone 2905",
"c":"#93C6E0"},
{"n":"Pantone 2915",
"c":"#60AFDD"},
{"n":"Pantone 2925",
"c":"#008ED6"},
{"n":"Pantone 2935",
"c":"#005BBF"},
{"n":"Pantone 2945",
"c":"#0054A0"},
{"n":"Pantone 2955",
"c":"#003D6B"},
{"n":"Pantone 2965",
"c":"#00334C"}],
[{"n":"Pantone 2975",
"c":"#BAE0E2"},
{"n":"Pantone 2985",
"c":"#51BFE2"},
{"n":"Pantone 2995",
"c":"#00A5DB"},
{"n":"Pantone 3005",
"c":"#0084C9"},
{"n":"Pantone 3015",
"c":"#00709E"},
{"n":"Pantone 3025",
"c":"#00546B"},
{"n":"Pantone 3035",
"c":"#004454"}],
[{"n":"Pantone 3105",
"c":"#7FD6DB"},
{"n":"Pantone 3115",
"c":"#2DC6D6"},
{"n":"Pantone 3125",
"c":"#00B7C6"},
{"n":"Pantone 3135",
"c":"#009BAA"},
{"n":"Pantone 3145",
"c":"#00848E"},
{"n":"Pantone 3155",
"c":"#006D75"},
{"n":"Pantone 3165",
"c":"#00565B"}],
[{"n":"Pantone 3242",
"c":"#87DDD1"},
{"n":"Pantone 3245",
"c":"#8CE0D1"},
{"n":"Pantone 3248",
"c":"#7AD3C1"},
{"n":"Pantone 3252",
"c":"#56D6C9"},
{"n":"Pantone 3255",
"c":"#47D6C1"},
{"n":"Pantone 3258",
"c":"#35C4AF"},
{"n":"Pantone 3262",
"c":"#00C1B5"}],
[{"n":"Pantone 3265",
"c":"#00C6B2"},
{"n":"Pantone 3268",
"c":"#00AF99"},
{"n":"Pantone 3272",
"c":"#00AA9E"},
{"n":"Pantone 3275",
"c":"#00B2A0"},
{"n":"Pantone 3278",
"c":"#009B84"},
{"n":"Pantone 3282",
"c":"#008C82"},
{"n":"Pantone 3285",
"c":"#009987"}],
[{"n":"Pantone 3288",
"c":"#008270"},
{"n":"Pantone 3292",
"c":"#006056"},
{"n":"Pantone 3295",
"c":"#008272"},
{"n":"Pantone 3298",
"c":"#006B5B"},
{"n":"Pantone 3302",
"c":"#00493F"},
{"n":"Pantone 3305",
"c":"#004F42"},
{"n":"Pantone 3308",
"c":"#004438"}],
[{"n":"Pantone 3375",
"c":"#8EE2BC"},
{"n":"Pantone 3385",
"c":"#54D8A8"},
{"n":"Pantone 3395",
"c":"#00C993"},
{"n":"Pantone 3405",
"c":"#00B27A"},
{"n":"Pantone 3415",
"c":"#007C59"},
{"n":"Pantone 3425",
"c":"#006847"},
{"n":"Pantone 3435",
"c":"#024930"}],
[{"n":"Pantone 3935",
"c":"#F2ED6D"},
{"n":"Pantone 3945",
"c":"#EFEA07"},
{"n":"Pantone 3955",
"c":"#EDE211"},
{"n":"Pantone 3965",
"c":"#E8DD11"},
{"n":"Pantone 3975",
"c":"#B5A80C"},
{"n":"Pantone 3985",
"c":"#998C0A"},
{"n":"Pantone 3995",
"c":"#6D6002"}],
[{"n":"Pantone 4485",
"c":"#604C11"},
{"n":"Pantone 4495",
"c":"#877530"},
{"n":"Pantone 4505",
"c":"#A09151"},
{"n":"Pantone 4515",
"c":"#BCAD75"},
{"n":"Pantone 4525",
"c":"#CCBF8E"},
{"n":"Pantone 4535",
"c":"#DBCEA5"},
{"n":"Pantone 4545",
"c":"#E5DBBA"}],
[{"n":"Pantone 4625",
"c":"#472311"},
{"n":"Pantone 4635",
"c":"#8C5933"},
{"n":"Pantone 4645",
"c":"#B28260"},
{"n":"Pantone 4655",
"c":"#C49977"},
{"n":"Pantone 4665",
"c":"#D8B596"},
{"n":"Pantone 4675",
"c":"#E5C6AA"},
{"n":"Pantone 4685",
"c":"#EDD3BC"}],
[{"n":"Pantone 4695",
"c":"#51261C"},
{"n":"Pantone 4705",
"c":"#7C513D"},
{"n":"Pantone 4715",
"c":"#99705B"},
{"n":"Pantone 4725",
"c":"#B5917C"},
{"n":"Pantone 4735",
"c":"#CCAF9B"},
{"n":"Pantone 4745",
"c":"#D8BFAA"},
{"n":"Pantone 4755",
"c":"#E2CCBA"}],
[{"n":"Pantone 4975",
"c":"#441E1C"},
{"n":"Pantone 4985",
"c":"#844949"},
{"n":"Pantone 4995",
"c":"#A56B6D"},
{"n":"Pantone 5005",
"c":"#BC8787"},
{"n":"Pantone 5015",
"c":"#D8ADA8"},
{"n":"Pantone 5025",
"c":"#E2BCB7"},
{"n":"Pantone 5035",
"c":"#EDCEC6"}],
[{"n":"Pantone 5115",
"c":"#4F213A"},
{"n":"Pantone 5125",
"c":"#754760"},
{"n":"Pantone 5135",
"c":"#936B7F"},
{"n":"Pantone 5145",
"c":"#AD8799"},
{"n":"Pantone 5155",
"c":"#CCAFB7"},
{"n":"Pantone 5165",
"c":"#E0C9CC"},
{"n":"Pantone 5175",
"c":"#E8D6D1"}],
[{"n":"Pantone 5185",
"c":"#472835"},
{"n":"Pantone 5195",
"c":"#593344"},
{"n":"Pantone 5205",
"c":"#8E6877"},
{"n":"Pantone 5215",
"c":"#B5939B"},
{"n":"Pantone 5225",
"c":"#CCADAF"},
{"n":"Pantone 5235",
"c":"#DDC6C4"},
{"n":"Pantone 5245",
"c":"#E5D3CC"}],
[{"n":"Pantone 5255",
"c":"#35264F"},
{"n":"Pantone 5265",
"c":"#493D63"},
{"n":"Pantone 5275",
"c":"#605677"},
{"n":"Pantone 5285",
"c":"#8C8299"},
{"n":"Pantone 5295",
"c":"#B2A8B5"},
{"n":"Pantone 5305",
"c":"#CCC1C6"},
{"n":"Pantone 5315",
"c":"#DBD3D3"}],
[{"n":"Pantone 5395",
"c":"#02283A"},
{"n":"Pantone 5405",
"c":"#3F6075"},
{"n":"Pantone 5415",
"c":"#607C8C"},
{"n":"Pantone 5425",
"c":"#8499A5"},
{"n":"Pantone 5435",
"c":"#AFBCBF"},
{"n":"Pantone 5445",
"c":"#C4CCCC"},
{"n":"Pantone 5455",
"c":"#D6D8D3"}],
[{"n":"Pantone 5463",
"c":"#00353A"},
{"n":"Pantone 5467",
"c":"#193833"},
{"n":"Pantone 5473",
"c":"#26686D"},
{"n":"Pantone 5477",
"c":"#3A564F"},
{"n":"Pantone 5483",
"c":"#609191"},
{"n":"Pantone 5487",
"c":"#667C72"},
{"n":"Pantone 5493",
"c":"#8CAFAD"}],
[{"n":"Pantone 5497",
"c":"#91A399"},
{"n":"Pantone 5503",
"c":"#AAC4BF"},
{"n":"Pantone 5507",
"c":"#AFBAB2"},
{"n":"Pantone 5513",
"c":"#CED8D1"},
{"n":"Pantone 5517",
"c":"#C9CEC4"},
{"n":"Pantone 5523",
"c":"#D6DDD6"},
{"n":"Pantone 5527",
"c":"#CED1C6"}],
[{"n":"Pantone 5535",
"c":"#213D30"},
{"n":"Pantone 5545",
"c":"#4F6D5E"},
{"n":"Pantone 5555",
"c":"#779182"},
{"n":"Pantone 5565",
"c":"#96AA99"},
{"n":"Pantone 5575",
"c":"#AFBFAD"},
{"n":"Pantone 5585",
"c":"#C4CEBF"},
{"n":"Pantone 5595",
"c":"#D8DBCC"}],
[{"n":"Pantone 5605",
"c":"#233A2D"},
{"n":"Pantone 5615",
"c":"#546856"},
{"n":"Pantone 5625",
"c":"#728470"},
{"n":"Pantone 5635",
"c":"#9EAA99"},
{"n":"Pantone 5645",
"c":"#BCC1B2"},
{"n":"Pantone 5655",
"c":"#C6CCBA"},
{"n":"Pantone 5665",
"c":"#D6D6C6"}],
[{"n":"Pantone 5743",
"c":"#3F4926"},
{"n":"Pantone 5747",
"c":"#424716"},
{"n":"Pantone 5753",
"c":"#5E663A"},
{"n":"Pantone 5757",
"c":"#6B702B"},
{"n":"Pantone 5763",
"c":"#777C4F"},
{"n":"Pantone 5767",
"c":"#8C914F"},
{"n":"Pantone 5773",
"c":"#9B9E72"}],
[{"n":"Pantone 5777",
"c":"#AAAD75"},
{"n":"Pantone 5783",
"c":"#B5B58E"},
{"n":"Pantone 5787",
"c":"#C6C699"},
{"n":"Pantone 5793",
"c":"#C6C6A5"},
{"n":"Pantone 5797",
"c":"#D3D1AA"},
{"n":"Pantone 5803",
"c":"#D8D6B7"},
{"n":"Pantone 5807",
"c":"#E0DDBC"}],
[{"n":"Pantone 5815",
"c":"#494411"},
{"n":"Pantone 5825",
"c":"#75702B"},
{"n":"Pantone 5835",
"c":"#9E9959"},
{"n":"Pantone 5845",
"c":"#B2AA70"},
{"n":"Pantone 5855",
"c":"#CCC693"},
{"n":"Pantone 5865",
"c":"#D6CEA3"},
{"n":"Pantone 5875",
"c":"#E0DBB5"}]]


;

export default ColorList;


