
import {
  observable, extendObservable, computed, action, autorun,
  //  whyRun,isObservable,
  reaction,
  ObservableMap
} from 'mobx';

import ColorNameMap from '../../UI/Tools/ColorNameMap.jsx';
import ColorList from '../../UI/Tools/ColorList.jsx';
import uuid from 'uuid';
import moment from 'moment';
import shortid from 'shortid';

function fixNum(num) { return +(Math.round(num + "e+4") + "e-4"); }

// This is the core data store for ModaQuote.com
// I wrote this before I knew much about typescript/PropTypes.

export class QuoteDataModel {
  //=== Quote Data.  This is the core model
  @observable doc = null;            // quote document.  Holds the ENTIRE user document object

  @observable provider = {};         // florist business info
  @observable appUserId = null;      // florist app user id
  @observable docId = null;          // quote document UUID
  @observable viewQuoteCode = null;  // client uuid for read only view
  @observable likeMap = null;        // recent like/dislike values
  @observable commentList = {};      // comments
  @observable docItemIdMap = {};     // convenience for per-item client comments
  @observable markup = 2;            // handy default for current default item markup
  @observable contribCount = {};     // used to know if contributions are present for an item
  @observable quoteStatus = 'New'; // New, Sent, Viewed, Signed, etc.
  @observable selectedImage = '';  // For adding images to items.                  

  //==== Menu Control: should be a separate store
  @observable selectedMenuItem = "General Info";  // UI menu location
  @observable selectedMenuType = "General Info";  // UI menu type
  @observable selectedMenuTitle = "-";             // menu support
  @observable showItemCatalog = false;             // display of basic item selection menu    
  @observable menuMode = 'menu';                   // valid values are menu, image, color, mats
  @observable isMenuOpen = false;                  // general menu display state.
  @observable isShowingSaveAs = false;
  @observable isShowingArchive = false;
  @observable isShowingShare = false;
  @observable isShowingSendInvoice = false;
  @observable isShowingNeedsLoginInfo = 0;
  @observable isOpenMaterialColorChooser = false;
  @observable targetMaterialIndex = 0;             // for adding materials to items.
  @observable verHistory = {doc:{list:[]}};             // list of version history

  //=== UI busy icon controll this should be a separate store
  @observable isWorking = 0;         // should be in a separate store.
  @observable changeCount = -1;        // has the document changed?
  @observable saveNeeded = 0;        // pending changes for save icon 


  @observable colorNameFilter = '';

  // this is pretty lame.  Holds a ref to a phase object, for adding items to the phase.
  @observable itemCatalogTargetPhase = {};


  //=== incidental validation feedback
  @observable paymentMessage = '';                      // payment validation
  @observable lastError = "";                         // ui error checking
  @observable commentInProgress = observable.map({});   // multi-item simultaneous comment support


  //=== interactions with the inventory page
  @observable addStockItemWindow = false; // displays the modal
  @observable editStockItemWindow=false; // displays the modal
  @observable editOrderStockId=0; // tels the inv page if this is an add or an edit.

  reactionToMenu = reaction(
    () => this.menuMode,
    length => window.dispatchEvent(new Event('resize'))
  );


  //======= END OF MODEL ========
  // Everything below this point is either computed value or controller actions.

  // clear out any residual information
  @action resetQuoteData() {
    this.doc = null;            // quote doc
    this.provider = {};         // provider info
    this.appUserId = null;      // florist app user id
    this.docId = null;          // documentID
    this.viewQuoteCode = null;
    this.likeMap = null;        // recent like values
    this.commentList = {};      // comments
    this.docItemIdMap = {};
    this.contribCount = {};     // used to know if contributs are present for an item
    this.quoteStatus = 'New';
    this.markup = 2;
    this.selectedMenuItem = "General Info";
    this.selectedMenuType = "General Info";
    this.selectedMenuTitle = "-";
    this.selectedImage = '';
    this.menuMode = 'menu';
    this.lastError = "";
    this.commentInProgress = observable.map({});
    this.isMenuOpen = false;
  }

  // start a new quote
  @action createEmptyQuote() {
    this.viewQuoteCode = shortid.generate();
    this.doc = observable({
      "metaData": {
        "docItemId": uuid.v4(),
        "clientAccessCode": this.viewQuoteCode,
        "clientOneTitle": "Bride",
        "brideName": "",
        "clientName": "",
        "eventName": "",
        "clientTwoTitle": "Groom",
        "groomName": "",
        "contactEmail": "",
        "contactPhone": "",
        "mainLocation": "event location",
        "notes": "",
        "notesPrivate": "",
        "estimatedBudget": "$2500",
        "accountId": "",
        "orderId": "",
        "date": new moment().format('YYYY-MM-DD'),
        "inspirationList": [],
        "generalInfoList": { "data": [] },
      },
      "phaseList": { "data": [] },
      "vendorList": { "data": [] },
      "clauseList": { "data": [] },
      "invoiceList": { "data": [] },
      "costList": []
    });
    this.addPhase('GENERAL', true);
  }

  @observable clientCreateAction=false; // only true during this method call.
  @action createClientQuote(clientInfo, rowIdx) {
    this.clientCreateAction=true;
    this.viewQuoteCode = shortid.generate();
    this.doc = observable({
      "metaData": {
        "docItemId": uuid.v4(),
        "clientAccessCode": this.viewQuoteCode,
        "clientOneTitle": clientInfo.type,
        "brideName": '-----',
        "clientName": clientInfo.name,
        "eventName": "New Event",
        "clientTwoTitle": clientInfo.type,
        "groomName": (clientInfo.contactList[rowIdx].first_name || '') + ' ' + (clientInfo.contactList[rowIdx].last_name || ''),
        "contactEmail": (clientInfo.contactList[rowIdx].ct_email || ''),
        "contactPhone": (clientInfo.contactList[rowIdx].ct_bus_phone || ''),
        "companyAddy": clientInfo.billing_address_1 + ' ' + clientInfo.billing_address_2,
        "companyCityState": clientInfo.billing_city + ' ' + clientInfo.billing_state + ' ' + clientInfo.billing_zip,
        "notes": "",
        "notesPrivate": "",
        "estimatedBudget": "",
        "accountId": clientInfo.clientId,
        "orderId": "",
        "date": new moment().format('YYYY-MM-DD'),
        "inspirationList": [],
        "generalInfoList": { "data": [] },
      },
      "phaseList": { "data": [] },
      "vendorList": { "data": [] },
      "clauseList": { "data": [] },
      "invoiceList": { "data": [] },
      "costList": []
    });
    this.addPhase('GENERAL', true);
  }


  // update the status of this quote document
  @action setQuoteStatus(newStatus) {
    this.quoteStatus = newStatus;
  }

  // sum up the cost of non-tax, non-percentage items for each phase
  // this computation is the root of many many other computations
  @computed get phaseItemTotals() {
    if (!this.doc) { return {} };
    if (!this.doc.phaseList) {
      this.doc.phaseList = observable({ data: [] });
    }
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {
        res[phase.phaseId] = 0;
        phase.lineItemList.forEach((item) => {
          if ((item.status > 0 && item.status <= 3) || item.status === 5) { // do not include fees
            res[phase.phaseId] = fixNum(res[phase.phaseId] +
              fixNum((Number(item.qty) || 0) * (Number(item.cost) || 0)));
          }
        });
      });
    }
    return res;
  }

  // get the sum for the whole quote, by adding up the sum of each phase
  @computed get quoteItemTotal() {
    if (!this.doc) { return 0 };
    var itemSum = 0;
    Object.keys(this.phaseItemTotals).forEach((key) => { itemSum = fixNum(itemSum + this.phaseItemTotals[key]); });
    return itemSum;
  }

  // Now to build up the fee structure
  @computed get phaseFeeTotals() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {
        res[phase.phaseId] = 0;
        phase.lineItemList.forEach((item) => {
          if (item.status === 4) {                  // an enumerator says what?
            res[phase.phaseId] = fixNum(res[phase.phaseId] + fixNum((item.cost || 0)));
          }
          else if (item.status === 6) {
            res[phase.phaseId] = fixNum(res[phase.phaseId] + fixNum((item.cost || 0) / 100) * this.quoteItemTotal);
          }
        });
      });
    }
    return res;
  }

  // get the sum of all the fees for the quote, by adding up all the fees for each phase.
  @computed get quoteFeeTotal() {
    if (!this.doc) { return 0 };
    var sum = 0;
    Object.keys(this.phaseFeeTotals).forEach((key) => { sum = fixNum(sum + this.phaseFeeTotals[key]); });
    return sum;
  }

  // Now find the tax items, which are calculated based on the sum of items and fees
  @computed get phaseTaxTotals() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {
        res[phase.phaseId] = 0;
        phase.lineItemList.forEach((item) => {
          if (item.status === 7) {
            res[phase.phaseId] =
              fixNum(
                fixNum(res[phase.phaseId] + ((item.cost || 0) / 100)) *
                fixNum(((this.quoteItemTotal || 0) + (this.quoteFeeTotal || 0)))
              ); // tax on items and fees

              
            console.log('tax1', res[phase.phaseId], 'tax2', 
              (item.cost || 0) / 100) ;

            console.log( 'tax3', 
              fixNum(res[phase.phaseId] + ( (item.cost || 0) / 100) ) , 
              'tax4', 
              this.quoteItemTotal,
              this.quoteFeeTotal,
              fixNum(this.quoteItemTotal),
              fixNum(this.quoteFeeTotal),
              fixNum( (this.quoteItemTotal || 0) + (this.quoteFeeTotal || 0) )
          );
              

          }
        });
      });
    }
    return res;
  }

  // sum up the quote total tax based on each phase's tax.
  @computed get quoteTaxTotal() {
    if (!this.doc) { return 0 };
    var sum = 0;
    Object.keys(this.phaseTaxTotals).forEach((key) => { sum = fixNum(sum + this.phaseTaxTotals[key]); });
    return sum;
  }

  // sum the items, fees and taxes for each phase.
  @computed get phaseSumTotals() {
    if (!this.doc) { return {} };

    var phaseSum = {};
    Object.keys(this.phaseItemTotals).forEach((phaseKey) => {
      phaseSum[phaseKey] = this.phaseItemTotals[phaseKey];
    });

    Object.keys(this.phaseFeeTotals).forEach((phaseKey) => {
      phaseSum[phaseKey] = fixNum(phaseSum[phaseKey] + this.phaseFeeTotals[phaseKey]);
    });

    Object.keys(this.phaseTaxTotals).forEach((phaseKey) => {
      phaseSum[phaseKey] = fixNum(phaseSum[phaseKey] + this.phaseTaxTotals[phaseKey]);
    });

    return phaseSum;
  }

  // sum the sum of the sums until you get the final total actual price with taxes and fees
  @computed get totalClientPrice() {
    return fixNum(this.quoteItemTotal +
      this.quoteFeeTotal +
      this.quoteTaxTotal);
  }


  //===== This marks the end of the core computational model =====
  // Everything from here down is UI jiggery-pokery

  // TOTALLY UN-USED JUNK CODE!
  // I left this in for the code review to illustrate an interesting point.
  // This code is not referenced in any of the UI any more.  
  // So while it should be cleaned up; it's not hurting my performance at all!
  // MobX is smart enough to realize that if no UI cares about this computatation, it wont bother to run it.
  @computed get quoteSpecialCostTotalMap() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {
        phase.lineItemList.forEach((item) => {
          res[item.docItemId] = 0;
          item.costList.forEach((mat) => {
            res[item.docItemId] = fixNum(res[item.docItemId] + fixNum(((mat.qty || 0) * (mat.cost || 0) * (item.qty || 0))));
          });
        });
      });
    }
    return res;
  }


  @computed get headerImgURL() {
    var url = '';
    if (this.doc.metaData.inspirationList &&
      this.doc.metaData.inspirationList.length > 0 &&
      this.doc.metaData.inspirationList[0] &&
      this.doc.metaData.inspirationList[0].imgURL &&
      this.doc.metaData.inspirationList[0].imgURL.toLowerCase().startsWith('http')) {
      url = this.doc.metaData.inspirationList[0].imgURL;
    }
    return url;
  }


  @computed get quoteSpecialPriceMap() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {

        phase.lineItemList.forEach((item) => {
          if (item.status === 6) {
            res[item.docItemId] = fixNum(((item.cost || 0) / 100) * this.quoteItemTotal); // fee is percentage of item total
          }
          else if (item.status === 7) {
            res[item.docItemId] = fixNum(((item.cost || 0) / 100) * fixNum(((this.quoteItemTotal || 0) + (this.quoteFeeTotal || 0)))); // tax is pct on item+fee total
          }
          else {
            res[item.docItemId] = fixNum(((item.cost || 0)) * ((item.qty || 0))); // tax is pct on item+fee total
          }
        });
      });
    }
    return res;
  }

  @computed get quoteSpecialCostItemMap() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.
      this.doc.phaseList.data.forEach((phase) => {
        phase.lineItemList.forEach((item) => {
          res[item.docItemId] = 0;
          item.costList.forEach((mat) => {
            res[item.docItemId] = fixNum(res[item.docItemId] + fixNum(((mat.qty || 0) * (mat.cost || 0))));
          });
        });
      });
    }
    return res;
  }



  @computed get quoteMarkupMap() {
    if (!this.doc) { return {} };
    let res = {};
    if (typeof this.doc.phaseList.data.map === 'function') {
      // ok, we have valid data.

      this.doc.phaseList.data.forEach((phase) => {
        phase.lineItemList.forEach((item) => {
          res[item.docItemId] = 0;
          item.costList.forEach((mat) => {
            res[item.docItemId] = fixNum(res[item.docItemId] +
              fixNum(((mat.qty || 0) * (mat.cost || 0) * (item.qty || 0) *
                fixNum(mat.markup || (1 + ((item.markup || 100) / 100))
                ))));
          });
        });
      });

    }
    return res;
  }

  @action toggleIsMenuOpen() {
    if (!this) { return; } // why?
    this.isMenuOpen = !this.isMenuOpen;
  }


  @action updatePriceAll(multi) {
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].status <= 5) {  // only items, not taxes or fee percents            
          // do the multiplication
          this.doc.phaseList.data[pctr].lineItemList[ictr].cost =
            fixNum(Number(this.doc.phaseList.data[pctr].lineItemList[ictr].cost || 0) +
              fixNum(Number((this.doc.phaseList.data[pctr].lineItemList[ictr].cost || 0) * multi))
            );
          // scrub off the bolloux
          this.doc.phaseList.data[pctr].lineItemList[ictr].cost = fixNum((this.doc.phaseList.data[pctr].lineItemList[ictr].cost || 0));
        }
      }
    }
  }


  //######## Material Cost Estimate Work #########    
  @computed get materialUsageMap() {
    var pctr = 0;
    var ictr = 0;
    var cctr = 0;
    var res = {
      matTotalCost: 0,
      keyList: []
    };
    for (pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {
          var curName = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name;
          var curColor = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color;
          if (curColor && -1 !== curColor.indexOf('#')) {
            curColor = curColor.substr(0, curColor.indexOf('#'));
          }
          if (curColor) {
            curName = (curName + ' = ' + curColor).trim();
          }

          if (!res[curName]) {
            res[curName] = {
              totalCost: 0,
              totalQty: 0,
              unitCost: 0,
              docItemIdList: []
            };
            res.keyList.push(curName);
          }
          res[curName].docItemIdList.push(this.doc.phaseList.data[pctr].lineItemList[ictr].docItemId);  // save the id to the lookup list
          res[curName][this.doc.phaseList.data[pctr].lineItemList[ictr].docItemId] = {                    // add the data to the object
            name: this.doc.phaseList.data[pctr].lineItemList[ictr].name,
            qty: this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].qty,
            itemQty: this.doc.phaseList.data[pctr].lineItemList[ictr].qty,
            cost: this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].cost,
          };
          res[curName].totalQty += fixNum(this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].qty *
            this.doc.phaseList.data[pctr].lineItemList[ictr].qty);

          var costIncrement = fixNum(this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].qty *
            this.doc.phaseList.data[pctr].lineItemList[ictr].qty *
            this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].cost);
          res[curName].totalCost = fixNum(res[curName].totalCost + costIncrement);
          res[curName].unitCost = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].cost;
          res.matTotalCost = fixNum(res.matTotalCost + costIncrement);
        }
      }
    }

    res.keyList.sort();
    return res;
  }

  @action changeMaterialCost(matName, matColor, newCost) {
    if (isNaN(parseFloat(newCost)) || !isFinite(newCost)) {
      return;
    }

    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (var cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {
          var curName = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name;
          var curColor = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color || '';
          if (curName === matName && curColor === matColor) {
            this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].cost = newCost;
          }
        }
      }
    }
  }

  @action updateMaterialQty(matName, matColor, newQty) {
    if (isNaN(parseFloat(newQty)) || !isFinite(newQty)) {
      return;
    }

    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (var cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {
          var curName = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name;
          var curColor = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color || '';
          if (curName === matName && curColor === matColor) {
            this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].qty = newQty;
          }
        }
      }
    }
  }




  //############## Payments: This should be made a seperate data store #######

  @computed get totalPaymentRemaining() {
    return fixNum(this.totalClientPrice - this.totalAmountPaid);
  }

  @computed get totalPaymentRemainingPct() {
    return fixNum(100 - fixNum(fixNum(fixNum(this.totalClientPrice - this.totalAmountPaid) / this.totalClientPrice) * 100));
  }

  objectMap() {
    var res = {};
    var pctr = 0;
    var ictr = 0
    if ("General Info" === this.selectedMenuType) { return this.doc.metaData; }
    if ("Terms" === this.selectedMenuType) { return this.doc.clauseList; }
    if ("phase" === this.selectedMenuType) {
      for (pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
        if (this.doc.phaseList.data[pctr].phaseId === this.selectedMenuItem) { res = this.doc.phaseList.data[pctr] };
      };
      return res;
    }

    if ("item" === this.selectedMenuType) {
      for (pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
        for (ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
          if (this.selectedMenuItem === this.doc.phaseList.data[pctr].lineItemList[ictr].docItemId) {
            res = this.doc.phaseList.data[pctr].lineItemList[ictr];
          }
        };
      };
      return res;
    }

    return res;
  }

  //==================== ACTIONS

  @action fetchServerDoc() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/load/" + this.viewQuoteCode,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        saneThis.loadDoc(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action fetchServerDocVerList() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/listVer/" + this.docId,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        saneThis.verHistory = res;
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action fetchServerDocVer(goalVer) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/quote/loadVer/${this.viewQuoteCode}/${goalVer}`  ,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        saneThis.loadDoc(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  @action notifyFloristOfView() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/notify/" + this.viewQuoteCode,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        saneThis.loadDoc(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action archiveQuote(token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/archive",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({
          docId: this.docId
        })
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        res = res.substr(1, res.length - 2);
        if (res.startsWith("Err")) {
          saneThis.lastError = "Unable to archive this quote.";
          return;
        }
        else {
          saneThis.quoteStatus = res;
        }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }

  @action shareQuote(token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/share",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({
          docId: this.docId
        })
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        res = res.substr(1, res.length - 2);
        if (res.startsWith("Err")) {
          console.log(res);
          window.Materialize.toast("Unable to send this quote. " + res, 3000, 'rounded');
          return;
        }
        else {
          window.Materialize.toast("Quote Sent", 3000, 'rounded');
          saneThis.quoteStatus = 'Sent';
        }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  @action saveQuoteAs(token, newName, newDate) {
    var tempDoc = this.doc;

    this.doc = null;
    this.docId = null;
    this.viewQuoteCode = null;
    this.likeMap = null;        // recent like values
    this.commentList = {};      // comments
    this.docItemIdMap = {};
    this.contribCount = {};     // used to know if contributs are present for an item
    this.quoteStatus = 'New';
    this.markup = 2;
    this.selectedImage = '';

    this.viewQuoteCode = uuid.v4();

    tempDoc.docId = uuid.v4();
    tempDoc.eventName = newName;
    tempDoc.metaData.brideName = newName ;
    tempDoc.metaData.docItemId = uuid.v4();
    tempDoc.metaData.clientAccessCode = this.viewQuoteCode;
    tempDoc.metaData.date = newDate;

    // iterate over phases and items
    for (var pctr = 0; pctr < tempDoc.phaseList.data.length; pctr++) {
      tempDoc.phaseList.data[pctr].phaseId = uuid.v4();
      for (var ictr = 0; ictr < tempDoc.phaseList.data[pctr].lineItemList.length; ictr++) {
        tempDoc.phaseList.data[pctr].lineItemList[ictr].docItemId = uuid.v4();
      }
    }

    tempDoc.costList.clear();
    tempDoc.vendorList.data.clear();

    var ddiff = moment(tempDoc.metaData.date).diff(newDate, 'days');
    for (var ictr = 0; ictr < tempDoc.invoiceList.data.length; ictr++) {
      var start = moment(tempDoc.invoiceList.data[ictr].actionDate);
      start.subtract(ddiff, 'days');
      this.doc.invoiceList.data[ictr].actionDate = start.format('YYYY-MM-DD');
      this.doc.invoiceList.data[ictr].amount = 0;
    }

    this.loadDoc({
      doc: tempDoc,
      contributionList: [],
      provider: this.provider,
      appUserId: this.appUserId,
      likeMap: {},
      docId: tempDoc.docId,
      commentList: [],
      quoteStatus: 'New'

    });
    this.saveNeeded = 1;

  }

  // this.props.LoginData.token
  @action saveQuote(token, router, goalPage) {
    if (this.provider.quoteCount === 0 && this.provider.providerEmail.startsWith("Free-Sample")) {
      this.isShowingNeedsLoginInfo = 1;
      return;
    }

    var saneThis = this;
    saneThis.isWorking++;
    saneThis.router = router;
    saneThis.goalPage = goalPage;

    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.eventName || 'Missing Client Name');

    //--- make the invoices identifiable
    if (this.doc.invoiceList && this.doc.invoiceList.data) {
      for (var ictr = 0; ictr < this.doc.invoiceList.data.length; ictr++) {
        if (!this.doc.invoiceList.data[ictr].guid) {
          // ensure nothing went without guid
          this.doc.invoiceList.data[ictr].guid = uuid.v4();
        }
        if (this.doc.invoiceList.data[ictr].amount < 0 ||
          this.doc.invoiceList.data[ictr].dueDateType === 1 ||
          this.doc.invoiceList.data[ictr].isAuto) {
          // ensure that the correct due date amount is saved for each item
          this.doc.invoiceList.data[ictr].amount = this.invoiceDueDateList.autoDueDateAmount || 0;
        }
      }
    }

    fetch("mqc/quote/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({
          docId: this.docId,
          appUserId: this.appUserId,
          headerImgURL: this.headerImgURL,
          materialCost: this.materialUsageMap.matTotalCost,
          clientPrice: this.totalClientPrice,
          doc: this.doc
        })
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        saneThis.saveNeeded = 0;

        var fix = res.substring(1, res.length - 1);
        if (-1 === fix.toLowerCase().indexOf('error')) {
          console.log(res);
          saneThis.setQuoteStatus(fix);
          saneThis.changeCount = -1;
          window.Materialize.toast('Quote Saved', 3000, 'rounded');
          window.mqccLastSave = window.mqcc; // copies the current change number to the lastSave change number 

          // reload all the stock data so that it stays fresh
          // trying to get the stock to reload
          saneThis.quoteStockMap.clear();
          // autorun is bugging me.  Just calling the data directly
          var curToken = window.localStorage.getItem('curToken'); // sadly, this is easiest way to get it here.
          if(saneThis.doc){
            for (var pctr = 0; pctr < saneThis.doc.phaseList.data.length; pctr++) {
              for (var ictr = 0; ictr < saneThis.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
                if (saneThis.doc.phaseList.data[pctr].lineItemList[ictr].stockId) {
                  var id = saneThis.doc.phaseList.data[pctr].lineItemList[ictr].stockId;
                  saneThis.stockLoadForQuote(id, curToken)
                }
              }
            }
          }
        }
        else {
          console.log(res);
          window.Materialize.toast(fix, 3000, 'rounded');
        }
        if (saneThis.goalPage) {
          saneThis.router.push(saneThis.goalPage);
        }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) {
        saneThis.isWorking = 0; // force the spinner to stop
        console.log(res);
        window.Materialize.toast('Unable to save quote changes.', 3000, 'rounded red');
      })
  }

  @action readyForNewDoc(token) {
    var selfThing = this.likeMap;
    var docItemIdMap = {};
    this.doc.phaseList.data.map((phase) => {
      return phase.lineItemList.map((item) => {
        // remember the docItemId to name map
        docItemIdMap[item.docItemId] = item;
        return item.inspirationList.map((img) => {
          // give each image a likeItensity in the model
          var key = img.owningDocItemId + img.name.trim();
          console.log(key);
          if (selfThing) {
            if (!(selfThing[key])) { selfThing[key] = 0; }
          }
          return '';
        });
      });
    });
    this.docItemIdMap = docItemIdMap;

    this.likeMap = observable({});
    this.commentList = observable({});

    if (this.provider.quoteCount > 3 && !this.provider.providerEmail.startsWith("Free-Sample")) {
      // do not auto save on creation until they've signed up
      this.saveQuote(token);
    }
  }

  @action loadDoc(res) {

    var selfThing = res.likeMap;
    var docItemIdMap = {};
    if (!res.doc) {
      return;
    }

    // console.log(JSON.stringify(res));

    // used to know if contributs are present for an item
    this.contribCount = {};

    //--- create the doc img mapping
    res.doc.phaseList.data.map((phase) => {
      return phase.lineItemList.map((item) => {
        // remember the docItemId to name map
        docItemIdMap[item.docItemId] = item;
        docItemIdMap[item.docItemId].qtyRequested = null; // clear potentially mis-saved data
        return item.inspirationList.map((img) => {
          // give each image a likeItensity in the model
          var key = img.owningDocItemId + img.name.trim();
          if (!(selfThing[key])) { selfThing[key] = 0; }
          return '';
        });
      });
    });


    if (!res.doc.metaData.date) { console.log("WARNIGN: Had to reset missing event date!"); res.doc.metaData.date = '01/01/2018'; }
    if (!Date.parse(res.doc.metaData.date)) {
      console.log("WARNIGN: Had to reset missing event date!"); res.doc.metaData.date = '01/01/2018';
      window.Materialize.toast('This quote held an invalid date.', 3000, 'rounded red');
      window.Materialize.toast('Please re-enter the event date.', 3000, 'rounded red');
    }
    if (!res.doc.metaData.eventName) { console.log("WARNIGN: Had to reset missing eventName!"); res.doc.metaData.eventName = res.doc.metaData.brideName||'Event Name'; }
    if (!res.doc.metaData.clientName) { console.log("WARNIGN: Had to reset missing clientName!"); res.doc.metaData.clientName = 'Client Name'; }
    if (!res.doc.metaData.brideName) { console.log("WARNIGN: Had to reset missing brideName!"); res.doc.metaData.brideName = ''; }
    if (!res.doc.metaData.groomName) { console.log("WARNIGN: Had to reset missing groomName!"); res.doc.metaData.groomName = ''; }
    if (!res.doc.metaData.contactEmail) { console.log("WARNIGN: Had to reset missing contactEmail!"); res.doc.metaData.contactEmail = ''; }
    if (!res.doc.metaData.contactPhone) { console.log("WARNIGN: Had to reset missing contactPhone!"); res.doc.metaData.contactPhone = ''; }
    if (!res.doc.metaData.mainLocation) { console.log("WARNIGN: Had to reset missing mainLocation!"); res.doc.metaData.mainLocation = ''; }
    if (!res.doc.metaData.notes) { console.log("WARNIGN: Had to reset missing notes!"); res.doc.metaData.notes = ''; }
    if (!res.doc.metaData.notesPrivate) { console.log("WARNIGN: Had to reset missing notesPrivate!"); res.doc.metaData.notesPrivate = ''; }
    if (!res.doc.metaData.accountId) { res.doc.metaData.accountId = ''; }
    if (!res.doc.metaData.orderId) { res.doc.metaData.orderId = ''; }


    //--- make the invoices identifiable
    for (var ictr = 0; ictr < res.doc.invoiceList.data.length; ictr++) {
      if (!res.doc.invoiceList.data[ictr].guid) {
        res.doc.invoiceList.data[ictr].guid = uuid.v4();
      }
      if (res.doc.invoiceList.data[ictr].amount < 0 ||
        res.doc.invoiceList.data[ictr].dueDateType === 1) {
        res.doc.invoiceList.data[ictr].isAuto = true;
      }
    }

    var contribCounterProto = {};

    res.contributionList.forEach((contrib) => {

      if (contrib && contrib.comment) {
        // QUANTITY CHANGE IDEAS
        if (contrib.comment.startsWith("Change Quantity of")) {
          var newQtyTxt = contrib.comment.substring(contrib.comment.lastIndexOf(' '));
          console.log('setQTY');
          var newQty = parseFloat(newQtyTxt);
          if (newQty && docItemIdMap[contrib.docItemId]) {
            docItemIdMap[contrib.docItemId].qtyRequested = newQty;
            contribCounterProto[contrib.docItemId] = ++contribCounterProto[contrib.docItemId] || 1;
          }
        }
        // image ideas
        else if (contrib.comment.startsWith("http")) {
          var newItem = observable({
            "imgURL": contrib.comment,
            "name": contrib.name,
            "comment": null,
            "likeIntensity": 0,
            "suggestionOnly": true,
            "imgId": "00000000-0000-0000-0000-000000000000",
            "owningDocItemId": contrib.docItemId,
            "status": 1
          });
          if (docItemIdMap[contrib.docItemId]) {
            // check if it already contains this
            var found = false;
            for (var ictr = 0; ictr < docItemIdMap[contrib.docItemId].inspirationList.length; ictr++) {
              if (docItemIdMap[contrib.docItemId].inspirationList[ictr] &&
                docItemIdMap[contrib.docItemId].inspirationList[ictr].name === newItem.name) {
                found = true;
              }
            }
            if (!found) {
              docItemIdMap[contrib.docItemId].inspirationList.push(newItem);
              contribCounterProto[contrib.docItemId] = ++contribCounterProto[contrib.docItemId] || 1;
            }
          }
        }
      }
      // color ideas
      else {
        if (ColorNameMap[contrib.name.toLowerCase()]) {
          var newColor = observable({
            "imgURL": ColorNameMap[contrib.name.toLowerCase()],
            "name": contrib.name,
            "comment": null,
            "likeIntensity": 0,
            "suggestionOnly": true,
            "owningDocItemId": contrib.docItemId,
            "status": 1
          });
          if (docItemIdMap[contrib.docItemId]) {
            var found2 = false;
            for (var ictr2 = 0; ictr2 < docItemIdMap[contrib.docItemId].inspirationList.length; ictr2++) {
              if (docItemIdMap[contrib.docItemId].inspirationList[ictr2] &&
                docItemIdMap[contrib.docItemId].inspirationList[ictr2].name === newColor.name) {
                found2 = true;
              }
            }
            if (!found2) {
              docItemIdMap[contrib.docItemId].inspirationList.push(newColor);
              contribCounterProto[contrib.docItemId] = ++contribCounterProto[contrib.docItemId] || 1;
            }
          }
        }
      }
    });

    // fix any bad material colors
    var pctr;
    var cctr;
    for (pctr = 0; pctr < res.doc.phaseList.data.length; pctr++) {

      // phase fixes
      if (!res.doc.phaseList.data[pctr].dateDelivery) res.doc.phaseList.data[pctr].dateDelivery='';
      if (!res.doc.phaseList.data[pctr].timeDelivery) res.doc.phaseList.data[pctr].timeDelivery = '';
      if (!res.doc.phaseList.data[pctr].arriveCarving) res.doc.phaseList.data[pctr].arriveCarving = '';
      if (!res.doc.phaseList.data[pctr].arriveEarly) res.doc.phaseList.data[pctr].arriveEarly = '';
      if (!res.doc.phaseList.data[pctr].timeStart) res.doc.phaseList.data[pctr].timeStart = '';
      if (!res.doc.phaseList.data[pctr].timeEnd) res.doc.phaseList.data[pctr].timeEnd = '';

      if (!res.doc.phaseList.data[pctr].locationName) res.doc.phaseList.data[pctr].locationName = '';
      if (!res.doc.phaseList.data[pctr].locationAddress) res.doc.phaseList.data[pctr].locationAddress = '';
      if (!res.doc.phaseList.data[pctr].locationCityStateZip) res.doc.phaseList.data[pctr].locationCityStateZip = '';

      if (!res.doc.phaseList.data[pctr].contactPerson) res.doc.phaseList.data[pctr].contactPerson = '';
      if (!res.doc.phaseList.data[pctr].contactEmail) res.doc.phaseList.data[pctr].contactEmail = '';
      if (!res.doc.phaseList.data[pctr].contactPhone) res.doc.phaseList.data[pctr].contactPhone = '';

      for (ictr = 0; ictr < res.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        // fix item build notes
        if (!res.doc.phaseList.data[pctr].lineItemList[ictr].detailsBuild){
          res.doc.phaseList.data[pctr].lineItemList[ictr].detailsBuild = '';
        }
        if (!res.doc.phaseList.data[pctr].lineItemList[ictr].completeBuild) {
          res.doc.phaseList.data[pctr].lineItemList[ictr].completeBuild = false;
        }

        // fix taxable
        res.doc.phaseList.data[pctr].lineItemList[ictr].isTaxable = true;  // allow per item tax toggle


        // fix colors
        for (cctr = 0; cctr < res.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {
          var curName = (res.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name || '');
          res.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name = curName.toLowerCase();

          var curColor = (res.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color || '');
          if (-1 !== curColor.indexOf('#')) {
            curColor = curColor.substr(0, curColor.indexOf('#'));
          }
          res.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color = curColor.toLowerCase();
        }
        if (!res.doc.phaseList.data[pctr].lineItemList[ictr].productType) {
          res.doc.phaseList.data[pctr].lineItemList[ictr].productType = 'floral';
        }
        else {
          res.doc.phaseList.data[pctr].lineItemList[ictr].productType = res.doc.phaseList.data[pctr].lineItemList[ictr].productType.toLowerCase();
        }
      }
    }

    // trying to get the stock to reload
    this.quoteStockMap.clear();    
    // autorun is bugging me.  Just calling the data directly
    var curToken = window.localStorage.getItem('curToken'); // sadly, this is easiest way to get it here.
    for (var pctr = 0; pctr < res.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < res.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (res.doc.phaseList.data[pctr].lineItemList[ictr].stockId) {
          var id = res.doc.phaseList.data[pctr].lineItemList[ictr].stockId;
          this.stockLoadForQuote(id, curToken)
        }
      }
    }


    // assign the model
    this.doc = res.doc;
    this.provider = res.provider;
    this.appUserId = res.appUserId;
    this.likeMap = res.likeMap;
    this.docId = res.docId;
    this.docItemIdMap = observable(docItemIdMap);
    this.commentList = res.commentList;
    this.quoteStatus = res.quoteStatus;

    // number the items
    this.reNumberItems();

    // ensure empty arrays where needed:
    if (!this.doc.costList) { this.doc.costList = observable([]) };
    if (!this.doc.phaseList) {
      this.doc.phaseList = observable({ data: [] });
    };

    if (!this.doc.metaData.clientOneTitle) {
      extendObservable(this.doc.metaData, {
        clientOneTitle: 'Bride',
        clientTwoTitle: 'Groom'
      });
    }

    this.contribCount = observable(contribCounterProto);
    window.mqccLastSave = window.mqcc; // copies the current change number to the lastSave change number 

    this.saveNeeded = 0;

  }

  @action addColorToItem(colorName, skipWarning) {
    this.saveNeeded = 1;

    var colorCodeName = colorName.split('\n').join(' ').trim();
    colorCodeName = colorName.split(' ').join('').trim();
    var colorCode = ColorNameMap[colorCodeName.toLowerCase()];
    var curItem = this.objectMap();
    var newColor = observable({
      "imgURL": colorCode,
      "name": colorName.toLowerCase(),
      "comment": null,
      "likeIntensity": 0,
      "suggestionOnly": false,
      "owningDocItemId": curItem.docItemId,
      "status": 0
    });
    for (var ctr = 0; ctr < curItem.inspirationList.length; ctr++) {
      if (colorName.trim() === curItem.inspirationList[ctr].name.trim()) {
        if (!skipWarning) {
          window.Materialize.toast('This color is already in the list.', 3000, 'rounded red');
        }
        return;
      }
    }
    curItem.inspirationList.unshift(newColor);
    window.Materialize.toast('Added ' + colorName, 3000, 'rounded');
  }

  @action addColorToMaterial(colorName) {
    this.saveNeeded = 1;
    colorName = colorName.split('\n').join(' ').trim();

    // change the color of this item.
    var curItem = this.objectMap();
    if ('remove' === colorName) {
      curItem.costList[this.targetMaterialIndex].color = '';
    }
    else {
      curItem.costList[this.targetMaterialIndex].color = colorName;
    }
    var curMatName = curItem.costList[this.targetMaterialIndex].name;

    // set colors on all uses of the material that do not have a color assigned.
    // if they are changing a mat to another color after setting the wrong one, they'll have to do it manually for now
    // or use remove to remove all colors and then set them all to a new color and fix as needed
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (var cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {

          if (this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].name === curMatName) {   // mat name matches
            var curColor = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color;
            if ('remove' === colorName) {
              this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color = '';             // clear if name matches
            }
            else if (!curColor) {                                                                       // not assigned.  Set enmasse
              this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color = colorName;
            }
          }
        }
      }
    }
  }

  // date formatted as ISO string, amount as number
  @action addPaidInvoiceManual(tempDate, tempAmount) {
    this.saveNeeded = 1;
    this.doc.invoiceList.data.push(observable({
      "actionDate": tempDate,
      "amount": tempAmount,
      "status": 2,
      "guid": uuid.v4()
    }));
  }

  @computed get invoiceDueDateList() {       // filter the invoices
    // collect the due dates
    var res = [];
    for (var ctr = 0; ctr < this.doc.invoiceList.data.length; ctr++) {
      if (this.doc.invoiceList.data[ctr].status < 2) {
        res.push({
          amount: this.doc.invoiceList.data[ctr].amount || 0,
          dueDateType: this.doc.invoiceList.data[ctr].dueDateType,
          actionDate: (this.doc.invoiceList.data[ctr].actionDate || moment()),
          guid: this.doc.invoiceList.data[ctr].guid
        });
      }
    };

    // sort them
    res.sort(
      function (a, b) {
        var at = moment(a.actionDate);
        var bt = moment(b.actionDate);
        return at.diff(bt);
      }
    );

    // make the items that are before today into manual items. then count manual sum and auto count
    var now = moment();
    var manualTotal = 0;
    var autoCount = 0;
    for (ctr = 0; ctr < res.length; ctr++) {
      /** logic removed by ModaFloral request
      if(now > moment(res[ctr].actionDate)){
        // PaymentAuto = 1,
        // PaymentSpecific = 2,
        res[ctr].dueDateType = 2; // cannot auto-adust past payments
        res[ctr].isAuto = false;
      }
      */
      if (res[ctr].dueDateType === 1 || res[ctr].isAuto) {
        autoCount += 1; // auto payments
      }
      else {             // everything else
        manualTotal = fixNum(manualTotal + (res[ctr].amount || 0));
      }
    }

    if (manualTotal > this.totalClientPrice + Number.EPSILON ||
      (manualTotal < this.totalClientPrice - Number.EPSILON && autoCount === 0)
    ) {
      res.paymentOffMessage = 'Amount Due does not match Client Price.  Use Auto-Payments to fix.'
    }
    else {
      res.paymentOffMessage = ''
    }

    // now set all of the auto items to their correct amount
    // due dates don't care about how much HAS been paid.  Just the schedule of what would be due when.
    res.autoDueDateAmount = 0;
    if (autoCount > 0) {
      res.autoDueDateAmount = fixNum(fixNum(this.totalClientPrice - manualTotal) / autoCount);
      if (res.autoDueDateAmount < 0) {
        res.autoDueDateAmount = 0;
      }
    }
    for (ctr = 0; ctr < res.length; ctr++) {
      // PaymentAuto = 1,
      // PaymentSpecific = 2,
      if (res[ctr].dueDateType === 1) {
        // WARNIGN! this only changes the computed value
        // the uploaded value still needs to be put into the object during save
        res[ctr].amount = res.autoDueDateAmount || 0;
      }
    }

    var payment = this.totalAmountPaid;
    // now mark which ones are paid, late, not due yet.
    for (ctr = 0; ctr < res.length; ctr++) {
      if (res[ctr].amount <= payment) {
        res[ctr].isPaid = 'paid';
        payment = fixNum(payment - res[ctr].amount);
      }
      else {
        if (now > moment(res[ctr].actionDate)) {
          // late
          if (res[ctr].amount > payment && payment > 0) {
            res[ctr].isPaid = 'partial';
            payment = 0;
          }
          else {
            res[ctr].isPaid = 'late';
          }
        }
        else {
          // not due`
          res[ctr].isPaid = 'not due yet';
        }
      }
    }

    return res;

  }

  /*
  Status Codes:

  Due = 0,
  Pending = 1,
  PaidOffLine = 2,
  PaidOnLine = 3
  */
  @computed get invoicePaidList() {       // filter the invoices
    var res = [];
    if (this.doc && this.doc.invoiceList && this.doc.invoiceList.data) {
      for (var ctr = 0; ctr < this.doc.invoiceList.data.length; ctr++) {
        if (this.doc.invoiceList.data[ctr].status === 2 ||
          this.doc.invoiceList.data[ctr].status === 3
        ) {
          res.push(this.doc.invoiceList.data[ctr]);
        }
      }
    }
    return res.sort(
      function (a, b) {
        var at = moment(a.actionDate);
        var bt = moment(b.actionDate);
        return at.diff(bt);
      }
    );

  }

  @action deletePaidInvoice(token, invoiceId) {
    // delete the record locally
    this.saveNeeded = 1;
    var targetIdx = -1;
    for (var ctr = 0; ctr < this.doc.invoiceList.data.length; ctr++) {
      if (this.doc.invoiceList.data[ctr].guid === invoiceId) {
        targetIdx = ctr;
        break;
      }
    }
    if (targetIdx !== -1) {
      this.doc.invoiceList.data.splice(targetIdx, 1);
    }

    // notify the server to delete the record
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/invoice/pp/delete`,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({
          invoiceId: invoiceId,
        })
      })
      .then(function (res) {
        // very likely that the id will not be present.  
        saneThis.isWorking--;
      })
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to delete the invoice.', 3000, 'rounded red'); })

  }

  @action deleteDueDate(guid) {
    this.saveNeeded = 1;
    var targetIdx = -1;
    for (var ctr = 0; ctr < this.doc.invoiceList.data.length; ctr++) {
      if (this.doc.invoiceList.data[ctr].guid === guid) {
        targetIdx = ctr;
        break;
      }
    }
    if (targetIdx !== -1) {
      this.doc.invoiceList.data.splice(targetIdx, 1);
    }
  }

  @action saveDueDate(dueDateType, date, amount) {
    this.saveNeeded = 1;
    this.doc.invoiceList.data.push(observable({
      "actionDate": date,
      "amount": amount,
      "status": 0,
      "dueDateType": dueDateType,
      "guid": uuid.v4(),

    }));
  }


  @computed get totalAmountPaid() {       // filter the invoices

    if (!this.doc.invoiceList) {
      this.doc.invoiceList = observable({ data: [] });
    }

    var paidAmount = 0;
    for (var ctr = 0; ctr < this.doc.invoiceList.data.length; ctr++) {
      if (this.doc.invoiceList.data[ctr].status === 2) {
        paidAmount = fixNum(paidAmount + (this.doc.invoiceList.data[ctr].amount || 0));
      }
      if (this.doc.invoiceList.data[ctr].status === 3) {
        paidAmount = fixNum(paidAmount + (this.doc.invoiceList.data[ctr].amount || 0));
      }
    }
    return paidAmount;
  }

  @computed get totalBalanceDueNow() {       // filter the invoices
    var amountDue = 0;
    var now = moment();
    for (var ctr = 0; ctr < this.invoiceDueDateList.length; ctr++) {
      var checkDate = moment(this.invoiceDueDateList[ctr].actionDate);
      if (checkDate < now) {
        amountDue = fixNum(amountDue + (this.invoiceDueDateList[ctr].amount || 0));
      }
    }
    return amountDue;
  }

  @computed get totalBalancePastDue() {
    var late = fixNum(this.totalBalanceDueNow - this.totalAmountPaid);
    if (late >= 0) { return late; }
    return 0;
  }



  @computed get totalCostsSpent() {       // filter the invoices
    var res = {
      'guid': uuid.v4(),
      'total': 0,
      'labor': 0,
      'travel': 0,
      'receipt': 0
    }

    if (!this.doc.costList) {
      this.doc.costList = observable([]);
    }

    for (var ctr = 0; ctr < this.doc.costList.length; ctr++) {
      res.total = fixNum(res.total + this.doc.costList[ctr].amount);
      if ('receipt' === this.doc.costList[ctr].type) {
        res.receipt = fixNum(res.receipt + (this.doc.costList[ctr].amount || 0));
      }
      if ('labor' === this.doc.costList[ctr].type) {
        res.labor = fixNum(res.labor + (this.doc.costList[ctr].amount || 0));
      }
      if ('travel' === this.doc.costList[ctr].type) {
        res.travel = fixNum(res.travel + (this.doc.costList[ctr].amount || 0));
      }
    }
    return res;
  }


  @action loadPDF() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/quote/load/" + this.viewQuoteCode,
      {
        headers: { 'Accept': 'application/json' },
        method: "GET"
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this quote code.";
          window.Materialize.toast('Unable to load this pdf.', 3000, 'rounded red');
          return;
        }
        // model clean up - if there is not a like-intensity for each image, the image cannot add one.
        saneThis.loadDoc(res);
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }


  @action setLikeIntensity(docId, docItemId, name, like) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/quote/like/${docId}/${docItemId}/${name}/${like}`,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) {
        saneThis.isWorking--;
        if (!res.ok) {
          console.log(res); window.Materialize.toast('Unable to update like value.', 3000, 'rounded');
        }
      })
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to update like value.', 3000, 'rounded'); })
  }

  @action finishRequestedChange(docItemId) {
    // mqc/quote/quantity/change/finished/{docItemId}
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/quote/quantity/change/finished/${docItemId}`,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) {
        saneThis.isWorking--;
        if (!res.ok) {
          console.log(res); window.Materialize.toast('Unable to complete the quantity change request.', 3000, 'rounded');
        }
      })
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to update like value.', 3000, 'rounded'); })
  }

  @action sendInvoice(amount) {
    console.log('sending invoice for ' + amount);
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/invoice/pp/send`,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          docId: this.docId,
          amount: amount
        })
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.ok) {
          saneThis.paymentMessage = 'An invoice has been sent to ' + saneThis.doc.metaData.contactEmail;
          window.Materialize.toast('An invoice has been sent to ' + saneThis.doc.metaData.contactEmail, 3000, 'rounded green');
        }
        return res.text();
      })
      .then(function (res) {
        if (-1 !== res.indexOf('Error')) {
          saneThis.paymentMessage = res.replace('"Error', ' ').replace('"', ' ');
          window.Materialize.toast(saneThis.paymentMessage, 3000, 'rounded red');
        }
        else {
          var url_string = window.location.href;
          var url = new URL(url_string);
          var accessCode = url.searchParams.get("code");
          if (accessCode) {  // this is the client, open the invoice.  
            var fix = res.substring(1, res.length - 1);
            window.open(fix, '_blank');
          }
        }
      })
      .catch(function (res) {
        console.log(res); window.Materialize.toast('Unable to send the invoice.', 3000, 'rounded red');
      })
  }

  @action checkInvoices() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/invoice/pp/check/` + this.docId,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) {
        saneThis.isWorking--;
        if (!res.ok) {
          saneThis.paymentMessage = res;
          console.log(res); window.Materialize.toast('Unable to check invoice status.', 3000, 'rounded');
          return {}
        }
        return res.json();
      })
      .then(function (res) {
        var foundAny = false;
        for (var ic = 0; ic < res.length; ic++) {

          if (!res[ic].paidDate) {
            res[ic].paidDate = res[ic].createDate;
          }
          if (-1 !== res[ic].paidDate.indexOf('T')) {
            res[ic].paidDate = res[ic].paidDate.substring(0, res[ic].paidDate.indexOf('T'));
          }

          var found = false;
          for (var pctr = 0; pctr < saneThis.doc.invoiceList.data.length; pctr++) {
            if (saneThis.doc.invoiceList.data[pctr].guid === res[ic].invoiceId) {
              found = true;
            }
          }
          if (!found) {
            saneThis.doc.invoiceList.data.push(observable({
              "actionDate": res[ic].paidDate,
              "amount": res[ic].amountBilled,
              "status": 3,
              "guid": res[ic].invoiceId
            }));
            saneThis.paymentMessage = 'Invoice for $' + res[ic].amountBilled + ' paid.';
            window.Materialize.toast('Invoice for $' + res[ic].amountBilled + ' paid.', 3000, 'rounded');
            foundAny = true;
          }
        }
        if (!foundAny) {
          var url_string = window.location.href;
          var url = new URL(url_string);
          var accessCode = url.searchParams.get("code");
          if (!accessCode) {
            // this is the florist, give feedback.  Otherwise don't
            saneThis.paymentMessage = 'No new invoices were paid for this quote.';
            window.Materialize.toast('No new invoices were paid for this quote.', 3000, 'rounded');
          }
        }

      })
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to check invoice status.', 3000, 'rounded'); })
  }



  @action bookQuote() {
    var saneThis = this;
    saneThis.isWorking++;
    fetch(`mqc/quote/sign/${this.docId}`,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) {
        saneThis.isWorking--;
        window.Materialize.toast('Thank you! This quote is signed.', 3000, 'rounded');
        saneThis.fetchServerDoc(); // lazy.  I could just add the comment.  But I want to update any changes and not re-build the icon code.
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to update booking status.', 3000, 'rounded'); })
  }


  @action saveQtyChange(docItemId, itemName, itemQty) {
    this.saveNeeded = 1;
    this.tempCurDocItemId = docItemId; // so super lame and not thread safe... lucky I'm in javascript? :S
    var saneThis = this;
    saneThis.isWorking++;
    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.clientName || 'Missing Client Name');
    fetch(`mqc/quote/quantity/change/${this.docId}/${docItemId}/${this.doc.metaData.brideName}/${itemName}/${itemQty}`,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) { return res.text() })
      .then(function (res) {
        saneThis.isWorking--;
        window.Materialize.toast('Quantity Change Request Saved', 3000, 'rounded');
        this.commentInProgress[docItemId] = '';
        this.loadComment();
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save quantity changes.', 3000, 'rounded'); })
  }


  @action saveStatusChange(docItemId, itemName, itemCode) {
    this.saveNeeded = 1;
    this.tempCurDocItemId = docItemId; // so super lame and not thread safe... lucky I'm in javascript? :S
    var saneThis = this;
    saneThis.isWorking++;
    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.clientName || 'Missing Client Name');

    fetch(`mqc/quote/status/change/${this.docId}/${docItemId}/${this.doc.metaData.brideName}/${itemName}/${itemCode}`,
      { headers: { 'Accept': 'application/json' }, method: "GET" })
      .then(function (res) { return res.text() })
      .then(function (res) {
        saneThis.isWorking--;
        window.Materialize.toast('Status Request Saved', 3000, 'rounded');
        this.commentInProgress[docItemId] = '';
        this.loadComment();
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save status changes.', 3000, 'rounded'); })
  }

  @action saveComment(docItemId, userName) {
    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.clientName || 'Missing Client Name');
    if (!userName) { userName = this.doc.metaData.brideName; }
    this.tempCurDocItemId = docItemId; // so super lame and not thread safe... lucky I'm in javascript? :S
    var saneThis = this;
    saneThis.isWorking++;

    fetch("mqc/quote/comment/save/",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          "comment": this.commentInProgress[docItemId],
          "userName": userName,
          "docId": this.docId,
          "docItemId": docItemId
        })
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.ok) {
          return res.json();
        }
        else { console.log(res); window.Materialize.toast('Sorry: unable to save this comment', 3000, 'rounded red'); }
      })
      .then(function (res) {
        if (res) {
          window.Materialize.toast('Comment Saved', 3000, 'rounded');
          this.commentInProgress[docItemId] = '';
          this.commentList = res;
        }
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save comment.', 3000, 'rounded red'); })

  }

  @action loadComment() {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("mqc/quote/comment/load/",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          "docId": this.docId,
        })
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.ok) {
          return res.json();
        }
        else { console.log(res); window.Materialize.toast('Sorry: unable to update comments', 3000, 'rounded red'); }
      })
      .then(function (res) {
        if (res) {
          this.commentList = res;
        }
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load comment.', 3000, 'rounded red'); })
  }



  @action saveImageURL(docItemId, imgURL) {
    var saneThis = this;
    saneThis.isWorking++;
    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.clientName || 'Missing Client Name');

    fetch("mqc/quote/image/url/save/",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          "imageURL": imgURL,
          "userName": this.doc.metaData.brideName,
          "name": this.doc.metaData.brideName + '-' + moment().format('YYYYMMDDhhmmss'),
          "docId": this.docId,
          "docItemId": docItemId
        })
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) {
          window.Materialize.toast('Image URL Saved', 3000, 'rounded');
          saneThis.fetchServerDoc(); // lazy.  I could just add the comment.  But I want to update any changes and not re-build the icon code.
        }
        else { console.log(res); window.Materialize.toast(res, 3000, 'rounded'); }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save image url.', 3000, 'rounded'); })
  }

  @action saveImageFile(docItemId, data, isProposal, callback) {
    var formData = new FormData();

    formData.append("docItemId", docItemId);
    formData.append("docId", this.docId);
    if (!isProposal) {
      formData.append("isProposal", "no");
    }
    else {
      formData.append("isProposal", "yes");
    }

    // eslint-disable-next-line
    for (var name in data) {
      formData.append(name, data[name]);
    }
    var saneThis = this;
    saneThis.isWorking++;
    fetch('mqc/quote/image/file/save/', {
      method: 'POST',
      body: formData
    })
      .then(function (res) { 
        debugger;
        return res.text() 
      })
      .then(function (res) {
        saneThis.isWorking--;
        window.Materialize.toast('Image File Saved', 3000, 'rounded');
        if (callback) {
          callback(res.replace(/"/g, ""));
          saneThis.fetchServerDoc(); // lazy.  I could just add the comment.  But I want to update any changes and not re-build the icon code.
        }
        else {
          saneThis.fetchServerDoc(); // lazy.  I could just add the comment.  But I want to update any changes and not re-build the icon code.
        }
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { 
        saneThis.isWorking--;
        console.log(res); 
        window.Materialize.toast('Unable to save image file.', 3000, 'rounded'); 
      })
  }


  @action saveColorRequest(docItemId, itemName, colorName, colorCode) {
    this.tempCurDocItemId = docItemId; // so super lame and not thread safe... lucky I'm in javascript? :S
    var saneThis = this;
    saneThis.isWorking++;
    // this.doc.metaData.brideName = (this.doc.metaData.clientName || 'Missing Event Name') + ' - ' + (this.doc.metaData.clientName || 'Missing Client Name');

    fetch("mqc/quote/request/color",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        method: "POST",
        body: JSON.stringify({
          "itemName": itemName,
          "colorName": colorName,
          "colorCode": colorCode,
          "userName": this.doc.metaData.brideName,
          "docId": this.docId,
          "docItemId": docItemId
        })
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        if ('""' === res) {
          window.Materialize.toast('Color Request Saved', 3000, 'rounded');
          this.commentInProgress[docItemId] = '';
          saneThis.fetchServerDoc(); // lazy.  I could just add the comment.  But I want to update any changes and not re-build the icon code.
        }
        else { console.log(res); window.Materialize.toast(res, 3000, 'rounded'); }
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save color request.', 3000, 'rounded'); })
  }


  // 
  @action saveQuoteAsPDF() {
    console.log(`https://modaquote.azurewebsites.net/mqc/quote/pdf/${this.docId}`);
    window.open(`https://modaquote.azurewebsites.net/mqc/quote/pdf/${this.docId}`, '_blank');
  }
  @action saveQuoteAsFloristPDF() {
    window.open(window.location.origin + `/mqc/quote/pdfFlorist/${this.docId}`, '_blank');
  }

  @action addImageToModel(name, url, skipAlert) {
    var curItem = this.objectMap();

    // check if the image should be added to the model
    for (var ctr = 0; ctr < curItem.inspirationList.length; ctr++) {
      if (name.toLowerCase().trim() === curItem.inspirationList[ctr].name.toLowerCase().trim()) {
        if (!skipAlert) {
          window.Materialize.toast(name + ' was already in the list.', 3000, 'rounded red');
        }
        return;
      }
    }

    this.saveNeeded = 1;
    curItem.inspirationList.unshift(observable({
      "imgURL": url.trim(),
      "name": name.trim(),
      "comment": null,
      "likeIntensity": 0,
      "suggestionOnly": false,
      "status": 0,
      "created": "0001-01-01T00:00:00",
      "owningDocItemId": curItem.docItemId
    }));
    window.Materialize.toast('Added ' + name, 3000, 'rounded');

  }

  @action deleteItem(docItemId) {
    this.saveNeeded = 1;
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].docItemId === docItemId) {
          this.selectedMenuItem = "General Info";
          this.selectedMenuType = "GeneralInfoPage";
          this.selectedImage = '';

          this.doc.phaseList.data[pctr].lineItemList.splice(ictr, 1);
        }
      }
    }
    this.reNumberItems();

  }

  @action reNumberItems(){
    var curItemNum=1;
    if(!this.doc){
      return
    }

    // colors in inspirations
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {          
        this.doc.phaseList.data[pctr].lineItemList[ictr].itemNumber = curItemNum;
        curItemNum++;
      }
    }
  }

  @computed get allColorsInQuote() {
    var colorList = [];
    var alreadyAdded = [];

    // colors in inspirations
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (var cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList.length; cctr++) {

          var colorName = this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name;
          var colorCodeName = colorName.split('\n').join(' ').trim();

          if (ColorNameMap[colorCodeName.toLowerCase()]) {
            if (-1 === alreadyAdded.indexOf(colorName.toLowerCase())) {
              colorList.push(colorName.toLowerCase());
              alreadyAdded.push(colorName.toLowerCase());
            }
          }
          else {
            if (
              this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].imgURL &&
              -1 === alreadyAdded.indexOf(this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name) &&
              !this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].imgURL.startsWith('#')
            ) {
              alreadyAdded.push(this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name);
            }
          }
        }
      }
    }

    for (cctr = 0; cctr < this.doc.metaData.inspirationList.length; cctr++) {
      colorName = this.doc.metaData.inspirationList[cctr].name;
      colorCodeName = colorName.split('\n').join(' ').trim();

      if (ColorNameMap[colorCodeName.toLowerCase()]) {
        if (-1 === alreadyAdded.indexOf(colorName)) {
          colorList.push(colorName);
          alreadyAdded.push(colorName);
        }
      }
      else {
        if (this.doc.metaData.inspirationList[cctr].imgURL &&
          -1 === alreadyAdded.indexOf(this.doc.metaData.inspirationList[cctr].name) &&
          !this.doc.metaData.inspirationList[cctr].imgURL.startsWith('#')
        ) {
          alreadyAdded.push(this.doc.metaData.inspirationList[cctr].name);
        }
      }
    }

    // colors in materials
    for (pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length; cctr++) {
          if (this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color) {
            colorName = this.doc.phaseList.data[pctr].lineItemList[ictr].costList[cctr].color;
            if (colorName && -1 !== colorName.indexOf('#')) {
              colorName = colorName.substr(0, colorName.indexOf('#'));
            }
            if (-1 === alreadyAdded.indexOf(colorName) && !colorName.startsWith('remove')) {
              colorList.push(colorName);
              alreadyAdded.push(colorName);
            }
          }
        }
      }
    }
    console.log(JSON.stringify(colorList));
    return colorList;
  }


  @computed get allImagesInQuote() {
    var imageList = [];

    var alreadyAdded = [];

    // colors in inspirations
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        for (var cctr = 0; cctr < this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList.length; cctr++) {

          var colorName = this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name;
          var colorCodeName = colorName.split('\n').join(' ').trim();
          colorCodeName = colorName.split(' ').join('').trim();

          if (ColorNameMap[colorCodeName.toLowerCase()]) {
          }
          else {
            if (
              this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].imgURL &&
              -1 === alreadyAdded.indexOf(this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name) &&
              !this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].imgURL.startsWith('#')
            ) {
              imageList.push({
                name: this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name,
                url: this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].imgURL
              });
              alreadyAdded.push(this.doc.phaseList.data[pctr].lineItemList[ictr].inspirationList[cctr].name);
            }
          }
        }
      }
    }

    for (cctr = 0; cctr < this.doc.metaData.inspirationList.length; cctr++) {
      colorName = this.doc.metaData.inspirationList[cctr].name;
      colorCodeName = colorName.split('\n').join(' ').trim();
      colorCodeName = colorName.split(' ').join('').trim();

      if (ColorNameMap[colorCodeName.toLowerCase()]) {
        if (-1 === alreadyAdded.indexOf(colorName)) {
          alreadyAdded.push(colorName);
        }
      }
      else {
        if (this.doc.metaData.inspirationList[cctr].imgURL &&
          -1 === alreadyAdded.indexOf(this.doc.metaData.inspirationList[cctr].name) &&
          !this.doc.metaData.inspirationList[cctr].imgURL.startsWith('#')
        ) {
          imageList.push({ name: this.doc.metaData.inspirationList[cctr].name, url: this.doc.metaData.inspirationList[cctr].imgURL });
          alreadyAdded.push(this.doc.metaData.inspirationList[cctr].name);
        }
      }
    }

    return imageList;
  }




  @action deletePhase(phaseId) {
    this.saveNeeded = 1;
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      if (this.doc.phaseList.data[pctr].phaseId === phaseId) {
        this.selectedMenuItem = "General Info";
        this.selectedMenuType = "GeneralInfoPage";
        this.selectedImage = '';

        this.doc.phaseList.data.splice(pctr, 1);
        return;
      }
    }
  }


  @action moveItem(docItemId, direction) {
    this.saveNeeded = 1;
    var hold = null;
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].docItemId === docItemId) {
          //=== is first in phase
          if (ictr === 0 && direction === -1) {
            if (pctr === 0) {
              // nothing to do
            }
            else {
              hold = this.doc.phaseList.data[pctr].lineItemList[ictr];
              this.doc.phaseList.data[pctr].lineItemList.remove(hold);
              this.doc.phaseList.data[pctr - 1].lineItemList.push(hold);
              this.reNumberItems();
              return;
            }
          }
          //=== is last in phase
          else if (ictr === this.doc.phaseList.data[pctr].lineItemList.length - 1 && direction === 1) {
            if (pctr === this.doc.phaseList.data.length - 1) {
              // nothing to do
            }
            else {
              hold = this.doc.phaseList.data[pctr].lineItemList[ictr];
              this.doc.phaseList.data[pctr].lineItemList.remove(hold);
              this.doc.phaseList.data[pctr + 1].lineItemList.unshift(hold);
              this.reNumberItems();
              return;
            }
          }
          //=== is moving within phase.
          else {
            var temp = this.doc.phaseList.data[pctr].lineItemList[ictr];
            this.doc.phaseList.data[pctr].lineItemList.splice(ictr, 1);
            this.doc.phaseList.data[pctr].lineItemList.splice(ictr + direction, 0, temp);
            this.reNumberItems();
            return;
          }
        }
      }
    }
  }


  @action movePhase(phaseId, direction) {
    this.saveNeeded = 1;
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      if (this.doc.phaseList.data[pctr].phaseId === phaseId) {
        if (direction === -1 && pctr === 0) {
          // nothing to do
        }
        else if (direction === 1 && pctr === this.doc.phaseList.data.length - 1) {
          // nothing to do
        }
        else {
          var temp = this.doc.phaseList.data[pctr];
          this.doc.phaseList.data.splice(pctr, 1);
          this.doc.phaseList.data.splice(pctr + direction, 0, temp);
          return;
        }
      }
    }
    this.reNumberItems();
  }

  @action addPhase(phaseName, atEnd) {
    this.saveNeeded = 1;
    if (phaseName) {
      // check that it's not already there
      for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
        if (phaseName === this.doc.phaseList.data[pctr].phaseName) {
          return this.doc.phaseList.data[pctr]; // no need to add anything
        }
      }
    }
    var newPhase = observable({
      "phaseName": (phaseName || "New List " + this.doc.phaseList.data.length),
      "phaseId": uuid.v4(),

      "dateDelivery": "",
      "timeDelivery": "",
      "arriveCarving": "",
      "arriveEarly": "",
      "timeStart": "",
      "timeEnd": "",

      "locationName": "",
      "locationAddress": "",
      "locationCityStateZip": "",

      "contactPerson": "",
      "contactEmail": "",
      "contactPhone": "",

      "comment": "",
      "commentPrivate": "",
      "curItemValue": 0,
      "curFeeValue": 0,
      "curTaxValue": 0,
      "lineItemList": []
    });
    if (atEnd) {
      this.doc.phaseList.data.push(newPhase);
    }
    else {
      this.doc.phaseList.data.unshift(newPhase);
    }
    this.itemCatalogTargetPhase = newPhase;
    return newPhase;
  }

  @action addItem(phase, itemName, status, dept, stockId, goalCost, goalDesc) {
    this.saveNeeded = 1;
    var cost = goalCost || 150;
    if (status === 6 || status === 7) { cost = 10; }
    if(goalCost){ cost=goalCost; }

    var fakeImgArray = [];
    var fakeCostArray = [];
    var fakeDetails = goalDesc||'';
    var newItemId = uuid.v4();
    if (this.provider.quoteCount === 0 && this.provider.providerEmail.startsWith("Free-Sample") && status === 1) {
      fakeDetails = "This is a sample of an item description.Because this is your first quote, we've added a color and a sample of a needed material.";
      console.log(JSON.stringify(this.provider));
      // add fake data for the first sample quote.
      fakeImgArray = [{
        "imgURL": "#-16",
        "name": "Ivory",
        "comment": null,
        "likeIntensity": 1,
        "suggestionOnly": false,
        "owningDocItemId": newItemId,
        "status": 0,
      }
      ];
      fakeCostArray = [
        {
          "name": "garden rose",
          "color": "ivory",
          "cost": 1,
          "qty": 5
        },
      ];
    }

    var newItem = observable({
      "name": (itemName || "New Item"),
      "docItemId": newItemId,
      "cost": cost,
      "qty": 1,
      "oldQty": 0,
      "markup": 100,
      "details": fakeDetails,
      "detailsPrivate": "",
      "status": (status || 1),
      "inspirationList": fakeImgArray,
      "costList": fakeCostArray,
      "stockId": stockId,
      "productType": (dept || '').toLowerCase()
    });

    phase.lineItemList.push(newItem);

    // ensure that the stock gets loaded
    var curToken = window.localStorage.getItem('curToken'); // sadly, this is easiest way to get it here.
    this.stockLoadForQuote(stockId, curToken)
    this.reNumberItems();

    return newItem;
  }

  @action addSpecial(name, itemType, value) {
    this.saveNeeded = 1;
    var newItem = observable({
      "docItemId": uuid.v4(),
      "name": name,
      "cost": value,
      "qty": 1,
      "oldQty": 0,
      "markup": 0,
      "details": "",
      "detailsPrivate": "",
      "status": itemType,
      "inspirationList": [
      ],
      "costList": [
      ]
    });

    this.doc.phaseList.data[this.doc.phaseList.data.length - 1].lineItemList.push(newItem);
  }

  @action termMove(index, direction) {
    this.saveNeeded = 1;
    if (index + direction >= 0 && index + direction < this.doc.clauseList.data.length) {
      var temp = this.doc.clauseList.data[index];
      this.doc.clauseList.data.splice(index, 1);
      this.doc.clauseList.data.splice(index + direction, 0, temp);
    }
  }

  @action termDelete(index) {
    this.saveNeeded = 1;
    if (index >= 0 && index <= this.doc.clauseList.data.length) {
      this.doc.clauseList.data.splice(index, 1);
    }
  }

  @action termAdd() {
    this.saveNeeded = 1;
    this.doc.clauseList.data.unshift(observable({
      key: 'new term title',
      value: 'text of this contract term'
    }));
  }

  @computed get warningList() {
    var warnList = [];
    if (!this.doc) {
      return warnList;
    }
    if (this.doc.metaData.inspirationList.length === 0) { warnList.push("General Info does not have any inspirations."); }
    if (this.doc.clauseList.data.length === 0) { warnList.push("Quote does not have any terms and conditions."); }
    if (this.invoiceDueDateList.length === 0) { warnList.push("Quote does not have a client payment calendar."); }

    var foundTax = false;
    var missingCostCount = 0;
    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].status === 7) { foundTax = true; }
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].status <= 5 &&
          this.doc.phaseList.data[pctr].lineItemList[ictr].costList.length === 0) {
          missingCostCount += 1;
        }
      }
    }
    if (!foundTax) { warnList.push("Quote does not contain a tax entry."); }
    if (missingCostCount) { warnList.push("" + missingCostCount + " items do not have a cost estimate."); }


    if (this.dueDateCalendarWarning) { warnList.push(this.dueDateCalendarWarning); }


    return warnList;
  }

  @computed get dueDateCalendarWarning() {
    var ddsum = 0;
    for (var dctr = 0; dctr < this.invoiceDueDateList.length; dctr++) {
      ddsum = fixNum(ddsum + this.invoiceDueDateList[dctr].amount || 0);
    }
    if (ddsum !== this.totalClientPrice) {
      return 'Due Date Calendar does not match the Client Total Price.';
    }
    return null;
  }

  @computed get ColorListFiltered() {
    var res = [];
    for (var octr = 0; octr < ColorList.length; octr++) {
      for (var ictr = 0; ictr < ColorList[octr].length; ictr++) {
        if (ColorList[octr][ictr].n.includes(this.colorNameFilter)) {
          res.push(ColorList[octr][ictr]);
        }
      }
    }
    return res;
  }

  @action saveTerms(token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/florist/terms/save",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify(this.doc.clauseList)
      })
      .then(function (res) { saneThis.isWorking--; return res.text() })
      .then(function (res) {
        window.Materialize.toast('Deafult Terms Saved', 3000, 'rounded');
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to save changes to default terms.', 3000, 'rounded red'); })
  }

  @action loadTerms(token) {
    var saneThis = this;
    saneThis.isWorking++;
    fetch("mqc/florist/terms/load",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
        method: "POST",
        body: JSON.stringify({})
      })
      .then(function (res) { saneThis.isWorking--; return res.json() })
      .then(function (res) {
        saneThis.doc.clauseList = res;
        window.Materialize.toast('Deafult Terms Loaded', 3000, 'rounded');
      }) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); window.Materialize.toast('Unable to load default terms.', 3000, 'rounded red'); })
  }

  // stock items in quote
  @computed get stockItemsInQuote() {
    var stockList = [];

    if(!this.doc){
      return [];
    }

    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].stockId) {
          var id = this.doc.phaseList.data[pctr].lineItemList[ictr].stockId;
          if (!stockList.includes(id)) {
            stockList.push(id);
          }
        }
      }
    }

    return stockList;
  }

  @computed get stockItemCountsInQuote() {
    var stockList = {};

    if (!this.doc) {
      return {};
    }

    for (var pctr = 0; pctr < this.doc.phaseList.data.length; pctr++) {
      for (var ictr = 0; ictr < this.doc.phaseList.data[pctr].lineItemList.length; ictr++) {
        if (this.doc.phaseList.data[pctr].lineItemList[ictr].stockId) {
          var id = this.doc.phaseList.data[pctr].lineItemList[ictr].stockId;
          if (!stockList[id]) {
            stockList[id]=0;
          }
          stockList[id] += this.doc.phaseList.data[pctr].lineItemList[ictr].qty;
        }
      }
    }

    console.log('stockItemCountsInQuote', stockList);

    return stockList;
  }


  @observable quoteStockMap = observable.map({});


  //==== stock work
  @action stockLoadForQuote(stockId, token) {
    var saneThis = this;


    saneThis.isWorking++;

    fetch("/mqc/desk/stock/load/" + stockId,
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load stock item.";
          window.Materialize.toast('Unable to load stock item.', 3000, 'rounded red');
          return;
        }
        return res.json()
      })
      .then(function (res) {

        if (!res.buildNoteList) res.buildNoteList = [];
        if (!res.photoList) res.photoList = [];
        if (!res.auditList) res.auditList = [];
        if (!res.buildText) res.buildText = '';
        if (!res.calendar) res.calendar = [];

        res.calendar = res.calendar.filter(d => {
          var m1 = moment(d.eventDate);
          var m2 = moment(saneThis.doc.metaData.date);
          return m2.isSame(m1);
        });


        saneThis.quoteStockMap[stockId] = res;
      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }  



  async quoteImport(token, json) {
    var saneThis = this;
    saneThis.isWorking++;

    console.log(json.length);
    
    var idx = 0;
    while (idx < 1) { //


     await fetch("mqc/quote/save",
        {
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + token },
          method: "POST",
          body: JSON.stringify({
            docId: json[idx].docId,
            appUserId: this.appUserId,
            headerImgURL: '',
            materialCost: 0,
            clientPrice: 0,
            doc: json[idx]
          })
        })
        .then(function (res) { 
          saneThis.isWorking--; return res.text() 
        })
        .then(function (res) {
          saneThis.saveNeeded = 0;
          var fix = res.substring(1, res.length - 1);
          if (-1 === fix.toLowerCase().indexOf('error')) {
            console.log(idx, res, ok);
          }
          else {
            console.log(idx, res, fix);
          }
        }) // don't forget to bind or JavaScript with drop it's brain on the floor.
        .catch(function (res) {
          saneThis.isWorking = 0; // force the spinner to stop
          console.log(idx, res);
        })
    }
    idx++;

  }


  @action quoteExport(token) {
    var saneThis = this;
    saneThis.isWorking++;

    fetch("/mqc/desk/client/export",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
        method: "GET"
      })
      .then(function (res) {
        saneThis.isWorking--;
        if (res.error) {
          console.log(res.error);
          saneThis.lastError = "Unable to load this Client export.";
          return;
        }
        return res.json()
      })
      .then(function (res) {

        // send the file to be downloaded
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(res)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "ClientExport.json";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

      }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function (res) { console.log(res); })
  }





}

const QuoteData = new QuoteDataModel();
export default QuoteData;
