


import {observable, computed, action} from 'mobx';


class ImageCatalogModel
{
    // loaded from server
    @observable isWorking=0;
    @observable imageCatalog=[];            // full catalog data
    @observable imageNameFilter='';           // filter on data
    @observable imgUploadURL = '';  
    @observable imgName = '';  

    @computed get imageReadyToAdd()
    {
      return (this.imgUploadURL && this.imgName);
    }

    @computed get errMessage()
    {
      if(!this.imgName){
        return 'Please enter a name.';
      }
      if(!this.imgUploadURL){
        return 'Please load an image.';
      }
      return '';
    }

    @computed get nameList()
    {
      var nameList = [];
      if(this.imageCatalog){
        this.imageCatalog.forEach( (img) => {
          if((img.name||'').includes(this.imageNameFilter)){
            nameList.push({
              name: img.name,
              url: img.imgURL
            });
          }
        });
      }
      return nameList;
    }

    @computed get nameMap()
    {
      var nameMap = {};
      if(this.imageCatalog){
        this.imageCatalog.forEach( (img) => {
            nameMap[img.name]=img.imgURL;
        });
      }
      return nameMap;
    }

    @computed get SizeSortedNameList()
    {
      var allList=[];
      if(this.imageCatalog){
        this.imageCatalog.forEach( (img) => {
            allList.push(img.name);
        });
      }
      allList.sort(function(a, b){ return b.length - a.length; });
      return allList;
    }

    @action clearData()
    {
      this.imageNameFilter='';           // filter on data
      this.imgUploadURL = '';  
    }

    @action loadImageCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      
      fetch("mqc/florist/image/list",
      {
        headers: { 
          'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({  } ) // handy for testing.  should be appUserId
      })
      .then(function(res){
        saneThis.isWorking--;
        return res.json() })
      .then(function(res){ 
        if(res){ 
          this.imageCatalog = res;
        }
        else{ console.log(res);window.Materialize.toast(res, 3000, 'rounded'); }
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to load image catalog.', 3000, 'rounded'); })        
    }

    @action saveImageToCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      
      fetch("mqc/florist/image/add",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({ "name":this.imgName,  "imgURL":this.imgUploadURL } )
      })
      .then(function(res){saneThis.isWorking--;return res.text() })
      .then(function(res){ 
        this.loadImageCatalog(token);
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to save image catalog changes.', 3000, 'rounded'); })        
    }

    @action deleteImageFromCatalog(token)
    {
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/florist/image/delete",
      {
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json','Authorization': "Bearer "+token},
        method: "POST",
        body: JSON.stringify({ "name":this.imgName,   } )
      })
      .then(function(res){saneThis.isWorking--;return res.text() })
      .then(function(res){ 
        this.loadImageCatalog(token);
        window.Materialize.toast('Removed '+this.imgName+' from your catalog.', 3000, 'rounded');
      }.bind(this) ) // don't forget to bind or JavaScript with drop it's brain on the floor.
      .catch(function(res){ console.log(res); window.Materialize.toast('Unable to remove image from catalog.', 3000, 'rounded'); })        
    }    

    @action saveImageFromCamera(imageAsString, token, callBackSet){
      var saneThis = this;
      saneThis.isWorking++;
      fetch("mqc/quote/image/bas64/save",
        {
          headers: { 'Accept': 'text/plain', 'Content-Type': 'text/plain', 'Cache-Control': 'no-cache', 'Authorization': "Bearer " + token },
          method: "POST",
          body: imageAsString
        })
        .then(function (res) { saneThis.isWorking--; return res.text() })
        .then(function (res) 
        {
          var url = res.replace('"', '').replace('"', ''); // because replaceAll would be too easy          
          callBackSet(url);
        }.bind(this)) // don't forget to bind or JavaScript with drop it's brain on the floor.
        .catch(function (res) { console.log(res); window.Materialize.toast('Unable to capture image.', 3000, 'rounded'); })        
    }


}

const ImageCatalog = new ImageCatalogModel();
export default ImageCatalog;

