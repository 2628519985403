
import styled from 'styled-components';

const BarImage = styled.div`
  display:block;
  background-image:url(${props => props.src});
  background-size: cover;
  background-position: center center;
  width:100%;
  height:100%;
  min-width:100px;
  min-height:${props => props.minHeight};
  vertical-align:bottom;
  color: darkblue;
  font-size:50%;
  border:2px solid black
`;

export default BarImage;