import React from 'react';

export default function Loading({ isLoading, pastDelay, error }) {
  if (isLoading && pastDelay) {
    return <div style={{margin:'20px'}}><p>Loading from ModaQuote.com...</p></div>;
  } else if (error && !isLoading) {
    console.log(error);
    return <p>Error: {error}</p>;
  } else {
    return null;
  }
}