
import React from 'react';
import autoBind from 'react-autobind';
import {observer,inject} from 'mobx-react';

export default 
@inject(
  "LoginData",
  "DeskData",
  "QuoteData",
  "ImageCatalog",
  "MaterialCatalog",
  "PayData",
  "TxnHistory",
  "RFPData"
) @observer 
class Spinner extends React.Component
{
// eslint-disable-next-line
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render()
  {
    var ctr=0;
    ctr+=this.props.LoginData.isWorking;
    ctr+=this.props.DeskData.isWorking;
    ctr+=this.props.QuoteData.isWorking;
    ctr+=this.props.ImageCatalog.isWorking;
    ctr+=this.props.MaterialCatalog.isWorking;
    ctr+=this.props.PayData.isWorking;
    ctr+=this.props.TxnHistory.isWorking;
    ctr+=this.props.RFPData.isWorking;

    if(0===ctr){
      return(null);
    }
    return(
      <div style={{
              zIndex: 3,
              backgroundColor   : 'rgba(255, 255, 255, 0.25)',
              position                   : 'absolute',              
              top                        : 0, 
              bottom                     : 0,              
              left                       : 0,
              right                      : 0,
              display:'block'
           }}
          >
          <img style={{
              zIndex: 4,
              position                   : 'absolute',              
              top                        : 0, 
              right                      : 0, 
              }} src='img/ripple.svg' alt=''/>
        </div>      
    );
  }
}